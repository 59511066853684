import {
  FocusContext,
  setFocus,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation';
import cx from 'classnames';
import { useController } from 'hooks';
import { ComponentType, ReactNode, useCallback, useEffect } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { selectOpenModal, setOpenModal } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ModalBackground, ModalBody, ModalLogo, ModalVariant } from './BaseModal.styles';
import { Buttons } from 'utils/buttons';

interface CustomProps {
  children: any; // workaround for React modal typing issues - should revisit in future
  className?: string;
  focusOptions?: Partial<UseFocusableConfig>;
  from?: string;
  isBackButtonEnabled?: boolean;
  onClose?: () => void;
  testId: string;
  variant?: ModalVariant;
  withLogo?: boolean;
}

export type BaseModalProps = CustomProps & ReactModalProps;

// workaround for React modal typing issues - should revisit in future
const TypedModal = ReactModal as ComponentType<ReactModal['props']>;

export function BaseModal({
  children,
  className = '',
  contentLabel = '',
  focusOptions,
  from = '',
  isBackButtonEnabled = true, // Useful when you want to press back to trigger an effect, but not close a modal
  isOpen,
  onAfterClose,
  onAfterOpen,
  onClose,
  testId,
  variant = 'base',
  withLogo,
}: BaseModalProps) {
  const dispatch = useAppDispatch();
  const { focusKey, focusSelf, ref } = useFocusable({
    autoRestoreFocus: false,
    focusable: isOpen,
    isFocusBoundary: true,
    ...focusOptions,
  });
  const openModal = useAppSelector(selectOpenModal);

  const closeHandler = () => {
    if (isBackButtonEnabled) dispatch(setOpenModal(null));
    if (onClose) onClose();
  };

  const handleAfterOpen = useCallback(() => {
    focusSelf();
    dispatch(setOpenModal(testId));

    if (onAfterOpen) onAfterOpen();
  }, [dispatch, focusSelf, onAfterOpen, testId]);

  const handleAfterClose = () => {
    if (from) setFocus(from);
    if (onAfterClose) onAfterClose();
    if (openModal === testId) dispatch(setOpenModal(null));
  };

  // handleAfterOpen doesn't always get called when the modal opens, so this will handle the missed cases
  useEffect(() => {
    if (isOpen) {
      handleAfterOpen();
    }
  }, [handleAfterOpen, isOpen]);

  useController({
    [Buttons.back]: { enabled: isOpen, event: closeHandler },
  });

  return (
    <TypedModal
      ariaHideApp={false}
      className="_"
      closeTimeoutMS={500}
      contentElement={(contentProps, contentChildren) => (
        <ModalBody
          {...contentProps}
          ref={ref}
          $variant={variant}
          className={cx(contentProps.className, className)}>
          {withLogo && <ModalLogo />}
          {contentChildren as ReactNode}
        </ModalBody>
      )}
      contentLabel={contentLabel}
      isOpen={isOpen}
      overlayClassName="_"
      overlayElement={(overlayProps, contentElement) => (
        <ModalBackground {...overlayProps}>{contentElement as ReactNode}</ModalBackground>
      )}
      preventScroll
      testId={testId}
      onAfterClose={handleAfterClose}
      onAfterOpen={handleAfterOpen}>
      <FocusContext.Provider value={focusKey}>{children}</FocusContext.Provider>
    </TypedModal>
  );
}
