import api, { GET } from 'services/api';
import { Team } from 'store/teams';
import { TeamResponse } from 'services/statsApi/teams/types';

export const fetchTeams = async (): Promise<Team[]> => {
  const response = await api<TeamResponse>(GET, {
    endpoint: 'teams',
    service: 'statsapi',
  });

  return response.data.teams;
};
