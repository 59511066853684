/* eslint-disable no-template-curly-in-string */
/* eslint-disable sort-keys */
import { Branding } from './types';

export const branding: Branding = {
  allstarTeams: [
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/NAS-Active-Dark-f4365f9b-b4c1-4aee-bfe1-0918e7e77672_qdao2z',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/NAS-Active-Light-5e5362e1-7fdc-4190-b2f8-83a7e03a3a18_nn4no4',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/NAS-Active-Dark-f4365f9b-b4c1-4aee-bfe1-0918e7e77672_qdao2z',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/NAS-Active-Light-5e5362e1-7fdc-4190-b2f8-83a7e03a3a18_nn4no4',
      },
      club: 'nl',
      clubFullName: 'NL All-Stars',
      fileCode: 'nl',
      sportCode: 'mlb',
      teamCode: 'nas',
      teamColors: {
        mlbtvBorderColor: '#000088',
        mlbtvGameTile: '#000',
        navigationColor: '#000088',
        primaryDark: '#000088',
        primaryLight: '#000088',
      },
      teamID: 160,
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/AAS-Active-Dark-605ae401-4913-443c-a170-5db6d2cbd3b9_pftpak',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/AAS-Active-Light-b62b4fc2-0664-4cfe-9c32-b56c1f2f40d9_jq7s8g',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/AAS-Active-Dark-605ae401-4913-443c-a170-5db6d2cbd3b9_pftpak',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/AAS-Active-Light-b62b4fc2-0664-4cfe-9c32-b56c1f2f40d9_jq7s8g',
      },
      club: 'al',
      clubFullName: 'AL All-Stars',
      fileCode: 'al',
      sportCode: 'mlb',
      teamCode: 'aas',
      teamColors: {
        mlbtvBorderColor: '#CC0000',
        navigationColor: '#CC0000',
        primaryDark: '#CC0000',
        primaryLight: '#CC0000',
      },
      teamID: 159,
    },
  ],
  mlb: {
    clubFullName: 'MLB',
    mlbtv: {
      gettingStarted: {
        imageUrl:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/mlbtv/gettingstarted/MLB-a26abfc9-0bcd-4638-b531-016614496495',
        templateUrl:
          'https://img.mlbstatic.com/mlb-mobile/${options}/oms/branding/mlbtv/gettingstarted/MLB-a26abfc9-0bcd-4638-b531-016614496495',
      },
      welcome: {
        browse: {
          imageUrl:
            'https://img.mlbstatic.com/mlb-mobile/oms/branding/mlbtv/welcome/MLB-1-fe69802d-3fc9-4f54-8920-777b208da57c',
          templateUrl:
            'https://img.mlbstatic.com/mlb-mobile/${options}/oms/branding/mlbtv/welcome/MLB-1-fe69802d-3fc9-4f54-8920-777b208da57c',
        },
        create: {
          imageUrl:
            'https://img.mlbstatic.com/mlb-mobile/oms/branding/mlbtv/welcome/MLB-0-c3b6338e-c939-4788-9e46-67369048b2ab',
          templateUrl:
            'https://img.mlbstatic.com/mlb-mobile/${options}/oms/branding/mlbtv/welcome/MLB-0-c3b6338e-c939-4788-9e46-67369048b2ab',
        },
        explore: {
          imageUrl:
            'https://img.mlbstatic.com/mlb-mobile/oms/branding/mlbtv/welcome/MLB-2-7c23abdd-cb2b-4c78-b424-067e0c0b80ec',
          templateUrl:
            'https://img.mlbstatic.com/mlb-mobile/${options}/oms/branding/mlbtv/welcome/MLB-2-7c23abdd-cb2b-4c78-b424-067e0c0b80ec',
        },
      },
    },
    teamColors: {
      complicationBackground: '#111111',
      complicationText: '#FFFFFF',
      gradientBorderEndColor: '#222222',
      gradientBorderStartColor: '#DDDDDD',
      gradientEndColor: '#DDDDDD',
      gradientStartColor: '#222222',
      mlbtvGameTile: '#000',
      navigationColor: '#000000',
      primaryDark: '#000000',
      primaryLight: '#FFFFFF',
      primaryLinkDark: '#000000',
      primaryLinkLight: '#FFFFFF',
      quaternary: '#222222',
      secondary: '#888888',
      tertiary: '#444444',
    },
  },
  options: {
    teamColors: [
      'navigationColor',
      'primaryLight',
      'primaryDark',
      'primaryLinkLight',
      'primaryLinkDark',
      'secondary',
      'tertiary',
      'quaternary',
      'complicationText',
      'complicationBackground',
      'gradientStartColor',
      'gradientEndColor',
      'gradientBorderStartColor',
      'gradientBorderEndColor',
      'mlbtvGameTile',
    ],
  },
  teams: [
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/Ball-Active-Dark_nud1mr',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/Ball-Inactive-Light_hhji9b',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/Ball-Active-Light_ndwx6l',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/Ball-Inactive-Light_g9bfcx',
        svgDarkActive: 'https://img.mlbstatic.com/mlb-mobile/oms/Ball-Active-Dark_nud1mr',
        svgDarkInactive: 'https://img.mlbstatic.com/mlb-mobile/oms/Ball-Inactive-Light_hhji9b',
        svgLightActive: 'https://img.mlbstatic.com/mlb-mobile/oms/Ball-Active-Light_ndwx6l',
        svgLightInactive: 'https://img.mlbstatic.com/mlb-mobile/oms/Ball-Inactive-Light_g9bfcx',
      },
      club: '',
      clubFullName: 'Default',
      fileCode: '',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: '',
      teamCode: '',
      teamColors: {
        complicationBackground: '#111111',
        complicationText: '#FFFFFF',
        gradientBorderEndColor: '#222222',
        gradientBorderStartColor: '#DDDDDD',
        gradientEndColor: '#DDDDDD',
        gradientStartColor: '#222222',
        mlbtvBorderColor: '#000000',
        mlbtvGameTile: '#666666',
        navigationColor: '#000000',
        primaryDark: '#000000',
        primaryLight: '#FFFFFF',
        primaryLinkDark: '#000000',
        primaryLinkLight: '#FFFFFF',
        quaternary: '#222222',
        secondary: '#888888',
        tertiary: '#444444',
      },
      teamID: 0,
      wordmarks: {},
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ARI-Active-Dark-43aa75d7-3b16-414f-b097-782415763f05',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ARI-Inactive-Dark-2b7cf469-9724-4770-a0b1-eec39f8292a9',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ARI-Active-Light-ee30ee82-8e30-406b-b799-ec64c29214f9',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ARI-Inactive-Light-a6681dee-43f4-427a-a2f7-568f19e75d08',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ARI-Active-Dark-43aa75d7-3b16-414f-b097-782415763f05',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ARI-Inactive-Dark-2b7cf469-9724-4770-a0b1-eec39f8292a9',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ARI-Active-Light-ee30ee82-8e30-406b-b799-ec64c29214f9',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ARI-Inactive-Light-a6681dee-43f4-427a-a2f7-568f19e75d08',
      },
      club: 'ari',
      clubFullName: 'Arizona Diamondbacks',
      fileCode: 'ari',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'ari',
      teamColors: {
        complicationBackground: '#A71930',
        complicationText: '#E3D4AD',
        gradientBorderEndColor: '#741121',
        gradientBorderStartColor: '#a61930',
        gradientEndColor: '#741121',
        gradientStartColor: '#a61930',
        mlbtvGameTile: '#000000',
        navigationColor: '#A71930',
        primaryDark: '#E3D4AD',
        primaryLight: '#A71930',
        primaryLinkDark: '#E3D4AD',
        primaryLinkLight: '#A71930',
        quaternary: '#666666',
        secondary: '#000000',
        tertiary: '#e3d4ad',
      },
      teamID: 109,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ARI-Dark-3a1681d2-d58d-4020-9fe7-c54a10dc3d40',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ARI-Light-31e28c0b-c414-4d30-af65-34a0c2137b95',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ARI-Dark-3a1681d2-d58d-4020-9fe7-c54a10dc3d40',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ARI-Light-31e28c0b-c414-4d30-af65-34a0c2137b95',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ATL-Active-Dark-f898dff8-9130-4291-86f8-68278638fd7f',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ATL-Inactive-Dark-404c6b36-cb9e-49c2-ac54-3f7d3fc84818',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ATL-Active-Light-faa618e2-9549-4020-a0f5-190863a80f8d',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/ATL-Inactive-Light-a592121e-1332-4a78-9f9c-f4b58cc54830',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ATL-Active-Dark-f898dff8-9130-4291-86f8-68278638fd7f',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ATL-Inactive-Dark-404c6b36-cb9e-49c2-ac54-3f7d3fc84818',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ATL-Active-Light-faa618e2-9549-4020-a0f5-190863a80f8d',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/ATL-Inactive-Light-a592121e-1332-4a78-9f9c-f4b58cc54830',
      },
      club: 'atl',
      clubFullName: 'Atlanta Braves',
      fileCode: 'atl',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'atl',
      teamColors: {
        complicationBackground: '#0C2340',
        complicationText: '#CE1141',
        gradientBorderEndColor: '#0b1c3d',
        gradientBorderStartColor: '#12254e',
        gradientEndColor: '#0b1c3d',
        gradientStartColor: '#12254e',
        mlbtvGameTile: '#0D2340',
        navigationColor: '#0C2340',
        primaryDark: '#E5485D',
        primaryLight: '#0C2340',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#0C2340',
        quaternary: '#af0039',
        secondary: '#af0039',
        tertiary: '#bebebe',
      },
      teamID: 144,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ATL-Dark-2d847d9e-31f2-4cb2-b197-b9450e802eb1',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ATL-Light-f5b1ba0c-1320-4dd8-bea2-d6a91d25916c',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ATL-Dark-2d847d9e-31f2-4cb2-b197-b9450e802eb1',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ATL-Light-f5b1ba0c-1320-4dd8-bea2-d6a91d25916c',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BAL-Active-Dark-836b0995-c23d-47c3-ab9d-e83de1e49559',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BAL-Inactive-Dark-332f3c74-62ca-4839-9be2-24b9579dd465',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BAL-Active-Light-84cded1e-5571-4a5c-9e52-c2c20ca2ccfb',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BAL-Inactive-Light-3cef6d3d-8b4d-42ad-b550-d25effa06ef8',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BAL-Active-Dark-836b0995-c23d-47c3-ab9d-e83de1e49559',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BAL-Inactive-Dark-332f3c74-62ca-4839-9be2-24b9579dd465',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BAL-Active-Light-84cded1e-5571-4a5c-9e52-c2c20ca2ccfb',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BAL-Inactive-Light-3cef6d3d-8b4d-42ad-b550-d25effa06ef8',
      },
      club: 'bal',
      clubFullName: 'Baltimore Orioles',
      fileCode: 'bal',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'bal',
      teamColors: {
        complicationBackground: '#DF4601',
        complicationText: '#DF4A20',
        gradientBorderEndColor: '#a33300',
        gradientBorderStartColor: '#c63d00',
        gradientEndColor: '#a33300',
        gradientStartColor: '#c63d00',
        mlbtvGameTile: '#000000',
        navigationColor: '#DF4601',
        primaryDark: '#E05129',
        primaryLight: '#DF4601',
        primaryLinkDark: '#E05129',
        primaryLinkLight: '#DF4601',
        quaternary: '#666666',
        secondary: '#d15809',
        tertiary: '#f5e2af',
      },
      teamID: 110,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/BAL-Dark-24186884-00e6-49dc-98d4-6fd6ca341e73',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/BAL-Light-458e6ec1-de19-4efe-a1e3-d823a2d3f5b9',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/BAL-Dark-24186884-00e6-49dc-98d4-6fd6ca341e73',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/BAL-Light-458e6ec1-de19-4efe-a1e3-d823a2d3f5b9',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BOS-Active-Dark-210e99a2-1472-41fe-8c7b-0ec26158e4d1',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BOS-Inactive-Dark-dcfb6476-fc6f-43c8-9d2e-84cd6516af1c',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BOS-Active-Light-aeeb9db4-c817-4fae-95a3-739832e2911a',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/BOS-Inactive-Light-72ad8679-298a-463e-94f7-492d7269be00',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BOS-Active-Dark-210e99a2-1472-41fe-8c7b-0ec26158e4d1',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BOS-Inactive-Dark-dcfb6476-fc6f-43c8-9d2e-84cd6516af1c',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BOS-Active-Light-aeeb9db4-c817-4fae-95a3-739832e2911a',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/BOS-Inactive-Light-72ad8679-298a-463e-94f7-492d7269be00',
      },
      club: 'bos',
      clubFullName: 'Boston Red Sox',
      fileCode: 'bos',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'bos',
      teamColors: {
        complicationBackground: '#BD3039',
        complicationText: '#BD3039',
        gradientBorderEndColor: '#8e2229',
        gradientBorderStartColor: '#B92E37',
        gradientEndColor: '#8e2229',
        gradientStartColor: '#B92E37',
        mlbtvGameTile: '#0D2B56',
        navigationColor: '#0D2B56',
        primaryDark: '#E5485D',
        primaryLight: '#0D2B56',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#BD3039',
        quaternary: '#666666',
        secondary: '#0d2b56',
        tertiary: '#cccccc',
      },
      teamID: 111,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/BOS-Dark-7670a1a6-17a7-4d50-b1dd-8d693e5ce700',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/BOS-Light-161c5136-a565-4fbe-94ea-9961a7cfb655',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/BOS-Dark-7670a1a6-17a7-4d50-b1dd-8d693e5ce700',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/BOS-Light-161c5136-a565-4fbe-94ea-9961a7cfb655',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CHC-Active-Dark-5d40cc5d-1e32-4c48-95f3-13471a255061',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CHC-Inactive-Dark-195ade04-54dd-426d-a480-e8b964b8d6e3',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CHC-Active-Light-accaf9c2-b934-49a7-9b07-99fcbc38548f',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CHC-Inactive-Light-1cf78b93-d254-4f1b-9965-4d485db7d22b',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CHC-Active-Dark-5d40cc5d-1e32-4c48-95f3-13471a255061',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CHC-Inactive-Dark-195ade04-54dd-426d-a480-e8b964b8d6e3',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CHC-Active-Light-accaf9c2-b934-49a7-9b07-99fcbc38548f',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CHC-Inactive-Light-1cf78b93-d254-4f1b-9965-4d485db7d22b',
      },
      club: 'chc',
      clubFullName: 'Chicago Cubs',
      fileCode: 'chc',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'chn',
      teamColors: {
        complicationBackground: '#0E3386',
        complicationText: '#CC3433',
        gradientBorderEndColor: '#0b2c76',
        gradientBorderStartColor: '#0e3386',
        gradientEndColor: '#0b2c76',
        gradientStartColor: '#0e3386',
        mlbtvGameTile: '#0E3386',
        navigationColor: '#0E3386',
        primaryDark: '#0088F0',
        primaryLight: '#0E3386',
        primaryLinkDark: '#0088F0',
        primaryLinkLight: '#0E3386',
        quaternary: '#666666',
        secondary: '#d82427',
        tertiary: '#cdcfc5',
      },
      teamID: 112,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CHN-Dark-c40c9e60-ca9f-4403-ae6c-b66e14d16bcd',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CHN-Light-daf23a61-b164-4b88-a43f-b81f579d643a',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CHN-Dark-c40c9e60-ca9f-4403-ae6c-b66e14d16bcd',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CHN-Light-daf23a61-b164-4b88-a43f-b81f579d643a',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CWS-Active-Dark-84011fd2-65c4-41e7-9201-b589a2402972',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CWS-Inactive-Dark-dab9c00f-2e13-4abf-8192-8ef9d78e934e',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CWS-Active-Light-b4d4c24f-b204-4489-8154-bc719a0cc13e',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CWS-Inactive-Light-88dd5acc-3d94-4087-ad7f-9c2333620a9a',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CWS-Active-Dark-84011fd2-65c4-41e7-9201-b589a2402972',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CWS-Inactive-Dark-dab9c00f-2e13-4abf-8192-8ef9d78e934e',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CWS-Active-Light-b4d4c24f-b204-4489-8154-bc719a0cc13e',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CWS-Inactive-Light-88dd5acc-3d94-4087-ad7f-9c2333620a9a',
      },
      club: 'cws',
      clubFullName: 'Chicago White Sox',
      fileCode: 'cws',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'cha',
      teamColors: {
        complicationBackground: '#000000',
        complicationText: '#C4CED4',
        gradientBorderEndColor: '#242424',
        gradientBorderStartColor: '#3a3a3a',
        gradientEndColor: '#242424',
        gradientStartColor: '#3a3a3a',
        mlbtvGameTile: '#000000',
        navigationColor: '#000000',
        primaryDark: '#C4CED4',
        primaryLight: '#000000',
        primaryLinkDark: '#C4CED4',
        primaryLinkLight: '#000000',
        quaternary: '#666666',
        secondary: '#666666',
        tertiary: '#ba0021',
      },
      teamID: 145,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CHA-Dark-2121926d-b28c-4d2b-94e2-2bd48b91fe40',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CHA-Light-3effcdee-5683-4af9-b31c-3d5e83160c90',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CHA-Dark-2121926d-b28c-4d2b-94e2-2bd48b91fe40',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CHA-Light-3effcdee-5683-4af9-b31c-3d5e83160c90',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CIN-Active-Dark-517af7ea-d653-41f7-b4a5-89e852856194',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CIN-Inactive-Dark-858d023b-936f-45db-b27c-121f9f2fc3bd',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CIN-Active-Light-bf476a78-53c6-4a52-9c90-c0ecde8aef25',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CIN-Inactive-Light-a9e4ea50-2925-4336-a47d-c14fe81b9fea',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CIN-Active-Dark-517af7ea-d653-41f7-b4a5-89e852856194',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CIN-Inactive-Dark-858d023b-936f-45db-b27c-121f9f2fc3bd',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CIN-Active-Light-bf476a78-53c6-4a52-9c90-c0ecde8aef25',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CIN-Inactive-Light-a9e4ea50-2925-4336-a47d-c14fe81b9fea',
      },
      club: 'cin',
      clubFullName: 'Cincinnati Reds',
      fileCode: 'cin',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'cin',
      teamColors: {
        complicationBackground: '#C6011F',
        complicationText: '#C60183',
        gradientBorderEndColor: '#9b0219',
        gradientBorderStartColor: '#c5011f',
        gradientEndColor: '#9b0219',
        gradientStartColor: '#c5011f',
        mlbtvGameTile: '#C6011F',
        navigationColor: '#C6011F',
        primaryDark: '#E5485D',
        primaryLight: '#C6011F',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#C6011F',
        quaternary: '#666666',
        secondary: '#f7c240',
        tertiary: '#e1d3a6',
      },
      teamID: 113,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CIN-Dark-43d9b8cb-e403-4f93-9500-c58e78efb64c',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CIN-Light-0f5275b0-4beb-436d-8777-e6cec384fbd6',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CIN-Dark-43d9b8cb-e403-4f93-9500-c58e78efb64c',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CIN-Light-0f5275b0-4beb-436d-8777-e6cec384fbd6',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CLE-Active-Dark-2923d3cf-eeb6-48ef-8665-8a878ce236f1',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CLE-Inactive-Dark-2df71ad6-a6fa-476b-b841-046ae7b99874',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CLE-Active-Light-8814ee38-eb44-4270-b62b-3c4a9b9b38fc',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/CLE-Inactive-Light-0c6d8dc3-0be2-4d59-8e6b-e6b4eac5ada2',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CLE-Active-Dark-2923d3cf-eeb6-48ef-8665-8a878ce236f1',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CLE-Inactive-Dark-2df71ad6-a6fa-476b-b841-046ae7b99874',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CLE-Active-Light-8814ee38-eb44-4270-b62b-3c4a9b9b38fc',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/CLE-Inactive-Light-0c6d8dc3-0be2-4d59-8e6b-e6b4eac5ada2',
      },
      club: 'cle',
      clubFullName: 'Cleveland Indians',
      fileCode: 'cle',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'cle',
      teamColors: {
        complicationBackground: '#002B5C',
        complicationText: '#E31937',
        gradientBorderEndColor: '#a70000',
        gradientBorderStartColor: '#cb0000',
        gradientEndColor: '#a70000',
        gradientStartColor: '#cb0000',
        mlbtvGameTile: '#002B5C',
        navigationColor: '#002B5C',
        primaryDark: '#E5485D',
        primaryLight: '#002B5C',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#E31937',
        quaternary: '#666666',
        secondary: '#023465',
        tertiary: '#cccccc',
      },
      teamID: 114,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CLE-Dark-303e43f4-b61b-40da-8584-7ddb001442f7',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/CLE-Light-42144d4e-642b-4041-8a8b-ca74b6389fcc',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CLE-Dark-303e43f4-b61b-40da-8584-7ddb001442f7',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/CLE-Light-42144d4e-642b-4041-8a8b-ca74b6389fcc',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/COL-Active-Dark-b7e8f4ca-5b50-4367-a14e-752138a491b7',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/COL-Inactive-Dark-1addf33c-b33c-4f1d-908d-4b3e204ae4d5',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/COL-Active-Light-01eab241-b26c-45cd-9160-d73073b5f7c6',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/COL-Inactive-Light-565ace9c-407f-49dc-b676-2fcb9dba0286',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/COL-Active-Dark-b7e8f4ca-5b50-4367-a14e-752138a491b7',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/COL-Inactive-Dark-1addf33c-b33c-4f1d-908d-4b3e204ae4d5',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/COL-Active-Light-01eab241-b26c-45cd-9160-d73073b5f7c6',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/COL-Inactive-Light-565ace9c-407f-49dc-b676-2fcb9dba0286',
      },
      club: 'col',
      clubFullName: 'Colorado Rockies',
      fileCode: 'col',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'col',
      teamColors: {
        complicationBackground: '#33006F',
        complicationText: '#C4CED4',
        gradientBorderEndColor: '#27274f',
        gradientBorderStartColor: '#33006F',
        gradientEndColor: '#27274f',
        gradientStartColor: '#33006F',
        mlbtvGameTile: '#33006F',
        navigationColor: '#33006F',
        primaryDark: '#945CC1',
        primaryLight: '#33006F',
        primaryLinkDark: '#945CC1',
        primaryLinkLight: '#33006F',
        quaternary: '#666666',
        secondary: '#33006F',
        tertiary: '#cccccc',
      },
      teamID: 115,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/COL-Dark-56a6ef73-1d64-41d2-a9dd-12202d60aff6',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/COL-Light-95887120-da1b-4203-9082-685efb7d8a3f',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/COL-Dark-56a6ef73-1d64-41d2-a9dd-12202d60aff6',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/COL-Light-95887120-da1b-4203-9082-685efb7d8a3f',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/DET-Active-Dark-6f98d759-cfd5-445a-9be4-e14e584d01c5',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/DET-Inactive-Dark-616541ea-0206-4374-b079-e80164610de0',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/DET-Active-Light-6e3c279a-d020-4c14-86d8-5e0950b2f55c',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/DET-Inactive-Light-801a7405-af13-4ef0-93b8-ad5fe1176177',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/DET-Active-Dark-6f98d759-cfd5-445a-9be4-e14e584d01c5',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/DET-Inactive-Dark-616541ea-0206-4374-b079-e80164610de0',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/DET-Active-Light-6e3c279a-d020-4c14-86d8-5e0950b2f55c',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/DET-Inactive-Light-801a7405-af13-4ef0-93b8-ad5fe1176177',
      },
      club: 'det',
      clubFullName: 'Detroit Tigers',
      fileCode: 'det',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'det',
      teamColors: {
        complicationBackground: '#0C2C56',
        complicationText: '#FF6602',
        gradientBorderEndColor: '#092141',
        gradientBorderStartColor: '#0c2b55',
        gradientEndColor: '#092141',
        gradientStartColor: '#0c2b55',
        mlbtvGameTile: '#0C2C56',
        navigationColor: '#0C2C56',
        primaryDark: '#FF6600',
        primaryLight: '#0C2C56',
        primaryLinkDark: '#FF6600',
        primaryLinkLight: '#FF6600',
        quaternary: '#666666',
        secondary: '#ff6600',
        tertiary: '#cccccc',
      },
      teamID: 116,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/DET-Dark-c44cfe93-3c5a-42d2-acfa-7b2b974ac016',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/DET-Light-9db4e29e-1bd9-4ab3-bd0e-93f7d291d3bf',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/DET-Dark-c44cfe93-3c5a-42d2-acfa-7b2b974ac016',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/DET-Light-9db4e29e-1bd9-4ab3-bd0e-93f7d291d3bf',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/HOU-Active-Dark-22068e34-1bc1-4af7-bdc0-02c864c8c3f9',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/HOU-Inactive-Dark-c703a599-f539-4e3d-b1b3-5ba14186b328',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/HOU-Active-Light-c0d427cc-4305-4807-9df5-8ce98ff9a72b',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/HOU-Inactive-Light-0c19a626-6bcd-4b1d-b0f0-c38ed43c5a92',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/HOU-Active-Dark-22068e34-1bc1-4af7-bdc0-02c864c8c3f9',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/HOU-Inactive-Dark-c703a599-f539-4e3d-b1b3-5ba14186b328',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/HOU-Active-Light-c0d427cc-4305-4807-9df5-8ce98ff9a72b',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/HOU-Inactive-Light-0c19a626-6bcd-4b1d-b0f0-c38ed43c5a92',
      },
      club: 'hou',
      clubFullName: 'Houston Astros',
      fileCode: 'hou',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'hou',
      teamColors: {
        complicationBackground: '#EB6E1F',
        complicationText: '#EB6E1F',
        gradientBorderEndColor: '#00295f',
        gradientBorderStartColor: '#003a82',
        gradientEndColor: '#00295f',
        gradientStartColor: '#003a82',
        mlbtvGameTile: '#002D62',
        navigationColor: '#002D62',
        primaryDark: '#EB6E1F',
        primaryLight: '#002D62',
        primaryLinkDark: '#EB6E1F',
        primaryLinkLight: '#002D62',
        quaternary: '#666666',
        secondary: '#c7c393',
        tertiary: '#c7c393',
      },
      teamID: 117,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/HOU-Dark-c1c05915-09ee-4782-a612-259d42f8102b',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/HOU-Light-add3d1f0-a785-4720-b8d8-602e2f6942c5',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/HOU-Dark-c1c05915-09ee-4782-a612-259d42f8102b',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/HOU-Light-add3d1f0-a785-4720-b8d8-602e2f6942c5',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/KC-Active-Dark-22a3c1fa-7ac3-4e02-95d4-63f9d979054d',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/KC-Inactive-Dark-9acbe5b7-abb8-464b-910c-39d228ede5df',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/KC-Active-Light-cb34baa3-58cc-451c-8c25-0cee949ac3e6',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/KC-Inactive-Light-cc9897cf-0a72-4d8f-a545-b1290783d33e',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/KC-Active-Dark-22a3c1fa-7ac3-4e02-95d4-63f9d979054d',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/KC-Inactive-Dark-9acbe5b7-abb8-464b-910c-39d228ede5df',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/KC-Active-Light-cb34baa3-58cc-451c-8c25-0cee949ac3e6',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/KC-Inactive-Light-cc9897cf-0a72-4d8f-a545-b1290783d33e',
      },
      club: 'kc',
      clubFullName: 'Kansas City Royals',
      fileCode: 'kc',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'kca',
      teamColors: {
        complicationBackground: '#004687',
        complicationText: '#C09A5B',
        gradientBorderEndColor: '#00396f',
        gradientBorderStartColor: '#0f4a81',
        gradientEndColor: '#00396f',
        gradientStartColor: '#0f4a81',
        mlbtvGameTile: '#004687',
        navigationColor: '#004687',
        primaryDark: '#0088F0',
        primaryLight: '#004687',
        primaryLinkDark: '#0088F0',
        primaryLinkLight: '#004687',
        quaternary: '#666666',
        secondary: '#000000',
        tertiary: '#cccccc',
      },
      teamID: 118,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/KCA-Dark-f1b37982-b3ec-447d-bb9a-a997426c8c6f',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/KCA-Light-9b4993b0-16b2-4128-b80d-af6385a1a1bf',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/KCA-Dark-f1b37982-b3ec-447d-bb9a-a997426c8c6f',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/KCA-Light-9b4993b0-16b2-4128-b80d-af6385a1a1bf',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAA-Active-Dark-68aeb7aa-5047-4e21-9c23-16a98d812713',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAA-Inactive-Dark-3c1dbbc1-4cf7-4142-8da6-59847970145d',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAA-Active-Light-549e48ec-58fb-4ae8-8673-9e937141fedd',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAA-Inactive-Light-ad642ee9-449d-47fa-a49b-ba9141f26359',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAA-Active-Dark-68aeb7aa-5047-4e21-9c23-16a98d812713',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAA-Inactive-Dark-3c1dbbc1-4cf7-4142-8da6-59847970145d',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAA-Active-Light-549e48ec-58fb-4ae8-8673-9e937141fedd',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAA-Inactive-Light-ad642ee9-449d-47fa-a49b-ba9141f26359',
      },
      club: 'ana',
      clubFullName: 'Los Angeles Angels',
      fileCode: 'ana',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'ana',
      teamColors: {
        complicationBackground: '#BA0021',
        complicationText: '#BBA0021',
        gradientBorderEndColor: '#a7001e',
        gradientBorderStartColor: '#BA0021',
        gradientEndColor: '#a7001e',
        gradientStartColor: '#ba0021',
        mlbtvGameTile: '#BA0021',
        navigationColor: '#BA0021',
        primaryDark: '#E5485D',
        primaryLight: '#BA0021',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#BA0021',
        quaternary: '#00234b',
        secondary: '#00234b',
        tertiary: '#cccccc',
      },
      teamID: 108,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ANA-Dark-06a17a17-5ac9-4bfd-900e-c8a228a16d68',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/ANA-Light-b96c45dc-fbfd-4e1e-862f-d96d1f934e7a',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ANA-Dark-06a17a17-5ac9-4bfd-900e-c8a228a16d68',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/ANA-Light-b96c45dc-fbfd-4e1e-862f-d96d1f934e7a',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAD-Active-Dark-54680c12-a912-45cb-a039-9e892b0f9bcd',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAD-Inactive-Dark-52970e66-29a4-4cce-b8a0-c3f3cc45274e',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAD-Active-Light-13578842-05c9-4ac0-98bc-29e2fb310752',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/LAD-Inactive-Light-19b1828e-fc28-4e5c-ba62-fce281b8786b',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAD-Active-Dark-54680c12-a912-45cb-a039-9e892b0f9bcd',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAD-Inactive-Dark-52970e66-29a4-4cce-b8a0-c3f3cc45274e',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAD-Active-Light-13578842-05c9-4ac0-98bc-29e2fb310752',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/LAD-Inactive-Light-19b1828e-fc28-4e5c-ba62-fce281b8786b',
      },
      club: 'la',
      clubFullName: 'Los Angeles Dodgers',
      fileCode: 'la',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'lan',
      teamColors: {
        complicationBackground: '#005A9C',
        complicationText: '#005A9C',
        gradientBorderEndColor: '#004272',
        gradientBorderStartColor: '#005a9c',
        gradientEndColor: '#004272',
        gradientStartColor: '#005a9c',
        mlbtvGameTile: '#005A9C',
        navigationColor: '#005A9C',
        primaryDark: '#0088F0',
        primaryLight: '#005A9C',
        primaryLinkDark: '#0088F0',
        primaryLinkLight: '#005A9C',
        quaternary: '#666666',
        secondary: '#87a0c3',
        tertiary: '#cfd5d5',
      },
      teamID: 119,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/LAN-Dark-67a5560d-350e-4c6c-b2b0-33c24df19725',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/LAN-Light-9f342437-3725-4c66-a317-0a40bea20ada',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/LAN-Dark-67a5560d-350e-4c6c-b2b0-33c24df19725',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/LAN-Light-9f342437-3725-4c66-a317-0a40bea20ada',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIA-Active-Dark-01a474a6-ffaf-47ef-83dc-17cbf16df9b1',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIA-Inactive-Dark-ad5fc8da-1741-47ea-91a5-4434499b9b2e',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIA-Active-Light-5e597776-294d-4c3d-a5cf-a56a53c1a475',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIA-Inactive-Light-8728893a-2b2e-4717-9e0c-397159972592',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIA-Active-Dark-01a474a6-ffaf-47ef-83dc-17cbf16df9b1',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIA-Inactive-Dark-ad5fc8da-1741-47ea-91a5-4434499b9b2e',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIA-Active-Light-5e597776-294d-4c3d-a5cf-a56a53c1a475',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIA-Inactive-Light-8728893a-2b2e-4717-9e0c-397159972592',
      },
      club: 'mia',
      clubFullName: 'Miami Marlins',
      fileCode: 'mia',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'mia',
      teamColors: {
        complicationBackground: '#000000',
        complicationText: '#00A3ED',
        gradientBorderEndColor: '#242424',
        gradientBorderStartColor: '#3a3a3a',
        gradientEndColor: '#242424',
        gradientStartColor: '#3a3a3a',
        mlbtvGameTile: '#000000',
        navigationColor: '#000000',
        primaryDark: '#00A3E0',
        primaryLight: '#000000',
        primaryLinkDark: '#00A3E0',
        primaryLinkLight: '#00A3ED',
        quaternary: '#41748D',
        secondary: '#41748D',
        tertiary: '#41748D',
      },
      teamID: 146,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIA-Dark-f7661431-a4a8-4e6b-b77a-0bb85df6f3cc',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIA-Light-edbcf168-9dd3-4984-be47-37d4b07ad280',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIA-Dark-f7661431-a4a8-4e6b-b77a-0bb85df6f3cc',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIA-Light-edbcf168-9dd3-4984-be47-37d4b07ad280',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIL-Active-Dark-da87911e-ea29-4954-a7a9-9f333ecbfca7',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIL-Inactive-Dark-dc0b4294-4500-4d79-a8b4-8b740108eafa',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIL-Active-Light-03984c02-d4ee-4ca4-9115-5bcde486f444',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIL-Inactive-Light-833030b7-407d-4e67-b078-7ade68982f10',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIL-Active-Dark-da87911e-ea29-4954-a7a9-9f333ecbfca7',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIL-Inactive-Dark-dc0b4294-4500-4d79-a8b4-8b740108eafa',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIL-Active-Light-03984c02-d4ee-4ca4-9115-5bcde486f444',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIL-Inactive-Light-833030b7-407d-4e67-b078-7ade68982f10',
      },
      club: 'mil',
      clubFullName: 'Milwaukee Brewers',
      fileCode: 'mil',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'mil',
      teamColors: {
        complicationBackground: '#11284B',
        complicationText: '#FFC72C',
        gradientBorderEndColor: '#081c41',
        gradientBorderStartColor: '#11284B',
        gradientEndColor: '#081c41',
        gradientStartColor: '#11284B',
        mlbtvGameTile: '#0A2351',
        navigationColor: '#11284B',
        primaryDark: '#FFC72C',
        primaryLight: '#11284B',
        primaryLinkDark: '#FFC72C',
        primaryLinkLight: '#11284B',
        quaternary: '#FFC72C',
        secondary: '#FFC72C',
        tertiary: '#d3cda3',
      },
      teamID: 158,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIL-Dark-498295e2-a31d-4dbb-bb5d-d15769fbd3b5',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIL-Light-b33c2b1c-7372-4009-a8d3-28b005428aa6',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIL-Dark-498295e2-a31d-4dbb-bb5d-d15769fbd3b5',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIL-Light-b33c2b1c-7372-4009-a8d3-28b005428aa6',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIN-Active-Dark-e76e5b24-9ff0-476a-9778-1202eb9aeb86',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIN-Inactive-Dark-c8bfc125-1989-42c3-905c-1da40ce85827',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIN-Active-Light-c48b85d9-a88a-4d3a-8cbc-376d8b18424d',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/MIN-Inactive-Light-110acb15-d978-4957-b43a-f5ba0d977835',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIN-Active-Dark-e76e5b24-9ff0-476a-9778-1202eb9aeb86',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIN-Inactive-Dark-c8bfc125-1989-42c3-905c-1da40ce85827',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIN-Active-Light-c48b85d9-a88a-4d3a-8cbc-376d8b18424d',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/MIN-Inactive-Light-110acb15-d978-4957-b43a-f5ba0d977835',
      },
      club: 'min',
      clubFullName: 'Minnesota Twins',
      fileCode: 'min',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'min',
      teamColors: {
        complicationBackground: '#002B5C',
        complicationText: '#D31145',
        gradientBorderEndColor: '#8a0b2d',
        gradientBorderStartColor: '#a40c35',
        gradientEndColor: '#8a0b2d',
        gradientStartColor: '#a40c35',
        mlbtvGameTile: '#002B5C',
        navigationColor: '#002B5C',
        primaryDark: '#E5485D',
        primaryLight: '#002B5C',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#D31145',
        quaternary: '#bc0033',
        secondary: '#bc0033',
        tertiary: '#cccccc',
      },
      teamID: 142,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIN-Dark-3d188835-e7fc-4701-8654-339a617344f9',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/MIN-Light-5876acec-211a-4bc3-bb07-741b5835478e',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIN-Dark-3d188835-e7fc-4701-8654-339a617344f9',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/MIN-Light-5876acec-211a-4bc3-bb07-741b5835478e',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYM-Active-Dark-bb6bebff-6a93-4557-b07d-f1950a864dd0',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYM-Inactive-Dark-814414dd-1ab0-479d-9476-0330686fef64',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYM-Active-Light-4777eaee-a154-4f63-95dd-73ed420807c3',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYM-Inactive-Light-c0ccee54-29d0-4f04-a6b2-90c992a2c3c9',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYM-Active-Dark-bb6bebff-6a93-4557-b07d-f1950a864dd0',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYM-Inactive-Dark-814414dd-1ab0-479d-9476-0330686fef64',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYM-Active-Light-4777eaee-a154-4f63-95dd-73ed420807c3',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYM-Inactive-Light-c0ccee54-29d0-4f04-a6b2-90c992a2c3c9',
      },
      club: 'nym',
      clubFullName: 'New York Mets',
      fileCode: 'nym',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'nyn',
      teamColors: {
        complicationBackground: '#002D72',
        complicationText: '#FF5910',
        gradientBorderEndColor: '#002259',
        gradientBorderStartColor: '#0f346e',
        gradientEndColor: '#002259',
        gradientStartColor: '#0f346e',
        mlbtvGameTile: '#002D72',
        navigationColor: '#002D72',
        primaryDark: '#FF5910',
        primaryLight: '#002D72',
        primaryLinkDark: '#FF5910',
        primaryLinkLight: '#002D72',
        quaternary: '#ff5731',
        secondary: '#ff5731',
        tertiary: '#e6e6e6',
      },
      teamID: 121,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/NYN-Dark-615e8b19-d502-4a09-8afe-0c583bd376d0',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/NYN-Light-44c17469-66e8-4cff-9d4c-83f44f3da867',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/NYN-Dark-615e8b19-d502-4a09-8afe-0c583bd376d0',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/NYN-Light-44c17469-66e8-4cff-9d4c-83f44f3da867',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYY-Active-Dark-a97f443b-8e5e-434c-86fe-18eb1636fa79',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYY-Inactive-Dark-01399564-e4e4-42a3-ace8-f4e39c440fb1',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYY-Active-Light-3e699076-2158-4bf2-9fe6-33ae1a96881c',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/NYY-Inactive-Light-22f1e54e-5e6b-4ed0-8aa4-73671b8172e4',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYY-Active-Dark-a97f443b-8e5e-434c-86fe-18eb1636fa79',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYY-Inactive-Dark-01399564-e4e4-42a3-ace8-f4e39c440fb1',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYY-Active-Light-3e699076-2158-4bf2-9fe6-33ae1a96881c',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/NYY-Inactive-Light-22f1e54e-5e6b-4ed0-8aa4-73671b8172e4',
      },
      club: 'nyy',
      clubFullName: 'New York Yankees',
      fileCode: 'nyy',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'nya',
      teamColors: {
        complicationBackground: '#132448',
        complicationText: '#E4002B',
        gradientBorderEndColor: '#162a55',
        gradientBorderStartColor: '#1b366e',
        gradientEndColor: '#162a55',
        gradientStartColor: '#1b366e',
        mlbtvGameTile: '#132448',
        navigationColor: '#132448',
        primaryDark: '#C4CED4',
        primaryLight: '#132448',
        primaryLinkDark: '#C4CED4',
        primaryLinkLight: '#003087',
        quaternary: '#666666',
        secondary: '#ceb72d',
        tertiary: '#cdcdce',
      },
      teamID: 147,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/NYA-Dark-c48b5ca8-31ab-46fd-828c-3fadab56341a',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/NYA-Light-71aa702b-abd7-470a-babb-83368b041811',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/NYA-Dark-c48b5ca8-31ab-46fd-828c-3fadab56341a',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/NYA-Light-71aa702b-abd7-470a-babb-83368b041811',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/OAK-Active-Dark-458e25f6-e4b1-4b32-94f5-244b5d7a3b74',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/OAK-Inactive-Dark-94839dd6-9bff-4845-bb1c-525afc8531f3',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/OAK-Active-Light-9bf47814-a15c-4438-b27f-0fb73b9a72b9',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/OAK-Inactive-Light-08af9c49-60f4-47bb-8f26-2e611d618a68',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/OAK-Active-Dark-458e25f6-e4b1-4b32-94f5-244b5d7a3b74',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/OAK-Inactive-Dark-94839dd6-9bff-4845-bb1c-525afc8531f3',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/OAK-Active-Light-9bf47814-a15c-4438-b27f-0fb73b9a72b9',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/OAK-Inactive-Light-08af9c49-60f4-47bb-8f26-2e611d618a68',
      },
      club: 'ath',
      clubFullName: 'Athletics ',
      fileCode: 'ath',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'ath',
      teamColors: {
        complicationBackground: '#003831',
        complicationText: '#EFB21E',
        gradientBorderEndColor: '#002b24',
        gradientBorderStartColor: '#003730',
        gradientEndColor: '#002b24',
        gradientStartColor: '#003730',
        mlbtvBorderColor: '#EFB21E',
        mlbtvGameTile: '#003831',
        navigationColor: '#003831',
        primaryDark: '#EFB21E',
        primaryLight: '#003831',
        primaryLinkDark: '#EFB21E',
        primaryLinkLight: '#003831',
        quaternary: '#666666',
        secondary: '#f3ea21',
        tertiary: '#fef9c4',
      },
      teamID: 133,
      wordmarks: {
        rasterDark: 'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/133_mbym4y',
        rasterLight: 'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/133_kzxi1o',
        svgDark: 'https://img.mlbstatic.com/mlb-mobile/oms/133_mbym4y',
        svgLight: 'https://img.mlbstatic.com/mlb-mobile/oms/133_kzxi1o',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PHI-Active-Dark-e02c8be3-7e73-4082-9ba0-e57939b1384d',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PHI-Inactive-Dark-feb0e316-a8e5-45d8-9a45-2baf1934d9a1',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PHI-Active-Light-225558e9-ca51-4bbf-ae1f-59e4aaafe038',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PHI-Inactive-Light-e747f1f0-396e-4e8e-8ca7-b8c976689734',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PHI-Active-Dark-e02c8be3-7e73-4082-9ba0-e57939b1384d',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PHI-Inactive-Dark-feb0e316-a8e5-45d8-9a45-2baf1934d9a1',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PHI-Active-Light-225558e9-ca51-4bbf-ae1f-59e4aaafe038',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PHI-Inactive-Light-e747f1f0-396e-4e8e-8ca7-b8c976689734',
      },
      club: 'phi',
      clubFullName: 'Philadelphia Phillies',
      fileCode: 'phi',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'phi',
      teamColors: {
        complicationBackground: '#003278',
        complicationText: '#E81828',
        gradientBorderEndColor: '#a1111d',
        gradientBorderStartColor: '#b91320',
        gradientEndColor: '#a1111d',
        gradientStartColor: '#b91320',
        mlbtvGameTile: '#E81828',
        navigationColor: '#E81828',
        primaryDark: '#E5485D',
        primaryLight: '#003278',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#E81828',
        quaternary: '#E81828',
        secondary: '#E81828',
        tertiary: '#959597',
      },
      teamID: 143,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/PHI-Dark-3004f4a1-801e-4c8e-884c-ea29e08f2e8c',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/PHI-Light-c4d2e329-2c03-42ba-8c9e-dfb6ecca0f34',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/PHI-Dark-3004f4a1-801e-4c8e-884c-ea29e08f2e8c',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/PHI-Light-c4d2e329-2c03-42ba-8c9e-dfb6ecca0f34',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PIT-Active-Dark-70d150b1-ab8a-4c07-8a5d-244add1926b4',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PIT-Inactive-Dark-c7e5f79c-a427-4fe2-b7ac-a32b1e446b48',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PIT-Active-Light-698a224a-c3d2-4301-b3f7-ea8d9945992f',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/PIT-Inactive-Light-2ba5f585-8cc7-4880-a778-f400cbc87893',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PIT-Active-Dark-70d150b1-ab8a-4c07-8a5d-244add1926b4',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PIT-Inactive-Dark-c7e5f79c-a427-4fe2-b7ac-a32b1e446b48',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PIT-Active-Light-698a224a-c3d2-4301-b3f7-ea8d9945992f',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/PIT-Inactive-Light-2ba5f585-8cc7-4880-a778-f400cbc87893',
      },
      club: 'pit',
      clubFullName: 'Pittsburgh Pirates',
      fileCode: 'pit',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'pit',
      teamColors: {
        complicationBackground: '#000000',
        complicationText: '#FDB827',
        gradientBorderEndColor: '#e2a522',
        gradientBorderStartColor: '#fdb827',
        gradientEndColor: '#e2a522',
        gradientStartColor: '#fdb827',
        mlbtvGameTile: '#000000',
        navigationColor: '#000000',
        primaryDark: '#FDB827',
        primaryLight: '#000000',
        primaryLinkDark: '#FDB827',
        primaryLinkLight: '#000000',
        quaternary: '#666666',
        secondary: '#e1b81e',
        tertiary: '#b3821b',
      },
      teamID: 134,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/PIT-Dark-cb02ea30-cc85-44ec-99ee-0404bb69bba5',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/PIT-Light-0e97ceb6-f0c6-4294-b4bf-3dc0396a9375',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/PIT-Dark-cb02ea30-cc85-44ec-99ee-0404bb69bba5',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/PIT-Light-0e97ceb6-f0c6-4294-b4bf-3dc0396a9375',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SD-Active-Dark-817749a6-17d4-46b9-9db9-2e2dc7db0203',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SD-Inactive-Dark-3d85084e-2e66-49e7-87a8-d9f85802ddd1',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SD-Active-Light-3c96e335-2e88-48be-836a-3272376d4b2e',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SD-Inactive-Light-dd68112b-7a41-4eb2-984b-09fa5444a3f1',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SD-Active-Dark-817749a6-17d4-46b9-9db9-2e2dc7db0203',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SD-Inactive-Dark-3d85084e-2e66-49e7-87a8-d9f85802ddd1',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SD-Active-Light-3c96e335-2e88-48be-836a-3272376d4b2e',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SD-Inactive-Light-dd68112b-7a41-4eb2-984b-09fa5444a3f1',
      },
      club: 'sd',
      clubFullName: 'San Deigo Padres',
      fileCode: 'sd',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'sdn',
      teamColors: {
        complicationBackground: '#2F241D',
        complicationText: '#FFC425',
        gradientBorderEndColor: '#040f2f',
        gradientBorderStartColor: '#0e1b41',
        gradientEndColor: '#040f2f',
        gradientStartColor: '#0e1b41',
        mlbtvGameTile: '#2F241D',
        navigationColor: '#2D241D',
        primaryDark: '#FFC425',
        primaryLight: '#2F241D',
        primaryLinkDark: '#FFC425',
        primaryLinkLight: '#2D241D',
        quaternary: '#FFC425',
        secondary: '#FFC425',
        tertiary: '#e1d5b1',
      },
      teamID: 135,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SDN-Dark-822ec52e-1151-4f64-a0e0-98aab952df62',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SDN-Light-47a4316e-cd91-44a5-ae27-5fd5cfe0b3e4',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SDN-Dark-822ec52e-1151-4f64-a0e0-98aab952df62',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SDN-Light-47a4316e-cd91-44a5-ae27-5fd5cfe0b3e4',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SEA-Active-Dark-e06142ce-04c1-446e-b1d6-22085b3a2dad',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SEA-Inactive-Dark-cdf10a2f-471e-4915-ba84-a6a9dd34a664',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SEA-Active-Light-94c93a8e-429d-41ac-bf93-35a7f52fae65',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SEA-Inactive-Light-d75644ca-832e-44d3-a673-9b3a720dae0c',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SEA-Active-Dark-e06142ce-04c1-446e-b1d6-22085b3a2dad',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SEA-Inactive-Dark-cdf10a2f-471e-4915-ba84-a6a9dd34a664',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SEA-Active-Light-94c93a8e-429d-41ac-bf93-35a7f52fae65',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SEA-Inactive-Light-d75644ca-832e-44d3-a673-9b3a720dae0c',
      },
      club: 'sea',
      clubFullName: 'Seattle Mariners',
      fileCode: 'sea',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'sea',
      teamColors: {
        complicationBackground: '#0C2C56',
        complicationText: '#6C8CB6',
        gradientBorderEndColor: '#0a2446',
        gradientBorderStartColor: '#112e55',
        gradientEndColor: '#0a2446',
        gradientStartColor: '#112e55',
        mlbtvGameTile: '#0C2C56',
        navigationColor: '#0C2C56',
        primaryDark: '#009985',
        primaryLight: '#0C2C56',
        primaryLinkDark: '#009985',
        primaryLinkLight: '#005C5C',
        quaternary: '#005c5c',
        secondary: '#005c5c',
        tertiary: '#cccccc',
      },
      teamID: 136,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SEA-Dark-6b1135e2-3f29-4b2a-ad43-fe86e83015b7',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SEA-Light-00a34874-6008-4d8c-8b53-47c3a573b8f4',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SEA-Dark-6b1135e2-3f29-4b2a-ad43-fe86e83015b7',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SEA-Light-00a34874-6008-4d8c-8b53-47c3a573b8f4',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SF-Active-Dark-4e845328-6bae-40a4-bb98-cd7918309a2f',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SF-Inactive-Dark-c894adce-1c82-45a5-93af-6b4685f68d8d',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SF-Active-Light-279b899b-99cc-4cfa-86ea-4677b8e77ec2',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/SF-Inactive-Light-26847371-ef80-4fea-96a3-316a08aa8e6d',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SF-Active-Dark-4e845328-6bae-40a4-bb98-cd7918309a2f',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SF-Inactive-Dark-c894adce-1c82-45a5-93af-6b4685f68d8d',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SF-Active-Light-279b899b-99cc-4cfa-86ea-4677b8e77ec2',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/SF-Inactive-Light-26847371-ef80-4fea-96a3-316a08aa8e6d',
      },
      club: 'sf',
      clubFullName: 'San Francisco Giants',
      fileCode: 'sf',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'sfn',
      teamColors: {
        complicationBackground: '#FD5A1E',
        complicationText: '#FD5A1E',
        gradientBorderEndColor: '#a03a14',
        gradientBorderStartColor: '#c24517',
        gradientEndColor: '#a03a14',
        gradientStartColor: '#c24517',
        mlbtvGameTile: '#000000',
        navigationColor: '#FD5A1E',
        primaryDark: '#FD5A1E',
        primaryLight: '#FD5A1E',
        primaryLinkDark: '#FD5A1E',
        primaryLinkLight: '#FD5A1E',
        quaternary: '#666666',
        secondary: '#fd5a1e',
        tertiary: '#cfd0bf',
      },
      teamID: 137,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SFN-Dark-9703c90d-6ce9-455f-8eb4-9df9b799a72e',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SFN-Light-0268ccaf-0c40-49e2-a15f-3b602528103a',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SFN-Dark-9703c90d-6ce9-455f-8eb4-9df9b799a72e',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SFN-Light-0268ccaf-0c40-49e2-a15f-3b602528103a',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/STL-Active-Dark-7503f563-4b10-4ee2-8fcd-4a3ce19aa987',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/STL-Inactive-Dark-f1add9d2-4c70-436f-8679-be3834115b45',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/STL-Active-Light-ed830965-8a97-48ad-b470-7aaa3849bcbd',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/STL-Inactive-Light-8185fdce-a593-403c-ba49-973d10eb35dd',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/STL-Active-Dark-7503f563-4b10-4ee2-8fcd-4a3ce19aa987',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/STL-Inactive-Dark-f1add9d2-4c70-436f-8679-be3834115b45',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/STL-Active-Light-ed830965-8a97-48ad-b470-7aaa3849bcbd',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/STL-Inactive-Light-8185fdce-a593-403c-ba49-973d10eb35dd',
      },
      club: 'stl',
      clubFullName: 'St. Louis Cardinals',
      fileCode: 'stl',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'sln',
      teamColors: {
        complicationBackground: '#C41E3A',
        complicationText: '#C41E3A',
        gradientBorderEndColor: '#9f182e',
        gradientBorderStartColor: '#c21e3a',
        gradientEndColor: '#9f182e',
        gradientStartColor: '#c21e3a',
        mlbtvGameTile: '#C41E3A',
        navigationColor: '#C41E3A',
        primaryDark: '#E5485D',
        primaryLight: '#C41E3A',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#C41E3A',
        quaternary: '#04437d',
        secondary: '#ffff00',
        tertiary: '#bcb9b9',
      },
      teamID: 138,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SLN-Dark-f0f755da-6de2-4672-9492-f71cdf047e24',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/SLN-Light-755c2b5d-a2ba-4b12-8c41-ad1ee4ee45c8',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SLN-Dark-f0f755da-6de2-4672-9492-f71cdf047e24',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/SLN-Light-755c2b5d-a2ba-4b12-8c41-ad1ee4ee45c8',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TB-Active-Dark-392f0e73-ac6e-467e-a716-1869dba4e582',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TB-Inactive-Dark-c71ca6d9-80d5-438c-a9ba-535796307e96',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TB-Active-Light-b434b4e1-5739-4509-aa02-b04d55594719',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TB-Inactive-Light-839414f0-853f-436f-90f9-245fa10705af',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TB-Active-Dark-392f0e73-ac6e-467e-a716-1869dba4e582',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TB-Inactive-Dark-c71ca6d9-80d5-438c-a9ba-535796307e96',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TB-Active-Light-b434b4e1-5739-4509-aa02-b04d55594719',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TB-Inactive-Light-839414f0-853f-436f-90f9-245fa10705af',
      },
      club: 'tb',
      clubFullName: 'Tampa Bay Rays',
      fileCode: 'tb',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'tba',
      teamColors: {
        complicationBackground: '#092C5C',
        complicationText: '#8FBCE6',
        gradientBorderEndColor: '#07244c',
        gradientBorderStartColor: '#092c5b',
        gradientEndColor: '#07244c',
        gradientStartColor: '#092c5b',
        mlbtvGameTile: '#092C5C',
        navigationColor: '#092C5C',
        primaryDark: '#8FBCE6',
        primaryLight: '#092C5C',
        primaryLinkDark: '#8FBCE6',
        primaryLinkLight: '#092C5C',
        quaternary: '#3377dd',
        secondary: '#f5d130',
        tertiary: '#8fbce6',
      },
      teamID: 139,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TBA-Dark-d5332932-9588-4528-867a-c101ff65e733',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TBA-Light-a71b91a9-4fb4-4eb2-8cc1-363d9a030bc3',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TBA-Dark-d5332932-9588-4528-867a-c101ff65e733',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TBA-Light-a71b91a9-4fb4-4eb2-8cc1-363d9a030bc3',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TEX-Active-Dark-58931c6a-4e62-4b97-b0e1-f04c203cf9a2',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TEX-Inactive-Dark-f4321bbb-3b56-47c1-a4b1-a1e034957c90',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TEX-Active-Light-1b698513-560e-4fb7-ada3-473819afc019',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TEX-Inactive-Light-6bc3a222-d19d-41ca-b3f5-1dc9984b6e87',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TEX-Active-Dark-58931c6a-4e62-4b97-b0e1-f04c203cf9a2',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TEX-Inactive-Dark-f4321bbb-3b56-47c1-a4b1-a1e034957c90',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TEX-Active-Light-1b698513-560e-4fb7-ada3-473819afc019',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TEX-Inactive-Light-6bc3a222-d19d-41ca-b3f5-1dc9984b6e87',
      },
      club: 'tex',
      clubFullName: 'Texas Rangers',
      fileCode: 'tex',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'tex',
      teamColors: {
        complicationBackground: '#003278',
        complicationText: '#C0111F',
        gradientBorderEndColor: '#002456',
        gradientBorderStartColor: '#043274',
        gradientEndColor: '#002456',
        gradientStartColor: '#043274',
        mlbtvGameTile: '#003278',
        navigationColor: '#003278',
        primaryDark: '#E5485D',
        primaryLight: '#003278',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#C0111F',
        quaternary: '#666666',
        secondary: '#000000',
        tertiary: '#cccccc',
      },
      teamID: 140,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TEX-Dark-3499c328-c39d-468d-9104-2da9d8fd88c0',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TEX-Light-99669072-48a0-4bb0-b5b8-8bb37914042c',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TEX-Dark-3499c328-c39d-468d-9104-2da9d8fd88c0',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TEX-Light-99669072-48a0-4bb0-b5b8-8bb37914042c',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TOR-Active-Dark-49b53cdc-4e5d-425a-8f1d-8a10f28debae',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TOR-Inactive-Dark-3a13fce8-c280-4555-a249-763b8556b395',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TOR-Active-Light-a9b44900-8a26-4e6a-a8fa-2ab6873a252e',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/TOR-Inactive-Light-b40bc038-3966-486a-8e3e-0134a7247f58',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TOR-Active-Dark-49b53cdc-4e5d-425a-8f1d-8a10f28debae',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TOR-Inactive-Dark-3a13fce8-c280-4555-a249-763b8556b395',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TOR-Active-Light-a9b44900-8a26-4e6a-a8fa-2ab6873a252e',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/TOR-Inactive-Light-b40bc038-3966-486a-8e3e-0134a7247f58',
      },
      club: 'tor',
      clubFullName: 'Toronto Blue Jays',
      fileCode: 'tor',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'tor',
      teamColors: {
        complicationBackground: '#134A8E',
        complicationText: '#538ACE',
        gradientBorderEndColor: '#002766',
        gradientBorderStartColor: '#003399',
        gradientEndColor: '#002766',
        gradientStartColor: '#003399',
        mlbtvGameTile: '#134A8E',
        navigationColor: '#134A8E',
        primaryDark: '#0088F0',
        primaryLight: '#134A8E',
        primaryLinkDark: '#0088F0',
        primaryLinkLight: '#134A8E',
        quaternary: '#0099cc',
        secondary: '#0099cc',
        tertiary: '#c9d8e5',
      },
      teamID: 141,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TOR-Dark-b87b2318-62a2-4f26-8d8c-59c4c1f0186c',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/TOR-Light-107f2ba3-5f4a-4742-afcc-17f59767a103',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TOR-Dark-b87b2318-62a2-4f26-8d8c-59c4c1f0186c',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/TOR-Light-107f2ba3-5f4a-4742-afcc-17f59767a103',
      },
    },
    {
      capLogos: {
        rasterDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/WSH-Active-Dark-287ef6bc-8924-4720-be76-ce5ec832da8d',
        rasterDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/WSH-Inactive-Dark-12976c89-f853-4555-b810-912535324f9b',
        rasterLightActive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/WSH-Active-Light-52bba439-5ef8-4780-aa0d-95e74f959691',
        rasterLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/caplogos/WSH-Inactive-Light-970df6d0-7071-45ed-b486-5fa43701200b',
        svgDarkActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/WSH-Active-Dark-287ef6bc-8924-4720-be76-ce5ec832da8d',
        svgDarkInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/WSH-Inactive-Dark-12976c89-f853-4555-b810-912535324f9b',
        svgLightActive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/WSH-Active-Light-52bba439-5ef8-4780-aa0d-95e74f959691',
        svgLightInactive:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/caplogos/WSH-Inactive-Light-970df6d0-7071-45ed-b486-5fa43701200b',
      },
      club: 'was',
      clubFullName: 'Washington Nationals',
      fileCode: 'was',
      mlbtv: {
        welcome: {
          browse: { imageUrl: '', templateUrl: '' },
          create: { imageUrl: '', templateUrl: '' },
          explore: { imageUrl: '', templateUrl: '' },
        },
      },
      sportCode: 'mlb',
      teamCode: 'was',
      teamColors: {
        complicationBackground: '#AB0003',
        complicationText: '#AB0003',
        gradientBorderEndColor: '#920002',
        gradientBorderStartColor: '#a9000e',
        gradientEndColor: '#920002',
        gradientStartColor: '#a9000e',
        mlbtvGameTile: '#AB0003',
        navigationColor: '#AB0003',
        primaryDark: '#E5485D',
        primaryLight: '#AB0003',
        primaryLinkDark: '#E5485D',
        primaryLinkLight: '#AB0003',
        quaternary: '#11225b',
        secondary: '#11225b',
        tertiary: '#9d7d3d',
      },
      teamID: 120,
      wordmarks: {
        rasterDark:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/WAS-Dark-e5010c67-27e6-4cc8-a230-edbc3a185a7b',
        rasterLight:
          'https://img.mlbstatic.com/mlb-mobile/f_{type},w_{width}/oms/branding/wordmarks/WAS-Light-4fe530e8-2970-4012-8d30-fd5ab3340c39',
        svgDark:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/WAS-Dark-e5010c67-27e6-4cc8-a230-edbc3a185a7b',
        svgLight:
          'https://img.mlbstatic.com/mlb-mobile/oms/branding/wordmarks/WAS-Light-4fe530e8-2970-4012-8d30-fd5ab3340c39',
      },
    },
  ],
};
