import {
  EpgAlternate,
  StatsEpgAlternateKeywordValue,
} from 'services/statsApi/condensedAndRecap/types';

export const getEpgAlternateSlug = (
  epgAlternate: EpgAlternate[] | undefined,
  keywordValue: StatsEpgAlternateKeywordValue,
) => {
  if (!epgAlternate) return '';

  return (
    epgAlternate.find((item) =>
      item.items.find(({ keywordsDisplay }) =>
        keywordsDisplay.find(({ value }) => value === keywordValue),
      ),
    )?.items[0]?.slug ?? ''
  );
};
