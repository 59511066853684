import { useEffect } from 'react';
import { selectIsAppInitialized } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTTSEnabled } from 'core-app/store/deviceSettings/deviceSettingsSlice';
import { getDeviceTTSEnabled } from 'utils/platform';

export function useDeviceStartup() {
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAppInitialized) return;

    const init = async () => {
      const ttsEnabled = await getDeviceTTSEnabled();
      dispatch(setTTSEnabled(ttsEnabled));
    };

    init();
  }, [dispatch, isAppInitialized]);
}
