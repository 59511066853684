import api, { GET } from 'services/api';
import { Branding } from 'store/branding';

import { validateBrandingData } from './normalizers';

export async function fetchBranding(): Promise<Branding> {
  try {
    const response = await api<Branding>(GET, {
      endpoint: 'json',
      service: 'branding',
    });

    return validateBrandingData(response);
  } catch (error) {
    console.error('Could not fetch branding data', error);
    throw error;
  }
}
