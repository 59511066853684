/* eslint-disable sort-keys-fix/sort-keys-fix */
import { black, grey1 } from 'styles/colors/colors';

export const theme = {
  borderRadius: {
    /** 3px */
    sm: '.3rem',
    /** 6px */
    md: '.6rem',
    /** 9px */
    lg: '.9rem',
    /** 15px */
    xl: '1.5rem',
    /** 100% */
    full: '999.9rem',
  },
  colors: {
    background: black,
    bodyText: grey1,
  },
  fontSize: {
    /** 18px */
    xs: '1.8rem',
    /** 21px */
    sm: '2.1rem',
    /** 24px */
    md: '2.4rem',
    /** 27px */
    lg: '2.7rem',
    /** 33px */
    xl: '3.3rem',
    /** 36px */
    '2xl': '3.6rem',
    /** 39px */
    '3xl': '3.9rem',
    /** 48px */
    '4xl': '4.8rem',
    /** 57px */
    '5xl': '5.7rem',
    /** 75px */
    '6xl': '7.5rem',
  },
  fontWeight: {
    100: 100,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
  },
  // Sourced from TailwindCSS - https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
  spacing: {
    /** 1.5px */
    px: '.15rem',
    /** 3px */
    0.5: '.3rem',
    /** 6px */
    1: '.6rem',
    /** 9px */
    2: '.9rem',
    /** 12px */
    3: '1.2rem',
    /** 15px */
    4: '1.5rem',
    /** 21px */
    5: '2.1rem',
    /** 24px */
    6: '2.4rem',
    /** 27px */
    7: '2.7rem',
    /** 33px */
    8: '3.3rem',
    /** 36px */
    9: '3.6rem',
    /** 39px */
    10: '3.9rem',
    /** 45px */
    11: '4.5rem',
    /** 48px */
    12: '4.8rem',
    /** 51px */
    13: '5.1rem',
    /** 57px */
    14: '5.7rem',
    /** 63px */
    16: '6.3rem',
    /** 81px */
    20: '8.1rem',
    /** 96px */
    24: '9.6rem',
    /** 111px */
    28: '11.1rem',
    /** 129px */
    32: '12.9rem',
    /** 144px */
    36: '14.4rem',
    /** 159px */
    40: '15.9rem',
    /** 177px */
    44: '17.7rem',
    /** 192px */
    48: '19.2rem',
    /** 207px */
    52: '20.7rem',
    /** 225px */
    56: '22.5rem',
    /** 240px */
    60: '24rem',
    /** 255px */
    64: '25.5rem',
    /** 288px */
    72: '28.8rem',
    /** 321px */
    80: '32.1rem',
    /** 384px */
    96: '38.4rem',
  },
};
