import { Isu } from '@mlbtv-clients/isu';
import {
  AdExperienceType,
  Content,
  contentSearch,
  ContentType,
  Device,
  Experience,
  initPlaybackSession,
  InitSession,
  initSession,
  LanguagePreference,
  MediaInfo,
  PlaybackQuality,
  PlaybackSession,
  requestExperience,
  requestMediaInfo,
} from '@mlbtv-clients/services';
import { ConfigEnv } from 'store/config';
import { getClientType, getDeviceId } from 'utils/platform';
import {
  getMLBExperienceStorage,
  getOktaAuthStorage,
  setMLBExperienceStorage,
} from 'utils/storage';

import { MLBExperienceConfig, serviceConfigsByEnv } from './config';
import { MLBExperienceStorage, MLBInitPlaybackSessionArgs } from './types';

class MLBExperience {
  public device: Device;

  private config: MLBExperienceConfig;

  constructor() {
    this.device = this.generateDeviceInfo();
    this.config = serviceConfigsByEnv.prod;
  }

  public async init(configEnv: ConfigEnv): Promise<void> {
    this.config = serviceConfigsByEnv[configEnv];

    const experienceInfo = getMLBExperienceStorage();
    await this.setExperienceInformation(experienceInfo);
  }

  public async setExperienceInformation(storageInfo: MLBExperienceStorage): Promise<void> {
    const { deviceId } = storageInfo;
    this.device.knownDeviceId = await getDeviceId(deviceId);
  }

  public generateDeviceInfo(): Device {
    const isu = Isu();
    const { browser, browserVersion, deviceFamily, model, platform, platformVersion, vendor } = isu;

    const device = {
      appVersion: __VERSION__,
      deviceFamily,
      extraAttrs: {
        browserName: browser,
        browserVersion,
      },
      languagePreference: LanguagePreference.ENGLISH,
      manufacturer: vendor,
      model,
      os: platform,
      osVersion: platformVersion,
    };

    return device;
  }

  public async initPlaybackSession({
    mediaId,
    mlbSessionId,
  }: MLBInitPlaybackSessionArgs): Promise<PlaybackSession> {
    const { accessToken } = getOktaAuthStorage();
    const clientType = await getClientType();
    const playbackSession = await initPlaybackSession({
      adCapabilities: [AdExperienceType.GOOGLE_STANDALONE_AD_PODS],
      deviceId: this.device.knownDeviceId ?? '',
      mediaId,
      quality: PlaybackQuality.PLACEHOLDER,
      requestData: {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'x-client-name': clientType,
          'x-client-version': __VERSION__,
        },
      },
      serviceConfig: this.config.playbackSession,
      sessionId: mlbSessionId,
    });

    return playbackSession;
  }

  public async registerDevice(): Promise<InitSession> {
    const { accessToken } = getOktaAuthStorage();
    const clientType = await getClientType();

    const response = await initSession({
      clientType,
      device: this.device,
      requestData: {
        headers: {
          ...(accessToken && {
            Authorization: `Bearer ${accessToken}`,
          }),
          'x-client-name': clientType,
          'x-client-version': __VERSION__,
        },
      },
      serviceConfig: this.config.registerDevice,
    });

    const {
      data: {
        initSession: { deviceId },
      },
    } = response;

    // Store session information
    const experienceInfo = { deviceId };
    await this.setExperienceInformation(experienceInfo);
    setMLBExperienceStorage(experienceInfo);

    return response.data.initSession;
  }

  public async requestExperience(): Promise<Experience> {
    const { accessToken } = getOktaAuthStorage();
    const clientType = await getClientType();
    const { experience } = await requestExperience({
      clientType,
      requestData: {
        headers: {
          ...(accessToken && {
            Authorization: `Bearer ${accessToken}`,
          }),
        },
      },
      serviceConfig: this.config.experience,
    });

    return experience;
  }

  public async requestMediaInfo(mediaId: string): Promise<MediaInfo> {
    const { accessToken } = getOktaAuthStorage();
    const clientType = await getClientType();
    const mediaInfo = await requestMediaInfo({
      ids: [mediaId],
      serviceConfig: this.config.mediaInfo,
      ...(accessToken && {
        requestData: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-client-name': clientType,
            'x-client-version': __VERSION__,
          },
        },
      }),
    });

    return mediaInfo[0];
  }

  public async contentSearch(gamePk: string): Promise<Content[]> {
    const { accessToken } = getOktaAuthStorage();
    const clientType = await getClientType();
    const response = await contentSearch({
      query: `GamePk = ${gamePk} AND ContentType = "${ContentType.GAME}" RETURNING AwayTeamId, ContentId, HomeTeamId, MediaId`,
      serviceConfig: this.config.contentSearch,
      ...(accessToken && {
        requestData: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-client-name': clientType,
            'x-client-version': __VERSION__,
          },
        },
      }),
    });

    return response;
  }
}

export default new MLBExperience();
