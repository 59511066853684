// playback scenarios
export const PlaybackScenarios = {
  PLAYSTATION: 'playstation',
  PLAYSTATION_DFP2_SSAI: 'playstation-dfp2~ssai',
  PLAYSTATION_SSAI: 'playstation~ssai',
};

export const BLACKOUT_ERROR_NAME = 'BlackoutException';
export const COMPLETE_PLAYLIST_TYPE = 'COMPLETE';
export const CONCURRENCY_ERROR_NAME = 'StreamConcurrencyException';
export const LIVE = 'LIVE';
export const NOT_ENTITLED_ERROR_NAME = 'NotEntitledException';

export const KeywordType = {
  inning: 'inning',
  top: 'top',
};

export const MilestoneTimeType = {
  absolute: 'absolute',
  offset: 'offset',
};

export const MilestoneType = {
  BROADCAST_START: 'BROADCAST_START',
  GAME_LIVE: 'GAME_LIVE',
  INNING_END: 'INNING_END',
  INNING_START: 'INNING_START',
  STREAM_START: 'STREAM_START',
};
