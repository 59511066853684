import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchProfileData,
  ProfileData,
  updateProfileData,
  UpdateProfileResponse,
} from 'services/profile';
import { RootState } from 'store';
import { signOut } from 'store/mlbAuth';
import { selectTeamIdToAbbrv } from 'store/teams';
import {
  getLogInAfterCreateStorage,
  setFavoriteTeamStorage,
  setFollowingTeamsStorage,
  setHideSpoilersStorage,
} from 'utils/storage';

export interface ProfileState {
  doNotSell: boolean;
  email: string;
  error: string | null;
  favoriteTeam: number;
  firstName: string;
  followingTeams: number[];
  hideSpoilers: boolean;
  lastName: string;
  loading: boolean;
  userId: string;
}

export const initialState: ProfileState = {
  doNotSell: false,
  email: '',
  error: null,
  favoriteTeam: 0,
  firstName: '',
  followingTeams: [],
  hideSpoilers: false,
  lastName: '',
  loading: false,
  userId: '',
};

const signOutState = {
  doNotSell: false,
  email: '',
  error: null,
  firstName: '',
  lastName: '',
  loading: false,
  userId: '',
};

export const fetchProfileFlow = createAsyncThunk<ProfileState, undefined, { state: RootState }>(
  'profile/fetchProfile',
  async (_, { getState }) => {
    const state = getState();
    const {
      mlbAuth: { accessToken },
    } = state;

    const profileData = await fetchProfileData(accessToken);
    const isLoginAfterCreate = getLogInAfterCreateStorage();
    const { favoriteTeam, followingTeams, hideSpoilers } = profileData;

    if (!isLoginAfterCreate) {
      setFavoriteTeamStorage(favoriteTeam);
      setFollowingTeamsStorage(followingTeams);
      setHideSpoilersStorage(hideSpoilers);
    }

    return profileData;
  },
);

export const updateProfileFlow = createAsyncThunk<
  UpdateProfileResponse,
  Partial<ProfileData>,
  { state: RootState }
>('profile/updateProfile', async (profile, { getState }) => {
  const state = getState();
  const {
    mlbAuth: { accessToken },
  } = state;

  return updateProfileData(accessToken, profile);
});

export const profileSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchProfileFlow.pending, (state) => ({
      ...state,
      loading: true,
    }));

    builder.addCase(fetchProfileFlow.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
    }));

    builder.addCase(fetchProfileFlow.rejected, () => initialState);

    builder.addCase(signOut, (state) => ({ ...state, ...signOutState }));
  },
  initialState,
  name: 'profile',
  reducers: {
    setFavoriteTeam(state: ProfileState, action: PayloadAction<number>) {
      state.favoriteTeam = action.payload;
    },
    setFollowingTeams(state: ProfileState, action: PayloadAction<number[]>) {
      state.followingTeams = action.payload;
    },
    setHideSpoilers(state: ProfileState, action: PayloadAction<boolean>) {
      state.hideSpoilers = action.payload;
    },
  },
});

// Actions
export const { setFavoriteTeam, setFollowingTeams, setHideSpoilers } = profileSlice.actions;

// Selectors
export const selectDoNotSell = (state: RootState) => state.profile.doNotSell;

export const selectEmail = (state: RootState) => state.profile.email;

export const selectFavoriteTeamId = (state: RootState) => state.profile.favoriteTeam;

export const selectFavoriteTeam = createSelector(
  [selectTeamIdToAbbrv, selectFavoriteTeamId],
  (teamIdToAbbrv, favoriteTeamId) => teamIdToAbbrv[favoriteTeamId],
);

export const selectFollowingTeamIds = (state: RootState) => state.profile.followingTeams;

export const selectFollowingTeams = createSelector(
  [selectTeamIdToAbbrv, selectFollowingTeamIds],
  (teamIdToAbbrv, followingTeamIds) => {
    const toClubCode = (teamId: number): string => teamIdToAbbrv[teamId];

    return followingTeamIds.map(toClubCode).filter((code) => !!code) as string[];
  },
);

export const selectHideSpoilers = (state: RootState) => state.profile.hideSpoilers;

export const selectName = (state: RootState) =>
  [state.profile.firstName, state.profile.lastName].filter((x) => x).join(' ');

export const selectIsProfileLoading = (state: RootState) => state.profile.loading;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
