import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import {
  ErrorTrackingPlugin,
  enableErrorTracking,
} from '@convivainc/conviva-js-appanalytics-error-tracking';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  enableButtonClickTracking,
} from '@convivainc/conviva-js-appanalytics-click-tracking';
import { ConvivaConfig } from 'store/config';

let initialized = false;

export const initializeConvivaEco = (convivaConfig: ConvivaConfig) => {
  if (!convivaConfig.isECOEnabled) return;
  if (initialized) return;
  initialized = true;

  convivaAppTracker({
    appId: `mlbtv-app-${__PLATFORM__}`,
    appVersion: __VERSION__,
    contexts: {
      performanceTiming: true,
    },
    convivaCustomerKey: convivaConfig?.prod?.customerKey,
    plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
  });

  enableButtonClickTracking();
  enableErrorTracking();
  enableLinkClickTracking();
};
