import { useCallback, useEffect, useState } from 'react';
import { getArrowDirectionText, waitForElement } from './utils';
import { ARROW_KEYS } from 'constants/spatialNavigation';
import { useController, useTextToSpeech, useTTSEnabled } from 'hooks';
import { utteranceListFromElement } from 'utils/textToSpeech/utteranceListFromElement';
import { without } from 'lodash';
import { getTextToSpeechStrategyStorage } from 'utils/storage';
import { TTSStrategy } from 'utils/textToSpeech/types';
import { Buttons } from 'utils/buttons';
import { useLocation } from 'react-router-dom';
import { utteranceFromIdReferenceList } from 'utils/textToSpeech/utteranceFromIdReferenceList';
import { ROUTES } from 'constants/screens';
import { DEFAULT_SPATIAL_NAV_SETTINGS } from 'constants/focus';

const { ScreenReader } = TTSStrategy;
const { throttle } = DEFAULT_SPATIAL_NAV_SETTINGS;

export const useTTSNavigation = () => {
  const [prevActiveElement, setPrevActiveElement] = useState<Element | null>(null);
  const [prevUtteranceList, setPrevUtteranceList] = useState<string[]>([]);

  const textToSpeechStrategy = getTextToSpeechStrategyStorage();

  const ttsEnabled = useTTSEnabled();
  const { speak, t } = useTextToSpeech();

  const { pathname } = useLocation();

  const handleKeyup = useCallback(
    async (e: KeyboardEvent) => {
      setTimeout(async () => {
        if (!ttsEnabled) return;

        let utteranceToSpeak = '';
        const isArrowPress = ARROW_KEYS.has(e.keyCode);
        const focusChanged = prevActiveElement !== document.activeElement;
        const liveRegion = document.getElementById('aria-live-region');
        // ensure screen is loaded before speaking
        const screenId = pathname === ROUTES.HOME ? 'homeScreen' : `${pathname.slice(1)}Screen`;
        const screen = await waitForElement(`[data-testid="${screenId}"]`);

        // focus did not change
        if (isArrowPress && !focusChanged) {
          const arrowDirectionText = getArrowDirectionText(e.keyCode);
          utteranceToSpeak = [
            t(`noSelectableContent.${arrowDirectionText}`),
            utteranceFromIdReferenceList(`noSelectableContent-${arrowDirectionText}`),
          ].join(' . ');
        }

        // focus changed
        if (focusChanged && screen) {
          const newUtteranceList = utteranceListFromElement(document.activeElement);
          utteranceToSpeak = without(newUtteranceList, ...prevUtteranceList).join(' . ');
          setPrevUtteranceList(newUtteranceList);
        }

        // determine which TTS strategy to use
        if (utteranceToSpeak) {
          if (liveRegion && textToSpeechStrategy === ScreenReader) {
            liveRegion.textContent = utteranceToSpeak;
          } else {
            speak(utteranceToSpeak);
          }
        }

        setPrevActiveElement(document.activeElement);
      }, throttle + 1);
    },
    [pathname, prevActiveElement, prevUtteranceList, speak, t, textToSpeechStrategy, ttsEnabled],
  );

  useEffect(() => {
    // trigger synthetic keyup event to handle speech when landing on a new screen
    handleKeyup(new KeyboardEvent('keyup'));
  }, [handleKeyup, pathname]);

  useController({
    [Buttons.down]: {
      event: handleKeyup,
    },
    // handle speech when pressing a button on a page
    [Buttons.enter]: {
      event: handleKeyup,
    },
    [Buttons.left]: {
      event: handleKeyup,
    },
    [Buttons.right]: {
      event: handleKeyup,
    },
    [Buttons.up]: {
      event: handleKeyup,
    },
  });
};
