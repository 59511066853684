/* eslint-disable sort-keys */
import { getAdobeIdentifier } from 'services/adobe';
import { Config, OmnitureConfig } from 'store/config';

import { NO_AUTH, PLAYSTATION_5 } from './constants';
import { actionEventIds, init as initAnalyticsTracking } from './tracking';
import { ADBMobile as ADBMobileType, BaseParams, UserParams } from './types';

declare global {
  interface Window {
    ADBMobile: ADBMobileType;
    ADBMobileConfig: OmnitureConfig;
  }
}

// ADBMobile SDK does not flatten objects itself
const flattenObject = (obj: any, prefix = '') =>
  Object.keys(obj).reduce((acc: any, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});

/**
 * Wrapper around ADBMobile SDK
 * {@link https://adobe-marketing-cloud.github.io/media-sdks/reference/chromecast/ADBMobile.analytics.html | Documentation of underlying interface}
 */
export default class AdobeTracker {
  static ADBMobile: any;

  static isInitialized: boolean;

  static ecid: string;

  static isLoaded = false;

  static oktaId: string;

  static userParams: UserParams = {} as UserParams;

  public static async init(config: Config) {
    if (!AdobeTracker.isInitialized) {
      window.ADBMobileConfig = config.analytics.omniture;
      const { ADBMobile } = await import('analytics/adobe/lib/adbmobile-chromecast.min');
      window.ADBMobile = ADBMobile;

      initAnalyticsTracking(config.analytics);

      AdobeTracker.ecid = await getAdobeIdentifier(config);
      AdobeTracker.isInitialized = true;

      AdobeTracker.trackAction(actionEventIds().APP_LAUNCHED);
    } else {
      console.warn('analytics already initialized, make sure you only call this once');
    }
  }

  /**
   * For tracking page view events
   * @param event the pageview id
   * @param args metadata for the pageview
   */
  public static async trackPageView(event: string, args?: any) {
    const params: any = flattenObject({
      ...this.baseParams(),
      ...(args ?? {}),
    });

    if (window.ADBMobileConfig.debug) {
      // eslint-disable-next-line no-console
      console.debug(`tracking page view ${event}`, params);
    }

    window.ADBMobile.analytics.trackState(event, params);
  }

  /**
   * For tracking actions
   * @param action action id
   * @param args metadata for the action
   */
  public static async trackAction(action: string, args?: any) {
    const extendedAction = !!args && 'action' in args ? args.action : {};
    const params = flattenObject({
      ...this.baseParams(),
      ...(args ?? {}),
      action: {
        a: action,
        ...extendedAction,
      },
    });

    if (window.ADBMobileConfig.debug) {
      // eslint-disable-next-line no-console
      console.debug(`tracking action ${action}`, params);
    }

    window.ADBMobile.analytics.trackAction(action, params);
  }

  private static baseParams(): BaseParams {
    return {
      app: {
        platform: PLAYSTATION_5,
        version: __VERSION__,
      },
      ecid: AdobeTracker.ecid,
      id: {
        okta: AdobeTracker.oktaId,
      },
      user: AdobeTracker.userParams,
    };
  }

  static updateUserParams(state: UserParams) {
    AdobeTracker.userParams = state;
    AdobeTracker.oktaId = state.okta ?? NO_AUTH;
  }
}
