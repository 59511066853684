// GREYSCALE
export const transparent = 'transparent';
export const white = '#FFFFFF';
export const white2 = '#FAFAFA';
export const grey1 = '#F3F3F3';
export const grey2 = '#DDDDDD';
export const grey3 = '#BABABA';
export const grey4 = '#AAAAAA';
export const grey5 = '#9B9B9B';
export const grey6 = '#979797';
export const grey7 = '#888888';
export const grey8 = '#666666';
export const grey9 = '#444444';
export const grey10 = '#222222';
export const grey11 = '#272727';
export const grey12 = '#1E1E1E';
export const black = '#000000';

// LINKS + NAVIGATION
export const cobaltBlue = '#0048AB';
export const feedSelectBlue = '#051e42';
export const linkBlue = '#057AFF';
export const starsBlue = '#041E42';

// SECONDARY COLORS
export const errorRed = '#EC1010';
export const liveRed = '#CC0025';
export const liveYellow = '#FFA801';
export const mlbTvOrange = '#FD7E17';
export const scrubberBlue = '#0462CC';
export const stripesRed = '#BC0022';
export const ticketGreen = '#00Af61';
