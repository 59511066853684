import { Button } from 'components/common';
import styled from 'styled-components';

export const CondensedAndRecapContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CondensedAndRecapButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  width: 48.3rem;
`;
