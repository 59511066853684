import api, { GET } from 'services/api';

import { formatPlayByPlayData } from './normalizers';
import { PlayByPlay, PlayByPlayResponseData } from './types';

export const fetchPlayByPlayData = async (gamePk: string): Promise<PlayByPlay> => {
  const response = await api<PlayByPlayResponseData>(GET, {
    endpoint: 'playByPlay',
    queryParams: {
      gamePk,
    },
    service: 'statsapi',
  });

  return formatPlayByPlayData(response.data);
};
