import SignalTowerSvg from 'assets/feedSelect/signalTower.svg?react';
import styled from 'styled-components';

export const NoFeedsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[16]};
`;

export const NoFeedsTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize['3xl']};
  margin-top: ${({ theme }) => theme.spacing[12]};
`;

export const NoFeedsText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const NoFeedsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[8]};
  text-align: center;
`;

export const SignalTower = styled(SignalTowerSvg)`
  height: 14.1rem;
  width: 14.1rem;
`;
