import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { BaseModalProps } from 'components/modals/BaseModal';
import { ReactNode, useEffect } from 'react';

import { Description, Modal, Title } from './ErrorModal.styles';

export type BaseErrorModalProps = Pick<BaseModalProps, 'isOpen'>;

export interface ErrorModalProps extends BaseErrorModalProps {
  button?: ReactNode;
  primaryText?: string;
  secondaryText?: string;
  testId: string;
  title?: string;
}

export function ErrorModal({
  button,
  isOpen,
  primaryText,
  secondaryText,
  testId,
  title,
}: ErrorModalProps) {
  useEffect(() => {
    if (isOpen) {
      datadogRum.startView({
        name: 'ErrorView',
      });
      datadogLogs.logger.info('ErrorView', {
        title,
      });
    }
  }, [isOpen, primaryText, secondaryText, title]);

  return (
    <Modal isOpen={isOpen} testId={testId} variant="fullScreen" withLogo>
      {title && <Title>{title}</Title>}
      {primaryText && <Description>{primaryText}</Description>}
      {secondaryText && <Description>{secondaryText}</Description>}
      {button}
    </Modal>
  );
}
