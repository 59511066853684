import styled from 'styled-components';

export const TeamAbbrv = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const TeamDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 33%;
`;

export const TeamRecord = styled.span``;
