import { FeedSelect } from 'components/FeedSelect';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { EpgAndStatsGame } from 'store/epg';

type FeedSelectGame = EpgAndStatsGame | null;

export type IFeedSelectGameContext = {
  feedSelectGame: FeedSelectGame;
  from: string | null;
  setFeedSelectGame: Dispatch<SetStateAction<FeedSelectGame>>;
  setFrom: Dispatch<SetStateAction<string | null>>;
};

export const FeedSelectGameContext = createContext<IFeedSelectGameContext>(
  {} as IFeedSelectGameContext,
);

export interface FeedSelectGameProviderProps {
  children: ReactNode;
}

export function FeedSelectGameProvider({ children }: FeedSelectGameProviderProps) {
  const [feedSelectGame, setFeedSelectGame] = useState<FeedSelectGame>(null);
  const [from, setFrom] = useState<string | null>(null);

  const value = useMemo(
    () => ({ feedSelectGame, from, setFeedSelectGame, setFrom }),
    [feedSelectGame, from],
  );

  return (
    <FeedSelectGameContext.Provider value={value}>
      <FeedSelect />
      {children}
    </FeedSelectGameContext.Provider>
  );
}

export function useFeedSelectGameContext() {
  return useContext(FeedSelectGameContext);
}
