import { createSelector } from '@reduxjs/toolkit';
import { fetchEpgByDate } from 'services/epg';
import { fetchFeaturedItemData, ForgeVideoData } from 'services/forge';
import { fetchPlayerDataByDate } from 'services/statsApi/schedule';
import { RootState } from 'store';
import { apiSlice } from 'store/apiSlice';
import { retryWithBackoff } from 'utils/api';
import { startOfYesterday, tenAmEasternInfo, yearMonthDay } from 'utils/date';
import { getGameMatchup, getGameTeamIds } from 'utils/gameData';

import { EpgAndStatsGame, HomePageData } from './types';
import { combineStatsAndEpgData, generateEpgByDateArgs } from './utils';

export const epgApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    gamesByDate: builder.query<EpgAndStatsGame[], string>({
      keepUnusedDataFor: 30,
      providesTags: ['EPG'],
      queryFn: async (date, { getState }) => {
        const fetchData = async () => {
          const state = getState() as RootState;
          const epgArgs = generateEpgByDateArgs(state);

          const [{ results }, playerData] = await Promise.all([
            fetchEpgByDate({ ...epgArgs, date }),
            fetchPlayerDataByDate(date),
          ]);

          return combineStatsAndEpgData(results, playerData);
        };

        try {
          const data = await retryWithBackoff<EpgAndStatsGame[]>(fetchData, 3, 600);
          return { data };
        } catch (error) {
          return {
            error: `We're having some trouble fetching the games right now. Please try again later.`,
          };
        }
      },
    }),
    homePage: builder.query<HomePageData, void>({
      providesTags: ['EPG'],
      queryFn: async (_, { getState }) => {
        try {
          const state = getState() as RootState;
          const epgArgs = generateEpgByDateArgs(state);

          const { isAfterTenAm } = tenAmEasternInfo();
          const date = yearMonthDay(isAfterTenAm ? new Date() : startOfYesterday());

          const [{ featured, hero, results }, playerData] = await Promise.all([
            fetchEpgByDate(epgArgs),
            fetchPlayerDataByDate(date),
          ]);

          const games = combineStatsAndEpgData(results, playerData);

          let heroData = null;
          if (hero?.live && hero?.contentUrl) {
            try {
              heroData = await fetchFeaturedItemData(hero.contentUrl);
            } catch (e) {
              console.error('Hero Item Data Error', e);
            }
          }

          let featuredData: ForgeVideoData[] = [];
          try {
            featuredData = await Promise.all(
              featured
                .filter((featuredItem) => !featuredItem.hero)
                .map((featuredItem) => fetchFeaturedItemData(featuredItem.contentUrl)),
            );
          } catch (e) {
            console.error('Featured Item Data Error', e);
          }

          const data = {
            featured: featuredData,
            games,
            hero: { ...hero, data: heroData },
          };

          return { data };
        } catch (error) {
          return {
            error:
              error?.message ||
              `We're having some trouble fetching the home page data right now. Please try again later.`,
          };
        }
      },
    }),
  }),
});

export const { useGamesByDateQuery, useHomePageQuery } = epgApiSlice;

// Selectors
export const selectFeaturedGame = (data: HomePageData | undefined) =>
  data?.games?.length ? data?.games[0] : null;

export const selectGamesCarouselContent = (data: HomePageData | undefined) =>
  !data?.hero?.live || !data?.hero?.data?.mediaPlaybackId ? data?.games.slice(1) : data.games;

export const selectGame = createSelector(
  (data: EpgAndStatsGame[] | undefined) => data,
  (_: EpgAndStatsGame[] | undefined, gamePk: string | null) => gamePk,
  (data: EpgAndStatsGame[] | undefined, gamePk: string | null) =>
    data?.find((game) => game.gamePk === gamePk),
);

export const selectGameMatchup = createSelector(
  [selectGame],
  (targetGame: EpgAndStatsGame | undefined) => (targetGame ? getGameMatchup(targetGame) : ''),
);

export const selectGameTeamIds = createSelector(
  [selectGame],
  (targetGame: EpgAndStatsGame | undefined) =>
    targetGame ? getGameTeamIds(targetGame) : { awayTeamId: 0, homeTeamId: 0 },
);
