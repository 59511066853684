import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface NetworkConnectionState {
  connected: boolean;
}

export const initialState: NetworkConnectionState = {
  connected: true,
};

export const networkConnectionSlice = createSlice({
  initialState,
  name: 'networkConnection',
  reducers: {
    setNetworkConnection(state, action: PayloadAction<boolean>) {
      state.connected = action.payload;
    },
  },
});

// Actions
export const { setNetworkConnection } = networkConnectionSlice.actions;

// Selectors
export const selectIsConnected = (state: RootState) => !!state.networkConnection.connected;

export default networkConnectionSlice.reducer;
