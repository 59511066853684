import { feedSelectIds } from 'constants/testIds';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildForgeVideoToObject } from 'utils/forgeVideo';

import { CondensedAndRecapButton, CondensedAndRecapContainer } from './CondensedAndRecap.styles';

const { CONDENSED_BUTTON, RECAP_BUTTON } = feedSelectIds;

interface CondensedAndRecapProps {
  condensedSlug: string;
  gamePk: string;
  recapSlug: string;
}

export function CondensedAndRecap({ condensedSlug, gamePk, recapSlug }: CondensedAndRecapProps) {
  const navigate = useNavigate();
  const { date } = useDateFromURL();
  const { pathname } = useLocation();

  const onCondensedPress = useCallback(() => {
    if (condensedSlug) {
      const to = buildForgeVideoToObject(condensedSlug, date);
      navigate(to, { state: { from: pathname, gamePk } });
    }
  }, [condensedSlug, date, gamePk, navigate, pathname]);

  const onRecapPress = useCallback(() => {
    if (recapSlug) {
      const to = buildForgeVideoToObject(recapSlug, date);
      navigate(to, { state: { from: pathname, gamePk } });
    }
  }, [date, gamePk, navigate, pathname, recapSlug]);

  return (
    <CondensedAndRecapContainer>
      {recapSlug && (
        <CondensedAndRecapButton
          data-testid={RECAP_BUTTON}
          focusOptions={{ focusKey: RECAP_BUTTON }}
          scale={false}
          onPress={onRecapPress}>
          Recap
        </CondensedAndRecapButton>
      )}
      {condensedSlug && (
        <CondensedAndRecapButton
          data-testid={CONDENSED_BUTTON}
          scale={false}
          onPress={onCondensedPress}>
          Condensed Game
        </CondensedAndRecapButton>
      )}
    </CondensedAndRecapContainer>
  );
}
