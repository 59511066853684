import { AudioTrack, Content } from '@mlbtv-clients/services';
import { ES_FEED, TV_TRACK_TYPE } from 'constants/feeds';

export const formatAudioFeedText = ({ language, name, trackType }: AudioTrack) => {
  if (language === ES_FEED) return `${ES_FEED.toUpperCase()} - ${name}`;

  if (trackType === TV_TRACK_TYPE) return `${TV_TRACK_TYPE} - ${name}`;

  return name;
};

interface BuildAdobeGameMenuOptionClickParamsProps {
  assetName: string;
  feed: Content | undefined;
  feedName: string;
  fguid: string;
  gameMatchup: string;
  text: string;
}

export const buildAdobeGameMenuOptionClickParams = ({
  assetName,
  feed,
  feedName,
  fguid,
  gameMatchup,
  text,
}: BuildAdobeGameMenuOptionClickParamsProps) => ({
  element: { text },
  video: {
    audioOverlay: { feedName },
    contentId: feed?.contentId ?? '',
    contentType: feed?.contentType ?? '',
    feedName,
    fguid,
    matchup: gameMatchup,
    title: assetName,
  },
});
