import api, { GET } from 'services/api';

export const fetchEntitlementToken = (accessToken: string, deviceId: string | undefined) => {
  if (!deviceId) return Promise.reject(new Error('Cannot fetch entitlement without a deviceId'));

  return api<string>(
    GET,
    {
      endpoint: 'token',
      queryParams: { deviceId },
      service: 'entitlement',
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
