import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { gameTileIds } from 'constants/testIds';
import { useEffect } from 'react';
import { selectStrings } from 'store/config';
import { EpgVideoFeed } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { isAppleTvGame } from 'utils/gameData';

import {
  BlackoutDescription,
  BlackoutHeader,
  BlackoutMessageContainer,
  BlackoutTitle,
} from './BlackoutMessage.styles';
import { getBlackoutInfo } from './utils';

const { PARTNER_ICON } = gameTileIds;

interface BlackoutMessageProps {
  videoFeeds: EpgVideoFeed[];
  videoStatusCodes: number[];
}

export function BlackoutMessage({ videoFeeds, videoStatusCodes }: BlackoutMessageProps) {
  const { feedSelectModal } = useAppSelector(selectStrings);

  const { focusSelf, ref } = useFocusable();

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  const { Icon, message, title } = getBlackoutInfo({
    messages: feedSelectModal,
    videoFeeds,
    videoStatusCodes,
  });
  const isPartnerMessage = !!Icon;
  const isAppleTv = isAppleTvGame(videoFeeds);

  return (
    <BlackoutMessageContainer ref={ref} $isPartnerMessage={isPartnerMessage}>
      <BlackoutHeader>
        <BlackoutTitle $isAppleTv={isAppleTv}>{title}</BlackoutTitle>
        {isPartnerMessage && <Icon data-testid={PARTNER_ICON} />}
      </BlackoutHeader>
      <BlackoutDescription>{message}</BlackoutDescription>
    </BlackoutMessageContainer>
  );
}
