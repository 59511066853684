import {
  FocusableComponentLayout,
  FocusDetails,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation';
import cx from 'classnames';
import { useSmoothScrollerContext } from 'context/SmoothScroller';
import { AriaRole, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { getNodeCenterYPosition } from 'utils/focus';

import { BaseButton, PrimaryButton } from './Button.styles';

// base: will typically be used when you composing a button/link that will NOT look like a conventional button
// primary: acts as a basic button component that is commonly used throughout the app
const buttonVariants = {
  base: BaseButton,
  primary: PrimaryButton,
} as const;

export type ButtonVariant = keyof typeof buttonVariants;

export type FocusOptions = Partial<UseFocusableConfig> & {
  overrideDefaultFocusBehavior?: boolean;
};

export interface ButtonProps {
  children?: ReactNode | ((focused: boolean) => JSX.Element);
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  focusOptions?: FocusOptions;
  onPress?: () => void;
  role?: AriaRole;
  scale?: boolean;
  scrollOptions?: Partial<ScrollOptions>;
  selected?: boolean;
  to?: LinkProps['to'];
  variant?: ButtonVariant;
}

export function Button({
  children,
  className,
  disabled = false,
  focusOptions = { overrideDefaultFocusBehavior: false },
  onPress,
  scale = true,
  scrollOptions,
  selected = false,
  variant = 'primary',
  ...props
}: ButtonProps) {
  const setY = useSmoothScrollerContext();

  const onFocus = (layout: FocusableComponentLayout, focusProps: object, details: FocusDetails) => {
    if (focusOptions.onFocus) {
      focusOptions.onFocus(layout, focusProps, details);
    }

    if (!focusOptions.overrideDefaultFocusBehavior) {
      const position = getNodeCenterYPosition(layout);
      if (position !== null && setY) setY(position);
    }
  };

  const { focusSelf, focused, ref } = useFocusable({
    ...focusOptions,
    ...(disabled && { focusable: false }),
    onEnterRelease: onPress,
    onFocus,
  });

  // Used to help QA engineers focus elements directly in tests
  const handleFocusSelf = () => focusSelf();

  const Variant = buttonVariants[variant];

  return (
    <Variant
      ref={ref}
      className={cx(className, { disabled, focused, scale, selected })}
      disabled={disabled}
      type="button"
      onFocus={handleFocusSelf}
      {...props}>
      {typeof children === 'function' ? children(focused) : children}
    </Variant>
  );
}
