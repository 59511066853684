import api, { GET } from 'services/api';

import { formatMatchupData } from './normalizers';
import { MatchupData, MatchupResponse } from './types';

export const fetchMatchup = async (
  gamePk: string | null,
  timecode: string,
): Promise<MatchupData> => {
  const response = await api<MatchupResponse>(GET, {
    endpoint: 'matchup',
    queryParams: {
      gamePk: gamePk ?? '',
      timecode,
    },
    service: 'statsapi',
  });

  return formatMatchupData(response.data);
};
