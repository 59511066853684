import styled from 'styled-components';

interface PlayerHeadshotContainerProps {
  $backgroundColor: string;
  $isBoxScore: boolean;
  $isLoading: boolean;
}

export const PlayerHeadshotContainer = styled.img<PlayerHeadshotContainerProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  clip-path: ${({ $isLoading }) => $isLoading && 'circle(50%)'};
  filter: ${({ $isLoading }) => $isLoading && 'blur(.9rem)'};
  height: ${({ $isBoxScore }) => ($isBoxScore ? '12.9rem' : '3.6rem')};
  width: ${({ $isBoxScore }) => ($isBoxScore ? '12.9rem' : '3.6rem')};
`;
