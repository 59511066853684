import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { PNG_TYPE, TYPE, WIDTH, WORDMARK_WIDTH } from 'constants/cloudinary';
import { RequestStatus } from 'services/api';
import { fetchBranding } from 'services/branding';
import { RootState } from 'store';

import { RASTER_DARK_ACTIVE, RASTER_LIGHT_ACTIVE } from './constants';
import { branding } from './default';
import { Branding, TeamBrandingInfo, TeamIdtoColorMap, WelcomeImages } from './types';
import { constructCapLogoMap, constructColorMap } from './utils';

export interface BrandingState {
  data: Branding;
  error: string;
  status: RequestStatus;
}

export const initialState = {
  data: branding,
  error: '',
  status: RequestStatus.IDLE,
};

export const fetchBrandingFlow = createAsyncThunk<Branding, undefined, { rejectValue: string }>(
  'branding/fetchBranding',
  async (_, { rejectWithValue }) => {
    try {
      const brandingResponse = await fetchBranding();
      return brandingResponse;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  },
);

export const brandingSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchBrandingFlow.pending, (state) => {
      state.status = RequestStatus.LOADING;
    });

    builder.addCase(fetchBrandingFlow.fulfilled, (state, action) => {
      state.status = RequestStatus.SUCCESS;
      state.data = action.payload;
      state.error = '';
    });

    builder.addCase(fetchBrandingFlow.rejected, (state, action) => {
      state.status = RequestStatus.ERROR;
      state.error = action.payload ?? '';
    });
  },
  initialState,
  name: 'branding',
  reducers: {},
});

// Selectors
export const selectTeams = (state: RootState) => state.branding.data.teams;

const selectAllStarTeams = (state: RootState) => state.branding.data.allstarTeams;

const selectAllTeams = createSelector(
  [selectTeams, selectAllStarTeams],
  (teams = [], allstarTeams = []) => [...teams, ...allstarTeams],
);

export const selectDarkCapLogoUrls = createSelector([selectAllTeams], (teams) =>
  constructCapLogoMap(RASTER_DARK_ACTIVE, teams),
);

export const selectDarkWordmarkUrls = createSelector([selectTeams], (teams) =>
  teams
    .filter((team: TeamBrandingInfo) => !!team.wordmarks.rasterDark)
    .reduce(
      (acc: TeamIdtoColorMap, team: TeamBrandingInfo) => ({
        ...acc,
        [team.teamID]: (team.wordmarks.rasterDark ?? '')
          .replace(TYPE, PNG_TYPE)
          .replace(WIDTH, WORDMARK_WIDTH),
      }),
      {},
    ),
);

export const selectFeaturedGameHomeTeamBackground = createSelector(
  [(_, featuredGame) => featuredGame, selectTeams],
  (featuredGame, teams): string => {
    const homeTeamId = featuredGame?.gameData.home.teamId;
    const homeTeamBranding: TeamBrandingInfo | undefined = teams.find(
      (team) => team.teamID === homeTeamId,
    );
    return homeTeamBranding?.mlbtv?.welcome.create.templateUrl ?? '';
  },
);

export const selectGameTileBorderTeamColors = createSelector([selectTeams], (teams) =>
  constructColorMap(teams, 'primaryDark'),
);

export const selectGameTileTeamColors = createSelector(
  [selectTeams, selectAllStarTeams],
  (teams, allStarTeams) => ({
    ...constructColorMap(teams, 'mlbtvGameTile'),
    ...constructColorMap(allStarTeams, 'primaryLight'),
  }),
);

export const selectGetStartedImageUrl = (state: RootState): string =>
  state.branding.data.mlb.mlbtv?.gettingStarted?.imageUrl ?? '';

export const selectGetStartedTemplateUrl = (state: RootState): string =>
  state.branding.data.mlb.mlbtv?.gettingStarted?.templateUrl ?? '';

export const selectLightCapLogoUrls = createSelector([selectAllTeams], (teams) =>
  constructCapLogoMap(RASTER_LIGHT_ACTIVE, teams),
);

export const selectTeamIdFromProps = (_state: RootState, teamId: number) => teamId;

export const selectTeamIdsFromProps = (_state: RootState, teamIds: number[]) => teamIds;

export const selectState = (state: RootState) => state;

export const selectTeamNameById = createSelector(
  [selectTeams, selectTeamIdFromProps],
  (teams = [], teamId) => teams.find(({ teamID }) => teamID === teamId)?.clubFullName ?? '',
);

export const selectTeamNamesById = createSelector(
  [selectState, selectTeamIdsFromProps],
  (state, teamIds) =>
    teamIds.map((teamId) => selectTeamNameById(state, teamId)).filter((teamName) => !!teamName),
);

export const selectWelcomeImages = (state: RootState): WelcomeImages =>
  state.branding.data.mlb.mlbtv?.welcome;

export default brandingSlice.reducer;
