import styled from 'styled-components';
import { Z_INDICES } from 'styles/zIndexHierarchy';

const { TITLE_SAFE_OVERLAY } = Z_INDICES;

export const TitleSafeOverlay = styled.div`
  border: solid red;
  height: 90%;
  left: 5%;
  position: absolute;
  top: 5%;
  width: 90%;
  z-index: ${TITLE_SAFE_OVERLAY};
`;
