import Conviva from '@convivainc/conviva-js-coresdk';
import {
  getConvivaGatewayUrl,
  getNetworkConnectionType,
} from 'analytics/conviva/hooks/useConviva/utils';
import { useState } from 'react';
import { selectConfigEnv } from 'store/app';
import { selectConvivaConfig } from 'store/config';
import { selectIsMLBExperience } from 'store/experience';
import { useAppSelector } from 'store/hooks';
import { isNonProdBuild } from 'utils/env';
import { getDeviceMetadata } from 'utils/platform';

const {
  Constants: {
    GATEWAY_URL,
    Network: { CONNECTION_TYPE },
  },
} = Conviva;

export const useInitializeConviva = async () => {
  const configEnv = useAppSelector(selectConfigEnv);
  const isMLBExperience = useAppSelector(selectIsMLBExperience);
  const { enabled } = useAppSelector(selectConvivaConfig);
  const {
    dev: { customerKey: devCustomerKey, gatewayUrl: devGatewayUrl },
    deviceMetadata,
    prod: { customerKey: prodCustomerKey, gatewayUrl: prodGatewayUrl },
  } = useAppSelector(selectConvivaConfig);
  const [isConvivaInitialized, setIsConvivaInitialized] = useState<boolean>(false);

  // Use this `prodTouchstoneGateway` ONLY when you are developing against
  // Experience.MLB and choosing `prod` as your environment AND you
  // want to be able to view those sessions in Touchstone
  // const prodTouchstoneGateway = 'https://mlb-baseball.testonly.conviva.com';

  // For the time being, when in a non-prod build and using PROD Experience.MLB
  // services, report playback sessions to Prod Conviva.
  // TODO: remove `shouldReportToProdAnyway` after npd services are created for Experience.MLB

  if (enabled && !isConvivaInitialized) {
    setIsConvivaInitialized(true);
    const shouldReportToProdAnyway = configEnv === 'prod' && isMLBExperience;
    const customerKey =
      isNonProdBuild() && !shouldReportToProdAnyway ? devCustomerKey : prodCustomerKey;
    const gatewayUrl =
      isNonProdBuild() && !shouldReportToProdAnyway ? devGatewayUrl : prodGatewayUrl;

    const convivaConfig = { [GATEWAY_URL]: getConvivaGatewayUrl(gatewayUrl, customerKey) };
    const networkConnectionType = await getNetworkConnectionType();
    const metadata = await getDeviceMetadata(deviceMetadata);
    Conviva.Analytics.init(customerKey, null, convivaConfig);
    Conviva.Analytics.setDeviceMetadata(metadata);
    Conviva.Analytics.reportDeviceMetric(CONNECTION_TYPE, networkConnectionType);
  }
};
