import styled from 'styled-components';

interface TeamFeedContainerProps {
  $entitled: boolean;
}

export const TeamFeedContainer = styled.div<TeamFeedContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: ${({ $entitled }) => ($entitled ? '100%' : '85%')};
`;
