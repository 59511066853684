import LacesIcon from 'assets/spinner/laces.svg?react';
import { m as motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';
import { spinnerColors } from 'styles/colors';
import { Z_INDICES } from 'styles/zIndexHierarchy';

import { SpinnerBackground, SpinnerVariant } from './Spinner';

const { ball, fullScreenBackground } = spinnerColors;

const { LOADER } = Z_INDICES;

export const Spin = keyframes`
  from {
    transform: rotate(45deg) translateX(-15%)
  }
  to {
    transform: rotate(45deg) translateX(18.35%);
  }
`;

interface BallProps {
  $size: number;
}

interface ContainerProps {
  $background: SpinnerBackground;
  $variant: SpinnerVariant;
}

interface LacesContainerProps {
  $isAnimated: boolean;
}

export const Ball = styled.div<BallProps>`
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // fixes overflow hidden on round corners in safari bug
  align-items: center;
  border: 0.3rem solid ${ball};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  height: ${({ $size }) => $size}rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${({ $size }) => $size}rem;
`;

export const SpinnerContainer = styled(motion.div)<ContainerProps>`
  ${({ $background, $variant }) => {
    if ($variant === 'block') {
      return css`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      `;
    }
    if ($variant === 'fullScreen') {
      return css`
        align-items: center;
        background: ${$background === 'solid' && fullScreenBackground};
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: ${LOADER};
      `;
    }
    return undefined;
  }}
`;

export const LacesContainer = styled.div<LacesContainerProps>`
  animation: ${Spin} 1.2s linear ${({ $isAnimated }) => ($isAnimated ? 'infinite' : '0')};
  animation-play-state: ${({ $isAnimated }) => ($isAnimated ? 'running' : 'paused')}
  display: flex;
  pointer-events: none;
  position: absolute;
  transform-origin: center;
`;

export const Laces = styled(LacesIcon)<BallProps>`
  height: ${({ $size }) => $size * 2}rem;
  margin-right: ${({ theme }) => theme.spacing[4]};
  max-width: ${({ $size }) => $size * 2}rem;
  width: ${({ $size }) => $size * 2}rem;
`;
