import { FormattedPlayerData } from 'services/statsApi/schedule';
import { RootState } from 'store';
import { selectExperience } from 'store/experience';
import { selectMlbAuth } from 'store/mlbAuth';
import { selectProfile } from 'store/profile';
import { getCacheBustStorage, removeBustCacheStorage } from 'utils/storage';

import { EpgAndStatsGame, EpgApiGame } from './types';

export const generateEpgByDateArgs = (state: RootState) => {
  const { accessToken, oktaId } = selectMlbAuth(state);
  const { favoriteTeam, followingTeams } = selectProfile(state);
  const bustCacheOnNextFetch = getCacheBustStorage();
  const experience = selectExperience(state);

  const headers: Record<string, string> = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
        'X-OKTA-ID': oktaId,
      }
    : {};

  let baseQuery = `exp=${experience}`;

  const followingTeamsQuery = followingTeams
    ? followingTeams.reduce((acc: string, teamId: number) => `${acc}&followedTeam=${teamId}`, '')
    : '';

  const favFollowingTeamQuery = favoriteTeam
    ? `&favoriteTeam=${favoriteTeam}${followingTeamsQuery}`
    : '';

  baseQuery += favFollowingTeamQuery;

  const cacheBustQuery = bustCacheOnNextFetch ? 'cachebust=true' : '';

  removeBustCacheStorage();

  return {
    baseQuery,
    cacheBustQuery,
    headers,
  };
};

export const combineStatsAndEpgData = (
  games: EpgApiGame[],
  statsPlayerData: FormattedPlayerData,
): EpgAndStatsGame[] => {
  const combinedData: EpgAndStatsGame[] = games.map((game) => {
    const { gameData, gamePk } = game;
    const statsGameData = statsPlayerData[gamePk];

    const combinedGameData = {
      ...gameData,
      currentBatterAvg: statsGameData?.batter?.avg,
      currentBatterTeamId: statsGameData?.batter?.teamId,
      currentPitcherTeamId: statsGameData?.pitcher?.teamId,
    };

    return { ...game, gameData: combinedGameData };
  });

  return combinedData;
};
