export const FULLSCREEN = 'Fullscreen';
export const NA = 'NA';
export const NO_AUTH = 'NoAuth';
export const NO_FAVORITE = 'NoFavorite';
export const NO_FOLLOW = 'NoFollow';
export const PLAYSTATION_5 = 'PlayStation 5';

export enum ClickStatus {
  Close = 'Close',
  Open = 'Open',
}
