import { Button } from 'components/common';
import styled from 'styled-components';
import { gameMenuColors } from 'styles/colors';

const { broadcastOptionsSelectedFeedColor, gameMenuTeamLogoBorderColor } = gameMenuColors;

interface BroadcastOptionsFeedButtonProps {
  $isSelectedFeed: boolean;
}

export const BroadcastOptionsFeedButton = styled(Button)<BroadcastOptionsFeedButtonProps>`
  background-color: ${({ $isSelectedFeed }) =>
    $isSelectedFeed && broadcastOptionsSelectedFeedColor};
  border-color: ${({ $isSelectedFeed }) => $isSelectedFeed && broadcastOptionsSelectedFeedColor};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  height: 5.4rem;
  justify-content: flex-start;
  margin-right: ${({ theme }) => theme.spacing['2.5']};
  max-width: 40.2rem;
  min-width: 28.5rem;
  padding: ${({ theme }) => `0 ${theme.spacing['4']}`};

  &.focused {
    &.scale {
      transform: scale(1.05);
    }
  }
`;

export const BroadcastOptionsFeedButtonContent = styled.div`
  display: flex;
  width: 100%;
`;

export const BroadcastOptionsLogoAndText = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

interface BroadcastOptionsFeedTeamLogoProps {
  $backgroundColor: string;
}

export const BroadcastOptionsFeedTeamLogo = styled.div<BroadcastOptionsFeedTeamLogoProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 0.15rem solid ${gameMenuTeamLogoBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  padding: ${({ theme }) => theme.spacing[0.5]};
`;

export const BroadcastOptionsFeedText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  margin: ${({ theme }) => `0 ${theme.spacing[3]}`};
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
`;
