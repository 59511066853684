import { AxiosResponse } from 'axios';
import { Branding } from 'store/branding';

export const validateBrandingData = (brandingResponse: AxiosResponse<Branding>): Branding => {
  const { data: brandingData } = brandingResponse;
  const requiredKeys = ['teams', 'allstarTeams', 'mlb', 'options'];
  const brandingDataKeys = Object.keys(brandingData);

  const missingKeys = requiredKeys.reduce(
    (acc: string[], key: string) => (brandingDataKeys.includes(key) ? acc : [...acc, key]),
    [],
  );

  if (missingKeys.length > 0) {
    const error = new Error(`Branding data response is missing keys: ${missingKeys.join(', ')}`);
    // TODO: Add logging once APM is implemented
    // Apm.logApplicationError('P122', error);
    throw error;
  }

  return brandingData;
};
