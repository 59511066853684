import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'services/api';
import { fetchExpressLoginData, fetchStandardLoginData } from 'services/mlbAuth';
import { ExpressLoginData, StandardLoginData } from 'services/mlbAuth/types';
import { RootState } from 'store';
import { selectServices } from 'store/config';

export interface MlbAuthState {
  accessToken: string;
  express: {
    expressLoginData: ExpressLoginData | null;
    expressLoginStatus: RequestStatus;
  };
  isAuthenticated: boolean | undefined;
  oktaId: string;
}

export const initialState: MlbAuthState = {
  accessToken: '',
  express: {
    expressLoginData: null,
    expressLoginStatus: RequestStatus.IDLE,
  },
  isAuthenticated: undefined,
  oktaId: '',
};

export const fetchExpressLoginDataFlow = createAsyncThunk<
  ExpressLoginData,
  undefined,
  { state: RootState }
>('mlbAuth/fetchExpressLoginData', async (_, { getState, rejectWithValue }) => {
  const {
    mlbAuth: { meta },
  } = selectServices(getState());
  try {
    const expressLoginData = await fetchExpressLoginData(meta);
    return expressLoginData;
  } catch (error) {
    return rejectWithValue(error?.message);
  }
});

export const fetchStandardLoginDataFlow = createAsyncThunk<
  StandardLoginData,
  string,
  { state: RootState }
>('mlbAuth/fetchStandardLoginData', async (deviceCode, { getState, rejectWithValue }) => {
  const {
    mlbAuth: { meta },
  } = selectServices(getState());

  try {
    const standardLoginData = await fetchStandardLoginData(deviceCode, meta);
    return standardLoginData;
  } catch (error) {
    return rejectWithValue(error?.message);
  }
});

export const signOut = createAction('SIGN_OUT');

export const mlbAuthSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchExpressLoginDataFlow.pending, (state) => {
      state.express.expressLoginStatus = RequestStatus.LOADING;
    });

    builder.addCase(fetchExpressLoginDataFlow.fulfilled, (state, action) => {
      state.express.expressLoginStatus = RequestStatus.SUCCESS;
      state.express.expressLoginData = action.payload;
    });

    builder.addCase(fetchExpressLoginDataFlow.rejected, (state) => {
      state.express.expressLoginStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: 'mlbAuth',
  reducers: {
    clearExpressLoginData(state: MlbAuthState) {
      state.express = initialState.express;
    },
    setMlbAuth(
      state: MlbAuthState,
      action: PayloadAction<{
        accessToken: string;
        isAuthenticated: boolean | undefined;
        oktaId: string;
      }>,
    ) {
      return { ...state, ...action.payload };
    },
  },
});

// Actions
export const { clearExpressLoginData, setMlbAuth } = mlbAuthSlice.actions;

// Selectors
export const selectMlbAuth = (state: RootState) => state.mlbAuth;

export const selectAccessToken = (state: RootState) => state.mlbAuth.accessToken;

export const selectExpressLoginData = (state: RootState) => state.mlbAuth.express.expressLoginData;

export const selectIsAuthenticated = (state: RootState) => state.mlbAuth.isAuthenticated;

export const selectExpressLoginStatus = (state: RootState) =>
  state.mlbAuth.express.expressLoginStatus;

export const selectOktaId = (state: RootState) => state.mlbAuth.oktaId;

export default mlbAuthSlice.reducer;
