import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface DeviceSettingsState {
  ttsEnabled: boolean;
}

export const initialState: DeviceSettingsState = {
  ttsEnabled: false,
};

// Slice
export const deviceSettingsSlice = createSlice({
  initialState,
  name: 'deviceSettings',
  reducers: {
    setTTSEnabled(state: DeviceSettingsState, action: PayloadAction<boolean>) {
      state.ttsEnabled = action.payload;
    },
  },
});

// Selectors
export const selectTTSEnabled = (state: RootState) => state.deviceSettings.ttsEnabled ?? false;

// Actions
export const { setTTSEnabled } = deviceSettingsSlice.actions;

export default deviceSettingsSlice.reducer;
