import { OUT_TITLE_CASE, OUTS_TITLE_CASE } from 'constants/gameState';
import { Play, PlayByPlay } from 'services/statsApi/playByPlay';
import { getTimeInMs } from 'utils/date';

export const getScoreAtTime = (playByPlay: PlayByPlay, absoluteDateWithOffset: string) => {
  const play = playByPlay.plays.findLast(
    ({ endTime }: Play) => getTimeInMs(absoluteDateWithOffset) >= getTimeInMs(endTime),
  );

  return {
    awayTeamRuns: play?.score.away ?? 0,
    homeTeamRuns: play?.score.home ?? 0,
  };
};

export const getOutsAtTime = (playByPlay: PlayByPlay, absoluteDateWithOffset: string) => {
  const playIndex = playByPlay.plays.findLastIndex(
    ({ endTime }: Play) => getTimeInMs(absoluteDateWithOffset) >= getTimeInMs(endTime),
  );

  const currPlay = playByPlay.plays[playIndex];
  const nextPlay = playByPlay.plays[playIndex + 1];

  // if currentOuts === 3 and nextOuts === 1, it should be 0 outs between currentOuts endTime and nextOuts startTime. This issue is due to the StatsAPI response only returning 0 out scenarios if a run is scored in that inning before an out is recorded. So we have to manually insert 0 out scenarios in the case where a run is not scored before the first out
  if (
    currPlay?.outs === 3 &&
    nextPlay?.outs === 1 &&
    getTimeInMs(absoluteDateWithOffset) > getTimeInMs(nextPlay?.startTime)
  )
    return `0 ${OUTS_TITLE_CASE}`;

  const outs = currPlay?.outs ?? 0;

  return `${outs} ${outs === 1 ? OUT_TITLE_CASE : OUTS_TITLE_CASE}`;
};
