import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsConnected, setNetworkConnection } from 'store/networkConnection';

export function useIsConnectedToNetwork() {
  const dispatch = useAppDispatch();
  const isConnectedToNetwork = useAppSelector(selectIsConnected);

  useEffect(() => {
    const onlineListener = () => dispatch(setNetworkConnection(true));
    const offlineListener = () => dispatch(setNetworkConnection(false));

    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, [dispatch]);

  return isConnectedToNetwork;
}
