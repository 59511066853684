import AbcIcon from 'assets/broadcastOptions/abc.svg?react';
import AppleTvIcon from 'assets/broadcastOptions/apple-tv-plus.svg?react';
import AudioFeedPlaceholderIcon from 'assets/broadcastOptions/audio-feed-placeholder.svg?react';
import EspnIcon from 'assets/broadcastOptions/espn.svg?react';
import FoxIcon from 'assets/broadcastOptions/fox.svg?react';
import Fs1Icon from 'assets/broadcastOptions/fs1.svg?react';
import MlbnIcon from 'assets/broadcastOptions/mlb-network.svg?react';
import TbsIcon from 'assets/broadcastOptions/tbs.svg?react';
import VideoFeedPlaceholderIcon from 'assets/broadcastOptions/video-feed-placeholder.svg?react';
import PeacockIcon from 'assets/partner/peacock-small.svg?react';
import YoutubeIcon from 'assets/partner/youtube.svg?react';
import { gameMenuIds } from 'constants/testIds';

import { PeacockContainer } from './BroadcastOptionNetworkFeedLogo.styles';

const {
  ABC_ICON,
  APPLETV_ICON,
  AUDIO_FEED_PLACEHOLDER_ICON,
  ESPN_ICON,
  FOX_ICON,
  FS1_ICON,
  MLBN_ICON,
  PEACOCK_ICON,
  TBS_ICON,
  VIDEO_FEED_PLACEHOLDER_ICON,
  YOUTUBE_ICON,
} = gameMenuIds;

const networks = ['abc', 'apple', 'espn', 'fox', 'fs1', 'mlbn', 'peacock', 'tbs', 'youtube'];

interface BroadcastOptionNetworkFeedLogoProps {
  callLetters: string;
  isAudioFeed: boolean;
}

export function BroadcastOptionNetworkFeedLogo({
  callLetters,
  isAudioFeed,
}: BroadcastOptionNetworkFeedLogoProps) {
  const network = networks.find((n) => callLetters.toLowerCase().trim().includes(n));

  switch (network) {
    case 'abc': {
      return <AbcIcon data-testid={ABC_ICON} />;
    }
    case 'apple': {
      return <AppleTvIcon data-testid={APPLETV_ICON} />;
    }
    case 'espn': {
      return <EspnIcon data-testid={ESPN_ICON} />;
    }
    case 'fox': {
      return <FoxIcon data-testid={FOX_ICON} />;
    }
    case 'fs1': {
      return <Fs1Icon data-testid={FS1_ICON} />;
    }
    case 'mlbn': {
      return <MlbnIcon data-testid={MLBN_ICON} />;
    }
    case 'peacock': {
      return (
        <PeacockContainer>
          <PeacockIcon data-testid={PEACOCK_ICON} />
        </PeacockContainer>
      );
    }
    case 'tbs': {
      return <TbsIcon data-testid={TBS_ICON} />;
    }
    case 'youtube': {
      return <YoutubeIcon data-testid={YOUTUBE_ICON} />;
    }
    default:
      return isAudioFeed ? (
        <AudioFeedPlaceholderIcon data-testid={AUDIO_FEED_PLACEHOLDER_ICON} />
      ) : (
        <VideoFeedPlaceholderIcon data-testid={VIDEO_FEED_PLACEHOLDER_ICON} />
      );
  }
}
