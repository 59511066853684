import { PlayByPlay, PlayByPlayResponseData } from './types';

export const formatPlayByPlayData = (data: PlayByPlayResponseData): PlayByPlay => ({
  plays: data.allPlays.map(({ about, count, result }) => ({
    endTime: about.endTime,
    isScoringPlay: about.isScoringPlay,
    outs: count.outs,
    score: {
      away: result.awayScore,
      home: result.homeScore,
    },
    startTime: about.startTime,
  })),
});
