import * as colors from 'styles/colors/colors';

import { transparent } from './colors';

const {
  black,
  cobaltBlue,
  errorRed,
  grey1,
  grey2,
  grey4,
  grey5,
  grey6,
  grey7,
  grey8,
  grey9,
  grey10,
  grey11,
  grey12,
  liveRed,
  liveYellow,
  scrubberBlue,
  white,
} = colors;

const backgroundImageColors = {
  overlayColor: black,
};

const boxScoreMatchupColors = {
  matchupHeaderColor: grey4,
  matchupPlayerTextColor: white,
};

const calendarDateColors = {
  backgroundColor: 'rgba(95, 95, 95, 0.6)',
  date: grey7,
  focused: {
    backgroundColor: 'rgba(0, 0, 0, .8)',
  },
  games: white,
  todayBorder: white,
};

const carouselColors = {
  defaultTileBackgroundColor: grey10,
  defaultTileBorderColor: white,
  featuredForgeTileBorderColor: white,
  liveIndicator: liveRed,
  loadingContainerBorder: white,
  lockIconBackgroundColor: black,
  tileTextColor: white,
  videoTimeBackgroundColor: 'rgba(2, 19, 41, 0.6)',
};

const createAccountColors = {
  errorInputBorderColor: errorRed,
  errorTextColor: errorRed,
  eyeIconBorderColor: grey7,
  focusedButtonTextColor: grey10,
  footerTextColor: grey1,
  hintTextColor: grey7,
  inputBorderColor: grey7,
  inputFieldBackgroundColor: transparent,
  inputFieldBackgroundFocusColor: 'rgba(255, 255, 255, 0.2)',
  inputTextColor: white,
  loginTextColor: grey1,
};

const datePickerColors = {
  focusedIconColor: grey10,
  unFocusedIconColor: white,
};

const featuredColors = {
  gameTime: scrubberBlue,
  liveIndicator: liveRed,
};

const featuredGameContentColors = {
  blackoutPillBackgroundColor: black,
  blackoutPillBorderColor: white,
  finalPillBackgroundColor: black,
  finalPillBorderColor: 'rgba(255, 255, 255, 0.6)',
  gameStatusColor: 'rgba(255, 255, 255, 0.4)',
  mvpdPillBackgroundColor: grey8,
  mvpdPillTextColor: white,
  partnerLogoBackgroundColor: white,
  probablePitcherTextColor: grey1,
};

const featuredGameInfoColors = {
  basesActiveColor: white,
  textColor: white,
};

const gameMenuColors = {
  broadcastOptionsSelectedFeedColor: scrubberBlue,
  gameMenuButtonBackgroundColorFocused: white,
  gameMenuButtonBackgroundColorSelected: 'rgba(255, 255, 255, 0.3)',
  gameMenuTeamLogoBorderColor: 'rgba(255, 255, 255, 0.2)',
  peacockBackgroundColor: white,
};

const gameTileColors = {
  backgroundColor: grey10,
  basesOutsActiveColor: liveYellow,
  basesOutsDefaultColor: grey8,
  blackoutPillBackgroundColor: grey7,
  borderColor: white,
  defaultPlayerHeadshotBackgroundColor: grey4,
  dropShadowColor: 'rgba(0,0,0,.2)',
  liveBadgeBackgroundColor: liveRed,
  messagePillBackgroundColor: white,
  messagePillTextColor: black,
  mvpdPillBackgroundColor: grey7,
  playerStatLineColor: grey4,
  textColor: white,
};

const headerColors = {
  headerBackgroundColor: 'rgba(0,0,0,.8)',
  headerButtonActiveTextColor: white,
  headerButtonFocusedBackgroundColor: white,
  headerButtonFocusedTextColor: black,
  headerButtonTextColor: grey6,
};

const linescoreColors = {
  inningFocusedColor: white,
  statsColor: grey6,
};

const loginColors = {
  errorInputBorderColor: errorRed,
  errorTextColor: errorRed,
  expressLoginNumberBackgroundColor: grey11,
  eyeIconBackgroundFocusedColor: white,
  eyeIconBorderColor: white,
  eyeIconFocusedColor: black,
  focusedButtonTextColor: grey10,
  focusedInputTextColor: black,
  footerTextColor: grey1,
  inputBorderColor: white,
  inputFieldBackgroundColor: transparent,
  inputFieldBackgroundFocusColor: white,
  inputTextColor: white,
  loginButtonBackgroundColor: white,
  loginOptionDividerColor: 'rgba(255, 255, 255, 0.7)',
  loginTextColor: grey1,
  placeholderFocusedTextColor: black,
  placeholderTextColor: white,
};

const matchupColors = {
  defaultBackgroundColor: grey5,
  dividerColor: grey5,
  overlayColor: black,
  textColor: white,
};

const modalColors = {
  backdropColor: black,
  backgroundColor: grey11,
};

const paywallColors = {
  cardBackgroundColor: grey12,
  cardBorderColor: white,
  currentBackgroundColor: grey9,
  dropShadowColor: 'rgba(0,0,0,.2)',
  packagePriceColor: white,
  packageTitleColor: white,
  priceUnitTextColor: grey7,
  qrDescriptionColor: grey1,
  unFocusedCardBorderColor: transparent,
};

const primaryButtonColors = {
  backgroundColor: transparent,
  borderColor: grey7,
  color: grey1,
  focused: {
    backgroundColor: grey2,
    borderColor: grey2,
    color: grey10,
  },
};

const scrubberColors = {
  ffrwBox: { ffrwBoxBackgroundColor: white, ffrwBoxScoreTextColor: black },
  liveIndicator: {
    circleLiveColor: liveRed,
    circleNonLiveColor: grey2,
    textLiveColor: white,
    textNonLiveColor: grey2,
  },
  scrubber: {
    playbackMarkerColor: white,
    scrubberColor: grey4,
    scrubberInningColor: white,
    scrubberProgressColor: scrubberBlue,
  },
  videoTime: {
    videoTimeColor: white,
  },
};

const settingsColors = {
  devSettingsEnvSelectedBackground: white,
  devSettingsEnvSelectedColor: grey10,
  settingsArrowFocusedColor: black,
};

const spinnerColors = {
  ball: white,
  fullScreenBackground: black,
};

const quickActionsColors = {
  button: {
    background: transparent,
    color: white,
    focused: {
      background: white,
      color: black,
    },
  },
  ccTitle: cobaltBlue,
};

const teamPickerColors = {
  textColor: white,
  tileBackgroundColor: grey11,
};

const toggleColors = {
  backgroundColor: grey12,
  borderColor: transparent,
  fontColor: white,
  option: {
    focusedBorderColor: white,
    focusedSelectedBackgroundColor: white,
    focusedSelectedFontColor: black,
    focusedUnselectedBackgroundColor: grey8,
    focusedUnselectedFontColor: white,
    unfocusedFontColor: white,
  },
};

const watchButtonColors = {
  backgroundColor: transparent,
  borderColor: white,
  focusedBackgroundColor: white,
  focusedLockIconBackgroundColor: black,
  focusedLockIconColor: white,
  focusedTextColor: black,
  lockIconBackgroundColor: white,
  lockIconColor: black,
  textColor: white,
};

const welcomeColors = {
  loginButtonBorderColor: white,
  onboardingButton: {
    backgroundColor: white,
  },
  textColor: grey1,
};

const videoDebuggerColors = {
  backgroundColor: 'rgba(2, 19, 41, 0.6)',
  textColor: white,
};

export {
  backgroundImageColors,
  boxScoreMatchupColors,
  calendarDateColors,
  carouselColors,
  createAccountColors,
  datePickerColors,
  featuredColors,
  featuredGameContentColors,
  featuredGameInfoColors,
  gameMenuColors,
  gameTileColors,
  headerColors,
  linescoreColors,
  loginColors,
  matchupColors,
  modalColors,
  paywallColors,
  primaryButtonColors,
  quickActionsColors,
  scrubberColors,
  settingsColors,
  spinnerColors,
  teamPickerColors,
  toggleColors,
  videoDebuggerColors,
  watchButtonColors,
  welcomeColors,
};
