import { AxiosResponse } from 'axios';
import api, { GET, POST } from 'services/api';
import { ProfileState } from 'store/profile';

import { formatProfileData } from './normalizers';
import { CreateAccountRequestData, ProfileData, UpdateProfileResponse } from './types';

export const createAccount = async (requestData: CreateAccountRequestData) => {
  const { marketing, password, username } = requestData;
  return api<CreateAccountRequestData>(
    POST,
    {
      endpoint: 'createAccount',
      queryParams: { activate: 'true' },
      service: 'profile',
    },
    {
      body: {
        credentials: { password: { value: password } },
        profile: {
          commercialEmailsOptin: marketing,
          email: username,
          login: username,
        },
      },
    },
  );
};

export const fetchProfileData = async (accessToken: string): Promise<ProfileState> => {
  const response = await api<ProfileData>(
    GET,
    {
      endpoint: 'preferences',
      service: 'profile',
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return formatProfileData(response.data);
};

export const updateProfileData = async (
  accessToken: string,
  profile: Partial<ProfileData>,
): Promise<UpdateProfileResponse> => {
  const response: AxiosResponse<UpdateProfileResponse> = await api(
    'POST',
    {
      endpoint: 'preferences',
      service: 'profile',
    },
    {
      body: profile,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.status !== 200) {
    throw new Error(`Could not update profile with the following data: ${JSON.stringify(profile)}`);
  }

  return response.data;
};
