import { ROUTES } from 'constants/screens';

import { yearMonthDay } from './date';

const { FORGE_VIDEO } = ROUTES;

export const buildForgeVideoToObject = (slug: string, date?: Date) => {
  const dateParam = date ? `&date=${yearMonthDay(date)}` : '';

  return {
    pathname: FORGE_VIDEO,
    search: `?slug=${slug}${dateParam}`,
  };
};
