import { datadogLogs } from '@datadog/browser-logs';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useAdobe } from 'analytics/adobe';
import { Spinner } from 'components/common/Spinner';
import { AppErrorBoundary } from 'components/modals/AppErrorBoundary';
import { NetworkConnectionModal } from 'components/modals/NetworkConnectionModal';
import { appIds } from 'constants/testIds';
import {
  useIsAppBackgrounded,
  useIsConnectedToNetwork,
  useReloadApp,
  useTTSNavigation,
} from 'hooks';
import { useDeeplinkArguments } from 'hooks/player';
import { useDeviceStartup } from 'hooks/useDeviceStartup/useDeviceStartup';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'screens/Router';
import { TitleSafeOverlay } from 'screens/TitleSafeOverlay';
import { selectIsAppInitialized, setIsAppInitialized } from 'store/app';
import { setDatadogUser, setupAppFlow } from 'store/flows';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsAuthenticated } from 'store/mlbAuth';
import { differenceInMs } from 'utils/date';
import { getShowTitleSafeOverlayStorage } from 'utils/storage';
import Conviva from '@convivainc/conviva-js-coresdk';

import { trackCustomEvent } from '@convivainc/conviva-js-appanalytics';
import { useInitializeConviva } from 'analytics/conviva/hooks/useInitializeConviva';
import { AppContainer } from './App.styles';

import 'utils/textToSpeech/i18n';

const showTitleSafeOverlay = getShowTitleSafeOverlayStorage();

export function App() {
  const dispatch = useAppDispatch();
  useAdobe();
  useIsAppBackgrounded();
  const reloadApp = useReloadApp();
  const isConnectedToNetwork = useIsConnectedToNetwork();
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { focusKey, focusSelf, ref } = useFocusable();

  useDeviceStartup();
  useDeeplinkArguments();
  useTTSNavigation();
  useInitializeConviva();

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  useEffect(() => () => Conviva.Analytics.release(), []);

  useEffect(() => {
    (async () => {
      if (!isAppInitialized) {
        await dispatch(setupAppFlow());
        const endTimestamp = new Date();
        const duration = differenceInMs(endTimestamp, window.startAppTime);
        const captured = duration !== 0;
        datadogLogs.logger.info('AppLaunchComplete', {
          captured,
          duration,
        });
        trackCustomEvent({
          data: {
            captured,
            duration,
          },
          name: 'AppLaunchComplete',
        });
        dispatch(setIsAppInitialized(true));
      }
    })();
  }, [dispatch, isAppInitialized]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setDatadogUser());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <FocusContext.Provider value={focusKey}>
      <ErrorBoundary FallbackComponent={AppErrorBoundary} onReset={reloadApp}>
        {isAppInitialized ? (
          <AppContainer ref={ref} data-testid={appIds.APP_CONTAINER}>
            {showTitleSafeOverlay && <TitleSafeOverlay />}
            <Router />
          </AppContainer>
        ) : (
          <Spinner background="solid" variant="fullScreen" />
        )}

        <NetworkConnectionModal isOpen={!isConnectedToNetwork} />
        <div
          id="aria-live-region"
          aria-live="assertive"
          role="region"
          style={{ height: 0, width: 0 }}
        />
      </ErrorBoundary>
    </FocusContext.Provider>
  );
}
