import { merge } from 'lodash';
import favoriteTeamTranslations from './favoriteTeam.json';
import followingTeamTranslations from './followingTeam.json';
import gameMenuTranslations from './gameMenu.json';
import headerTranslations from './header.json';
import loginTranslations from './login.json';
import navigationTranslations from './navigation.json';
import settingsTranslations from './settings.json';

export const en = merge(
  headerTranslations,
  gameMenuTranslations,
  favoriteTeamTranslations,
  followingTeamTranslations,
  loginTranslations,
  navigationTranslations,
  settingsTranslations,
);
