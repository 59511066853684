// audio and video feeds
export const AUDIO_FEED = 'AUDIO';
export const AUDIO_TITLE = 'Audio';
export const AWAY_AUDIO_FEED = 'Away Audio';
export const AWAY_FEED = 'away';
export const AWAY_FEED_TYPE = 'AWAY';
export const EN_FEED = 'en';
export const ES_FEED = 'es';
export const HOME_AUDIO_FEED = 'Home Audio';
export const HOME_FEED = 'home';
export const HOME_FEED_TYPE = 'HOME';
export const INTERNATIONAL_FEED_SUFFIX = 'INT';
export const MEDIA_ARCHIVE = 'MEDIA_ARCHIVE';
export const MEDIA_OFF = 'MEDIA_OFF';
export const MEDIA_ON = 'MEDIA_ON';
export const MLBTV_VIDEO_FEED = 'MLBTV';
export const NATIONAL_FEED = 'national';
export const NATIONAL_FEED_TYPE = 'NATIONAL';
export const SELECT_FEED = 'Select Feed';
export const UNTITLED_FEED = 'Untitled';
export const VIDEO_FEED = 'VIDEO';
export const VIDEO_TITLE = 'Video';

// Audio Tracks
export const ENGLISH_AUDIO_TRACK = 'English';
export const NATURAL_SOUND_AUDIO_TRACK = 'Natural Sound';
export const NO_LINGUISTIC_CONTENT_AUDIO_TRACK = 'No linguistic content';
export const RADIO_ESPA_AUDIO_TRACK = 'Radio Espa';
export const SPANISH_AUDIO_TRACK = 'Spanish';

// Game Menu
export const BROADCAST_OPTIONS = 'Broadcast Options';
export const DETAILS = 'Details';

// feeds requiring MVPD Auth in the postseason
export const ABC_FEED_CALL_LETTERS = 'ABC';
export const ESPN_FEED_CALL_LETTERS = 'ESPN';
export const ESPN2_FEED_CALL_LETTERS = 'ESPN2';
export const ESPN_FEED = 'espn';
export const FOX_FEED = 'fox';
export const FS1_FEED = 'fs1';
export const MLBN_FEED = 'mlbn';
export const TBS_FEED = 'tbs';

// Audio Options Track Types
export const NAT_SOUND_TRACK_TYPE = 'NAT SOUND';
export const TV_TRACK_TYPE = 'TV';

// Secondary Feed Select
export const RESUME = 'Resume';
export const START_FROM_THE_BEGINNING = 'Start From The Beginning';
export const WATCH_LIVE = 'Watch Live';

// No Feeds
export const NO_BROADCAST_AVAILABLE = 'No Broadcast Available';

// Not Entitled to Feeds
export const FULL_GAME_ACCESS = 'Full Game Access';
export const GET_MLBTV = 'Get MLB.TV';
export const UPGRADE_MLBTV = 'Upgrade MLB.TV';
export const WATCH_FREE = 'Watch Free';

// DM5 Feeds
export const ARCHIVE_MLB_FEED = '0';
export const LIVE_MLB_FEED = '1';
