import mlbLogo from 'assets/mlbLogo.png';

import { MLB_LOGO_ALT_TEXT } from './constants';

export interface MlbLogoProps {
  'data-testid'?: string;
  margin?: string;
  width?: number;
}

export function MlbLogo(props: MlbLogoProps) {
  return <img alt={MLB_LOGO_ALT_TEXT} src={mlbLogo} {...props} />;
}
