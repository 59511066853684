import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { actionEventIds, AdobeTracker, ClickStatus } from 'analytics/adobe';
import {
  getLinescoreInningFocusKey,
  showLeftInningArrow,
  showRightInningArrow,
} from 'components/video/GameMenu/GameMenuOptions/Details/Linescore/utils';
import { BROADCAST_OPTIONS, DETAILS, VIDEO_FEED } from 'constants/feeds';
import { NUM_INNINGS_IN_GAME } from 'constants/gameState';
import { gameMenuIds } from 'constants/testIds';
import { useAdobeGameMenuParams } from 'hooks/player/useAdobeGameMenuParams/useAdobeGameMenuParams';
import { useSearchParams } from 'react-router-dom';
import { selectShouldShowBroadcastOptions, selectShouldShowDetails } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectGameMenuOption, selectInningOffset, setGameMenuOption } from 'store/player';
import { selectHideSpoilers } from 'store/profile';
import { selectCurrentInning, selectIsLive } from 'store/selectedVideo';
import { useLinescoreQuery } from 'store/stats/statsApiSlice';

import { useTextToSpeech, useTTSEnabled } from 'hooks';
import { speakImmediately } from 'utils/textToSpeech/textToSpeech';
import { GameMenuOption } from '../types';
import { GameMenuButton, GameMenuButtonContainer } from './GameMenuButtons.styles';

const { BroadcastOptions, Details } = GameMenuOption;
const { BROADCAST_OPTIONS_BUTTON, BROADCAST_OPTIONS_FEED, DETAILS_BUTTON, GAME_MENU_BUTTONS } =
  gameMenuIds;
const { BROADCAST_OPTIONS_OPEN_CLICK, VIDEO_PLAYER_DETAILS_CLICK } = actionEventIds();

interface GameMenuButtonsProps {
  onFirstFocus: boolean;
  setOnFirstFocus: (value: boolean) => void;
}

export function GameMenuButtons({ onFirstFocus, setOnFirstFocus }: GameMenuButtonsProps) {
  const dispatch = useAppDispatch();
  const { t } = useTextToSpeech();
  const ttsEnabled = useTTSEnabled();

  const [params] = useSearchParams();
  const gamePk = params.get('gamePk');

  const shouldShowBroadcastOptions = useAppSelector(selectShouldShowBroadcastOptions);
  const isLive = useAppSelector(selectIsLive);
  const shouldShowDetails = useAppSelector(selectShouldShowDetails);
  const gameMenuOption = useAppSelector(selectGameMenuOption);
  const { currentInning, isTop } = useAppSelector(selectCurrentInning);
  const inningOffset = useAppSelector(selectInningOffset);
  const hideSpoilers = useAppSelector(selectHideSpoilers);

  const shouldSkipLinescoreFetch = !hideSpoilers && !isLive;

  const { data } = useLinescoreQuery(gamePk, {
    skip: shouldSkipLinescoreFetch,
  });

  const detailsParams = useAdobeGameMenuParams(DETAILS);

  const onDetailsFocus = () => {
    AdobeTracker.trackAction(VIDEO_PLAYER_DETAILS_CLICK(ClickStatus.Open), detailsParams);
    if (ttsEnabled && onFirstFocus) {
      speakImmediately(t('gameMenu.onFocus'));
      setOnFirstFocus(false);
    }
    dispatch(setGameMenuOption(Details));
  };
  const onDetailsPress = () => {
    const allInnings = data?.innings ?? [];
    const firstFocusableInning = inningOffset + 1;
    const lastFocusableInning = NUM_INNINGS_IN_GAME + inningOffset + 1;

    const shouldShowLeftInningArrow = showLeftInningArrow({
      hideSpoilers,
      inningOffset,
      innings: allInnings,
    });
    const shouldShowRightInningArrow = showRightInningArrow({
      hideSpoilers,
      innings: allInnings,
      lastFocusableInning,
    });

    const linescoreInningFocusKey = getLinescoreInningFocusKey({
      currentInning,
      firstFocusableInning,
      isTop,
      lastFocusableInning,
      shouldShowLeftInningArrow,
      shouldShowRightInningArrow,
    });

    setFocus(linescoreInningFocusKey);
  };

  const onBroadcastOptionsFocus = () => {
    AdobeTracker.trackAction(BROADCAST_OPTIONS_OPEN_CLICK);
    if (ttsEnabled && onFirstFocus) {
      speakImmediately(t('gameMenu.onFocus'));
      setOnFirstFocus(false);
    }
    dispatch(setGameMenuOption(BroadcastOptions));
  };
  const onBroadcastOptionsPress = () => {
    setFocus(`${BROADCAST_OPTIONS_FEED}-${VIDEO_FEED}-0`);
  };

  const { focusKey, ref } = useFocusable({
    focusKey: GAME_MENU_BUTTONS,
  });

  const total = Number(shouldShowDetails) + Number(shouldShowBroadcastOptions);
  const rightOption = total > 1 ? 'press right for more options, ' : '';
  const leftOption = total > 1 ? 'press left for more options, ' : '';

  return (
    <FocusContext.Provider value={focusKey}>
      <GameMenuButtonContainer ref={ref} data-testid={GAME_MENU_BUTTONS}>
        {shouldShowDetails && (
          <GameMenuButton
            data-testid={DETAILS_BUTTON}
            aria-label={t(`gameMenu.details`, { index: 1, rightOption, total })}
            focusOptions={{
              focusKey: DETAILS_BUTTON,
              onFocus: onDetailsFocus,
            }}
            scale={false}
            selected={gameMenuOption === Details}
            onPress={onDetailsPress}>
            {DETAILS}
          </GameMenuButton>
        )}
        {shouldShowBroadcastOptions && (
          <GameMenuButton
            data-testid={BROADCAST_OPTIONS_BUTTON}
            aria-label={t(`gameMenu.broadcastOptions`, {
              index: total === 2 ? 2 : 1,
              leftOption,
              total,
            })}
            focusOptions={{
              focusKey: BROADCAST_OPTIONS_BUTTON,
              onFocus: onBroadcastOptionsFocus,
            }}
            scale={false}
            selected={gameMenuOption === BroadcastOptions}
            onPress={onBroadcastOptionsPress}>
            {BROADCAST_OPTIONS}
          </GameMenuButton>
        )}
      </GameMenuButtonContainer>
    </FocusContext.Provider>
  );
}
