import { datadogRum } from '@datadog/browser-rum';
import { setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Matchup } from 'components/Matchup';
import { ROUTES } from 'constants/screens';
import { feedSelectIds } from 'constants/testIds';
import { useFeedSelectGameContext } from 'context/FeedSelectGame';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectOpenModal, selectUserCountryCode } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectFeed, setSelectedFeed } from 'store/selectedVideo';
import { shouldShowBlackoutMessage, shouldShowMvpdMessage } from 'utils/gameData';

import { BlackoutMessage } from './BlackoutMessage';
import { FeedSelectContent, FeedSelectModal } from './FeedSelect.styles';
import { MvpdMessage } from './MvpdMessage';
import { NoFeeds } from './NoFeeds';
import { SecondaryFeedSelect } from './SecondaryFeedSelect';
import { StreamSelection } from './StreamSelection';
import { shouldShowNoFeedsMessage } from './utils';

const { FEED_SELECT, FEED_SELECT_CONTENT } = feedSelectIds;
const { GAMES } = ROUTES;

export function FeedSelectModalContent() {
  const { feedSelectGame } = useFeedSelectGameContext();
  const { gamePk = '', videoFeeds = [], videoStatusCodes = [] } = feedSelectGame ?? {};

  const userCountryCode = useAppSelector(selectUserCountryCode);
  const showBlackoutMessage = shouldShowBlackoutMessage(feedSelectGame);

  const showNoFeedsMessage = shouldShowNoFeedsMessage(feedSelectGame, showBlackoutMessage);
  const showMvpdMessage = shouldShowMvpdMessage(userCountryCode, videoFeeds);
  const selectedFeed = useAppSelector(selectFeed);
  const showPrimaryFeedSelect = !showNoFeedsMessage && !selectedFeed && !showMvpdMessage;
  const showSecondaryFeedSelect = !showNoFeedsMessage && !!selectedFeed;

  if (!feedSelectGame) return null;

  return (
    <FeedSelectContent data-testid={FEED_SELECT_CONTENT}>
      <Matchup focused={false} game={feedSelectGame} />
      {showBlackoutMessage && (
        <BlackoutMessage videoFeeds={videoFeeds} videoStatusCodes={videoStatusCodes} />
      )}
      {showNoFeedsMessage && <NoFeeds />}
      {showMvpdMessage && <MvpdMessage videoFeeds={videoFeeds} />}
      {showPrimaryFeedSelect && <StreamSelection game={feedSelectGame} />}
      {showSecondaryFeedSelect && <SecondaryFeedSelect feed={selectedFeed} gamePk={gamePk} />}
    </FeedSelectContent>
  );
}

export function FeedSelect() {
  const dispatch = useAppDispatch();
  const { feedSelectGame, from, setFeedSelectGame, setFrom } = useFeedSelectGameContext();
  const openModal = useAppSelector(selectOpenModal);
  const { pathname } = useLocation();

  const isFeedSelectOpen = openModal === FEED_SELECT;

  const { gamePk = '' } = feedSelectGame ?? {};

  const selectedFeed = useAppSelector(selectFeed);
  const showBlackoutMessage = shouldShowBlackoutMessage(feedSelectGame);

  const showNoFeedsMessage = shouldShowNoFeedsMessage(feedSelectGame, showBlackoutMessage);
  const showSecondaryFeedSelect = !showNoFeedsMessage && !!selectedFeed;

  // Setting "focusable" to false prevents being able to set focus on element when modal is mid-close
  useFocusable({ focusable: false });

  const onAfterClose = () => {
    setFrom(null);
    setFeedSelectGame(null);

    const fromView = pathname === GAMES ? 'GamesView' : 'HomeView';
    if (showSecondaryFeedSelect) return;
    datadogRum.startView({ name: fromView });
  };

  const handleBackToStreamSelection = () => {
    if (selectedFeed) setFocus(selectedFeed.contentId);
    dispatch(setSelectedFeed(null));
  };

  const onClose = selectedFeed ? handleBackToStreamSelection : undefined;

  useEffect(() => {
    if (isFeedSelectOpen) {
      const name = showSecondaryFeedSelect ? 'FeedOptionsView' : 'FeedSelectView';
      datadogRum.startView({
        name,
      });
    }
  }, [isFeedSelectOpen, showSecondaryFeedSelect]);

  return (
    <FeedSelectModal
      from={from ?? gamePk}
      isBackButtonEnabled={!showSecondaryFeedSelect}
      isOpen={isFeedSelectOpen}
      testId={FEED_SELECT}
      onAfterClose={onAfterClose}
      onClose={onClose}>
      <FeedSelectModalContent />
    </FeedSelectModal>
  );
}
