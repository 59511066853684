import { ROUTES } from 'constants/screens';
import { EpgApiGame, EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { yearMonthDay } from 'utils/date';
import { getIsMediaArchive, getIsMediaOn, isGamePreview, isMvpdGame } from 'utils/gameData';

const { GAME_VIDEO } = ROUTES;

interface buildGameVideoToObjectArgs {
  contentId: string;
  date?: Date;
  gamePk: string;
  mediaId: string;
}

interface ShowSecondaryFeedSelectArgs {
  bookmarkTime: number;
  entitled: boolean;
  feed: EpgAudioFeed | EpgVideoFeed;
}

export const buildGameVideoToObject = ({
  contentId,
  date = new Date(),
  gamePk,
  mediaId,
}: buildGameVideoToObjectArgs) => ({
  pathname: GAME_VIDEO,
  search: `?gamePk=${gamePk}&contentId=${contentId}&mediaId=${mediaId}&date=${yearMonthDay(date)}`,
});

export const doesGameHaveFeeds = (game: EpgApiGame) => {
  const { videoFeeds } = game;

  return videoFeeds.some((feed) => getIsMediaArchive(feed) || getIsMediaOn(feed));
};

export const shouldShowNoFeedsMessage = (
  game: EpgApiGame | null,
  showBlackoutMessage: boolean,
): boolean =>
  !game ||
  (!doesGameHaveFeeds(game) &&
    isGamePreview(game) &&
    !showBlackoutMessage &&
    !isMvpdGame(game.videoFeeds));

export const shouldShowSecondaryFeedSelect = ({
  bookmarkTime,
  entitled,
  feed,
}: ShowSecondaryFeedSelectArgs) => {
  const isMediaOn = getIsMediaOn(feed);
  const isMediaArchive = getIsMediaArchive(feed);
  const shouldShowSecondaryFeedSelectArchived = isMediaArchive && !!bookmarkTime;
  const shouldShowSecondaryFeedSelectLive = isMediaOn;

  return entitled && (shouldShowSecondaryFeedSelectArchived || shouldShowSecondaryFeedSelectLive);
};
