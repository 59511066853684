import { Experience } from '@mlbtv-clients/services';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { EXEC_MLB, MLBN, SUBSCRIBER_VOD } from 'constants/entitlement';
import { RequestStatus } from 'services/api';
import { RootState } from 'store';
import { signOut } from 'store/mlbAuth';

import { authorizeDSSSessionFlow, registerMLBSessionFlow } from './flows';
import { ExperienceState } from './types';

const { LEGACY, MLB } = Experience;

export const initialState: ExperienceState = {
  [LEGACY]: {
    deviceId: '',
    entitlements: [],
  },
  [MLB]: {
    deviceId: '',
    entitlements: [],
    sessionId: '',
  },
  experience: MLB,
  status: RequestStatus.IDLE,
};

const { ERROR, LOADING, SUCCESS } = RequestStatus;

const experienceSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(registerMLBSessionFlow.fulfilled, (state, action) => {
      const entitlements = action.payload.entitlements.map(({ code }) => code);
      state.status = SUCCESS;
      state.MLB = { ...action.payload, entitlements };
    });

    builder.addCase(authorizeDSSSessionFlow.fulfilled, (state, action) => {
      const entitlements = action.payload.entitlements.map(({ name }) => name);
      state.LEGACY.entitlements = entitlements;
      state.LEGACY.deviceId = action.payload.deviceId;
      state.status = SUCCESS;
    });
    builder.addCase(signOut, (state) => ({
      ...initialState,
      [LEGACY]: { ...initialState.LEGACY, deviceId: state.LEGACY.deviceId },
      [MLB]: { ...initialState.MLB, deviceId: state.MLB.deviceId },
      experience: state.experience,
      status: SUCCESS,
    }));

    // TODO: Figure out UX for when this errors
    builder.addMatcher(
      isAnyOf(authorizeDSSSessionFlow.rejected, registerMLBSessionFlow.rejected),
      (state) => ({
        ...initialState,
        [LEGACY]: { ...initialState.LEGACY, deviceId: state.LEGACY.deviceId },
        [MLB]: { ...initialState.MLB, deviceId: state.MLB.deviceId },
        status: ERROR,
      }),
    );

    builder.addMatcher(
      isAnyOf(authorizeDSSSessionFlow.pending, registerMLBSessionFlow.pending),
      (state) => {
        state.status = LOADING;
      },
    );
  },
  initialState,
  name: 'experience',
  reducers: {
    setExperience(state: ExperienceState, action: PayloadAction<Experience>) {
      state.experience = action.payload;
    },
    setExperienceStatus(state: ExperienceState, action: PayloadAction<RequestStatus>) {
      state.status = action.payload;
    },
  },
});

// Actions
export const { setExperience, setExperienceStatus } = experienceSlice.actions;

// Selectors

export const selectDeviceId = (state: RootState) => {
  const experience = selectExperience(state);
  return state.experience[experience].deviceId;
};

export const selectEntitlements = (state: RootState) => {
  const experience = selectExperience(state);
  return state.experience[experience].entitlements;
};

export const selectExperience = (state: RootState) => state.experience.experience;

export const selectExperienceStatus = (state: RootState) => state.experience.status;

export const selectIsDSSExperience = (state: RootState) =>
  state.experience.experience === Experience.LEGACY;

export const selectIsEntitledToMlbn = (state: RootState) => {
  const entitlements = selectEntitlements(state);
  return entitlements.includes(MLBN) || entitlements.includes(EXEC_MLB);
};

export const selectIsEntitledToSvod = (state: RootState) => {
  const entitlements = selectEntitlements(state);
  return entitlements.includes(SUBSCRIBER_VOD);
};

export const selectIsFreeUser = (state: RootState) => {
  const experience = selectExperience(state);
  return state.experience[experience].entitlements.length === 0;
};

export const selectIsMLBExperience = (state: RootState) =>
  state.experience.experience === Experience.MLB;

export const selectMLBSessionId = (state: RootState) => state.experience.MLB.sessionId;

export default experienceSlice.reducer;
