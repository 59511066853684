// format
export const DAY_OF_WEEK_MONTH_DAY = "EEEE',' MMMM d";
export const HOUR_MINUTE_AMPM = 'h:mm a';
export const MONTH_DAY = 'LLL do';

// lightFormat
export const MONTH_DAY_YEAR = 'MM/dd/yyyy';
export const YEAR_MONTH_DAY = 'yyyy-MM-dd';
export const YEAR_MONTH_DAY_TIME = 'yyyyMMdd_HHmmss';

// Country Codes
export const US = 'US';

// Text
export const TODAY_BUTTON_TEXT = 'Today';
export const TODAYS_GAMES = "Today's Games";

// Eastern Time Zone
export const EASTERN_TZ = 'America/New_York';
export const FORMAT_TZ_PATTERN = "yyyy-MM-dd'T'HH:mm:ss.sss";
