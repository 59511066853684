import styled from 'styled-components';

interface BroadcastOptionsFeedsProps {
  $numColumns: number;
}

export const BroadcastOptionsContainer = styled.div`
  display: grid;
  grid-gap: 15rem;
  grid-template-columns: auto 1fr;
  margin-top: ${({ theme }) => theme.spacing[10]};
  width: 100%;
`;

export const BroadcastOptionsOptionsTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

export const BroadcastOptionsFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BroadcastOptionsFeeds = styled.div<BroadcastOptionsFeedsProps>`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: ${({ $numColumns }) => `repeat(${$numColumns}, minmax(0, 1fr))`};
  grid-template-rows: repeat(2, auto);
  justify-content: flex-start;
`;
