import styled from 'styled-components';
import { primaryButtonColors } from 'styles/colors';

const { borderColor, color, focused } = primaryButtonColors;

export const BaseButton = styled.button`
  background: none;
  border: 0.3rem solid transparent;
`;

export const PrimaryButton = styled(BaseButton)`
  align-items: center;
  background-color: transparent;
  border-color: ${borderColor};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border-style: solid;
  border-width: 0.3rem;
  color: ${color};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  justify-content: center;
  padding: 0.9rem 2.4rem;

  &.focused {
    background-color: ${focused.backgroundColor};
    border-color: ${focused.backgroundColor};
    color: ${focused.color};
    &.scale {
      transform: scale(1.08);
    }
  }

  &.disabled {
    opacity: 0.5;
    transform: scale(0.9);
  }
`;
