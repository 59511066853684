/* eslint-disable sort-keys-fix/sort-keys-fix */
export const Z_INDICES = {
  BACKGROUND_IMAGE: -10,
  BACKGROUND_IMAGE_OVERLAY: -9,
  VIDEO_SCREEN: -1,
  GAME_TILE_HEADER_HOME_TEAM: 9,
  GAME_TILE_HEADER_AWAY_TEAM: 10,
  VIDEO_CONTENT: 10,
  GAME_TILE_HEADER_DIVIDER: 10,
  FEATURED_FORGE_TILE_CONTENT: 10,
  GAME_TILE_HEADER_OVERLAY: 12,
  GAME_TILE_HEADER_CONTENT: 15,
  VIDEO_DEBUGGER: 15,
  TITLE_SAFE_OVERLAY: 20,
  LOCK_ICON: 25,
  LOADER: 45,
  MODAL: 50,
};
