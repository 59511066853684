import { BaseModal } from 'components/modals/BaseModal';
import styled from 'styled-components';

export const FeedSelectModal = styled(BaseModal)`
  overflow: hidden;
  padding: 0;
`;

export const FeedSelectContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
