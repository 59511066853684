import { useTranslation } from 'react-i18next';
import i18n from 'utils/textToSpeech/i18n';
import { speak } from 'utils/textToSpeech/textToSpeech';
import { useTTSEnabled } from 'hooks';

const noop = () => {};

export const useTextToSpeech = () => {
  const ttsEnabled = useTTSEnabled();
  const { t } = useTranslation(__PLATFORM__);

  const speakLng = (phrase: string) => speak(phrase, i18n.language);

  return {
    speak: ttsEnabled ? speakLng : noop,
    t,
  };
};
