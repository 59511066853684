import { MlbLogo } from 'components/common/MlbLogo';
import styled, { css } from 'styled-components';
import { modalColors } from 'styles/colors';
import { Z_INDICES } from 'styles/zIndexHierarchy';

const { backdropColor, backgroundColor } = modalColors;

const { MODAL } = Z_INDICES;

export type ModalVariant = 'base' | 'fullScreen';

interface ModalBodyProps {
  $variant: ModalVariant;
}

export const ModalBody = styled.div<ModalBodyProps>`
  align-items: center;
  background: ${backgroundColor};
  border: solid 0.3rem;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  display: flex;
  flex-direction: column;
  max-width: 74.1rem;
  min-height: 33.6rem;
  padding: 2.5% 10%;
  width: 100%;
  z-index: ${MODAL};

  &.ReactModal__Content {
    transform: translateY(250%);
    transition: transform 0.4s ease-out;

    &--after-open {
      transform: translateY(0);
    }

    &--before-close {
      transform: translateY(250%);
      transition: transform 0.5s ease-in;
    }
  }

  ${({ $variant }) =>
    $variant === 'fullScreen' &&
    css`
      border: 0;
      border-radius: 0;
      height: 100vh;
      left: 0;
      max-width: none;
      top: 0;
      width: 100%;

      &.ReactModal__Content {
        transform: translateY(100%);
        transition: transform 0.5s ease-out;

        &--after-open {
          transform: translateY(0);
        }

        &--before-close {
          transform: translateY(100%);
          transition: transform 0.5s ease-in;
        }
      }
    `}
`;

export const ModalBackground = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${MODAL};

  &.ReactModal__Overlay {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${backdropColor};
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }

    &--after-open {
      &::before {
        opacity: 0.8;
      }
    }

    &--before-close {
      &::before {
        opacity: 0;
      }
    }
  }
`;

export const ModalLogo = styled(MlbLogo)`
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.spacing[20]};
  width: 36rem;
`;
