import api, { GET } from 'services/api';

import { StatsCondensedAndRecapResponse } from './types';

export const fetchCondensedAndRecap = async (
  gamePk: string,
): Promise<StatsCondensedAndRecapResponse> => {
  const response = await api<StatsCondensedAndRecapResponse>(GET, {
    endpoint: 'condensedAndRecap',
    queryParams: {
      gamePk,
    },
    service: 'statsapi',
  });

  return response.data;
};
