import { Spinner } from 'components/common/Spinner';
import { ROUTES } from 'constants/screens';
import { FeedSelectGameProvider } from 'context/FeedSelectGame';
import { PlayerProvider } from 'context/PlayerContext';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getGettingStartedViewed } from 'utils/storage';

const CreateAccount = lazy(() => import('screens/CreateAccount'));
const CreateAccountSuccess = lazy(() => import('screens/CreateAccountSuccess'));
const FavTeamPicker = lazy(() => import('screens/FavTeamPicker'));
const FollowTeamPicker = lazy(() => import('screens/FollowTeamPicker'));
const ForgeVideo = lazy(() => import('screens/ForgeVideo'));
const GameVideo = lazy(() => import('screens/GameVideo'));
const GetStarted = lazy(() => import('screens/GetStarted'));
const Games = lazy(() => import('screens/Games'));
const Home = lazy(() => import('screens/Home'));
const Login = lazy(() => import('screens/Login'));
const LoginCallback = lazy(() => import('screens/LoginCallback'));
const Purchase = lazy(() => import('screens/Purchase'));
const Settings = lazy(() => import('screens/Settings'));
const Paywall = lazy(() => import('screens/Paywall'));
const Welcome = lazy(() => import('screens/Welcome'));

const {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  FAV_TEAM_PICKER,
  FOLLOW_TEAM_PICKER,
  FORGE_VIDEO,
  GAME_VIDEO,
  GAMES,
  GET_STARTED,
  HOME,
  LOGIN,
  LOGIN_CALLBACK,
  PAYWALL,
  PURCHASE,
  SETTINGS,
  WELCOME,
} = ROUTES;

function OnAppLoadRouter() {
  const isGettingStartedViewed = getGettingStartedViewed();

  return isGettingStartedViewed ? (
    <FeedSelectGameProvider>
      <Home />
    </FeedSelectGameProvider>
  ) : (
    <Navigate replace to={GET_STARTED} />
  );
}

export function Router() {
  return (
    <Suspense fallback={<Spinner variant="fullScreen" />}>
      <Routes>
        <Route
          element={
            <PlayerProvider>
              <ForgeVideo />
            </PlayerProvider>
          }
          path={FORGE_VIDEO}
        />
        <Route
          element={
            <PlayerProvider>
              <GameVideo />
            </PlayerProvider>
          }
          path={GAME_VIDEO}
        />
        <Route
          element={
            <FeedSelectGameProvider>
              <Games />
            </FeedSelectGameProvider>
          }
          path={GAMES}
        />
        <Route element={<FavTeamPicker />} path={FAV_TEAM_PICKER} />
        <Route element={<FollowTeamPicker />} path={FOLLOW_TEAM_PICKER} />
        <Route element={<GetStarted />} path={GET_STARTED} />
        <Route element={<Login />} path={LOGIN} />
        <Route element={<LoginCallback />} path={LOGIN_CALLBACK} />
        <Route element={<OnAppLoadRouter />} path={HOME} />
        <Route element={<Settings />} path={SETTINGS} />
        <Route element={<Welcome />} path={WELCOME} />
        <Route element={<CreateAccount />} path={CREATE_ACCOUNT} />
        <Route element={<Paywall />} path={PAYWALL} />
        <Route element={<Purchase />} path={PURCHASE} />
        <Route element={<CreateAccountSuccess />} path={CREATE_ACCOUNT_SUCCESS} />
        <Route element={<Navigate replace to={HOME} />} path="*" />
      </Routes>
    </Suspense>
  );
}
