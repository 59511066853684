export enum ForgeDisplayTypes {
  FEATURED = 'featured',
  GAME = 'game',
  VOD = 'vod',
}

export enum PlaylistType {
  ARCHIVE = 'archive',
  GAME = 'game',
  SVOD = 'svod',
  VOD = 'vod',
}

export interface PlaylistApiItem {
  displayType: ForgeDisplayTypes;
  hideSpoilers: boolean;
  title: string;
  type: PlaylistType;
  url: string;
}

export interface PlaylistApiResponse {
  featuredContent: PlaylistApiItem[];
  items: PlaylistApiItem[];
}
