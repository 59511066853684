// time is in seconds
export const formatForgeVideoTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  const m = minutes > 9 || hours === 0 ? minutes : `0${minutes}`;
  const s = seconds > 9 ? seconds : `0${seconds}`;

  if (hours === 0) {
    return `${m}:${s}`;
  }

  return `${hours}:${m}:${s}`;
};

// time is in seconds
export const formatVideoTime = (time: number): string => {
  const nonNegativeTime = time < 0 ? 0 : time;

  const hours = Math.floor(nonNegativeTime / 3600);
  const minutes = Math.floor((nonNegativeTime % 3600) / 60);
  const seconds = Math.floor(nonNegativeTime % 60);

  const h = hours > 9 ? hours : `${hours}`;
  const m = minutes > 9 ? minutes : `0${minutes}`;
  const s = seconds > 9 ? seconds : `0${seconds}`;

  const timeString = `${h}:${m}:${s}`;

  return timeString;
};

export const msToSeconds = (ms: number) => ms / 1000;
