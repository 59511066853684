import { NAVIGATION_KEY_MAP } from 'utils/controllerKeymap';

export const getArrowDirectionText = (keyCode: number) => {
  if (NAVIGATION_KEY_MAP.left.includes(keyCode)) {
    return 'left';
  } else if (NAVIGATION_KEY_MAP.up.includes(keyCode)) {
    return 'above';
  } else if (NAVIGATION_KEY_MAP.right.includes(keyCode)) {
    return 'right';
  } else if (NAVIGATION_KEY_MAP.down.includes(keyCode)) {
    return 'below';
  } else {
    return '';
  }
};

// This is a more dependable way to determine if a screen has been loaded and is ready for TTS to be spoken
export const waitForElement = async (selector: string) => {
  if (document.querySelector(selector)) {
    return document.querySelector(selector);
  }

  const observer = new MutationObserver(async () => {
    if (document.querySelector(selector)) {
      observer.disconnect();
      return document.querySelector(selector);
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};
