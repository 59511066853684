import { shouldHideGameScore } from 'components/GameTiles/GameTile/utils';
import { EpgAndStatsGameData } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { selectHideSpoilers } from 'store/profile';

import { GameDetailsContainer, GameDetailsDash, GameDetailsScore } from './GameDetails.styles';

interface GameDetailsProps {
  gameData: EpgAndStatsGameData;
}

export function GameDetails({ gameData }: GameDetailsProps) {
  const hideSpoilers = useAppSelector(selectHideSpoilers);

  const {
    abstractGameState,
    away: { runs: awayTeamRuns },
    detailedState,
    home: { runs: homeTeamRuns },
  } = gameData;

  const score = (
    <>
      <GameDetailsScore>{awayTeamRuns}</GameDetailsScore>
      <GameDetailsDash>-</GameDetailsDash>
      <GameDetailsScore>{homeTeamRuns}</GameDetailsScore>
    </>
  );
  const text = shouldHideGameScore({
    abstractGameState,
    detailedState,
    hideSpoilers,
  })
    ? ''
    : score;

  return <GameDetailsContainer>{text}</GameDetailsContainer>;
}
