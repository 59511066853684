import { selectFeatureFlags } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { getTextToSpeechEnabledStorage } from 'utils/storage';
import { selectTTSEnabled } from 'store/deviceSettings';

export const useTTSEnabled = () => {
  const { hasTextToSpeech } = useAppSelector(selectFeatureFlags);
  const deviceTTSEnabled = useAppSelector(selectTTSEnabled);
  const devSettingsTTSEnabled = getTextToSpeechEnabledStorage();

  const ttsEnabled = devSettingsTTSEnabled || (hasTextToSpeech && deviceTTSEnabled);

  return ttsEnabled;
};
