import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'services/api';
import { fetchPlayByPlayData, PlayByPlay } from 'services/statsApi/playByPlay';
import { RootState } from 'store';
import { selectCurrentTime, selectSeekOffset } from 'store/player';
import { selectAbsoluteStreamStartTime } from 'store/selectedVideo';
import { getAbsoluteDateWithOffset } from 'utils/gameVideo';

import { getOutsAtTime, getScoreAtTime } from './utils';

interface PlayByPlayWithGamePk {
  gamePk: string;
  playByPlay: PlayByPlay;
}

export interface PlayByPlayState {
  games: {
    [gamePk: string]: PlayByPlay;
  };
  status: RequestStatus;
}

export const initialState: PlayByPlayState = {
  games: {},
  status: RequestStatus.IDLE,
};

export const fetchPlayByPlayFlow = createAsyncThunk<
  PlayByPlayWithGamePk,
  string,
  { rejectValue: string; state: RootState }
>('playByPlay/fetchPlayByPlay', async (gamePk, { rejectWithValue }) => {
  try {
    const formattedPlayByPlayData = await fetchPlayByPlayData(gamePk);

    return {
      gamePk,
      playByPlay: formattedPlayByPlayData,
    };
  } catch (error: any) {
    return rejectWithValue(error?.message);
  }
});

export const playByPlaySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPlayByPlayFlow.pending, (state) => {
      state.status = RequestStatus.LOADING;
    });

    builder.addCase(fetchPlayByPlayFlow.fulfilled, (state, { payload }) => {
      const { gamePk, playByPlay } = payload;
      state.games = {
        ...state.games,
        [gamePk]: playByPlay,
      };
      state.status = RequestStatus.SUCCESS;
    });

    builder.addCase(fetchPlayByPlayFlow.rejected, (state) => {
      state.status = RequestStatus.ERROR;
    });
  },
  initialState,
  name: 'playByPlay',
  reducers: {},
});

// selectors
export const selectPlayByPlayData = (state: RootState) => state.playByPlay.games;

export const selectPlayByPlayDataForGame = (state: RootState, gamePk: string | null) =>
  state.playByPlay.games[gamePk ?? ''];

export const selectGamePkFromProps = (_: RootState, gamePk: string | null) => gamePk;

export const selectScoreAtTime = createSelector(
  [
    selectGamePkFromProps,
    selectPlayByPlayData,
    selectAbsoluteStreamStartTime,
    selectCurrentTime,
    selectSeekOffset,
  ],
  (gamePk, playByPlay, streamStartTime, currentTime, seekOffset) => {
    const playByPlayForGame = playByPlay[gamePk ?? ''];
    const absoluteDateWithOffset = getAbsoluteDateWithOffset({
      currentTime,
      seekOffset,
      streamStartTime,
    });

    return getScoreAtTime(playByPlayForGame, absoluteDateWithOffset);
  },
);

export const selectCurrentOuts = createSelector(
  [
    selectGamePkFromProps,
    selectPlayByPlayData,
    selectAbsoluteStreamStartTime,
    selectCurrentTime,
    selectSeekOffset,
  ],
  (gamePk, playByPlay, streamStartTime, currentTime, seekOffset) => {
    const playByPlayForGame = playByPlay[gamePk ?? ''];
    const absoluteDateWithOffset = getAbsoluteDateWithOffset({
      currentTime,
      seekOffset,
      streamStartTime,
    });

    return getOutsAtTime(playByPlayForGame, absoluteDateWithOffset);
  },
);

export default playByPlaySlice.reducer;
