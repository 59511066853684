import { AxiosError } from 'axios';
import { fetchCondensedAndRecap } from 'services/statsApi/condensedAndRecap';
import { fetchLinescore, Linescore } from 'services/statsApi/linescore';
import { fetchMatchup, MatchupData } from 'services/statsApi/matchup';
import { RootState } from 'store';
import { apiSlice } from 'store/apiSlice';
import { selectCurrentTime } from 'store/player';
import { selectHideSpoilers } from 'store/profile';
import { selectAbsoluteStreamStartTime } from 'store/selectedVideo';
import { addSeconds, yearMonthDayHourMinuteSecond } from 'utils/date';

import { getEpgAlternateSlug } from './utils';

export interface StatsCondensedAndRecap {
  condensed: string;
  recap: string;
}

export const statsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    condensedAndRecap: builder.query<StatsCondensedAndRecap, string>({
      keepUnusedDataFor: 30,
      queryFn: async (gamePk) => {
        try {
          const result = await fetchCondensedAndRecap(gamePk);
          const { epgAlternate } = result.dates[0].games[0].content.media;

          const condensed = getEpgAlternateSlug(epgAlternate, 'condensed_game');
          const recap = getEpgAlternateSlug(epgAlternate, 'mlb_recap');

          const data = { condensed, recap };

          return { data };
        } catch (error) {
          if (error instanceof AxiosError) {
            const { code, message, name, response } = error;
            return {
              error: { code, message, name, status: response?.status },
            };
          }

          return {
            error:
              error?.message ||
              `We're having some trouble fetching the condensed and recap videos. Please try again later`,
          };
        }
      },
    }),
    linescore: builder.query<Linescore, string | null>({
      keepUnusedDataFor: 30,
      providesTags: ['Linescore'],
      queryFn: async (gamePk, { getState }) => {
        try {
          const state = getState() as RootState;
          const streamStartTime = selectAbsoluteStreamStartTime(state);
          const currentTime = selectCurrentTime(state);
          const hideSpoilers = selectHideSpoilers(state);

          const absoluteDate = addSeconds(new Date(streamStartTime), currentTime);
          const timecode = yearMonthDayHourMinuteSecond(absoluteDate);

          const result = await fetchLinescore(gamePk, hideSpoilers ? timecode : '');

          return { data: result };
        } catch (error) {
          if (error instanceof AxiosError) {
            const { code, message, name, response } = error;
            return {
              error: { code, message, name, status: response?.status },
            };
          }

          return {
            error:
              error?.message ||
              `We're having some trouble fetching the linescore data. Please try again later`,
          };
        }
      },
    }),
    matchup: builder.query<MatchupData, string | null>({
      keepUnusedDataFor: 30,
      providesTags: ['Matchup'],
      queryFn: async (gamePk, { getState }) => {
        try {
          const state = getState() as RootState;
          const streamStartTime = selectAbsoluteStreamStartTime(state);
          const currentTime = selectCurrentTime(state);

          const absoluteDate = addSeconds(new Date(streamStartTime), currentTime);
          const timecode = yearMonthDayHourMinuteSecond(absoluteDate);

          const result = await fetchMatchup(gamePk, timecode);

          return { data: result };
        } catch (error) {
          if (error instanceof AxiosError) {
            const { code, message, name, response } = error;
            return {
              error: { code, message, name, status: response?.status },
            };
          }

          return {
            error:
              error?.message ||
              `We're having some trouble fetching the matchup data. Please try again later`,
          };
        }
      },
    }),
  }),
});

export const { useCondensedAndRecapQuery, useLinescoreQuery, useMatchupQuery } = statsApiSlice;
