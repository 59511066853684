import { ServicesConfig } from 'store/config';
import { DotNestedKeys } from 'types/abstract';

export interface QueryParams {
  [key: string]: string;
}

export interface RequestInfo {
  body?: object | string;
  headers?: object;
  timeoutMs?: number;
  withCredentials?: boolean;
}

type Service = keyof ServicesConfig;
type Endpoint = DotNestedKeys<ServicesConfig[Service]['endpoints']>;

export interface RequestConfig {
  endpoint: Endpoint;
  queryParams?: QueryParams;
  service: Service;
}

export enum RequestStatus {
  ERROR = 'error',
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export type Verb = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
