import { VIDEO_FEED } from 'constants/feeds';
import {
  Airing,
  createMediaDescriptor,
  createPlaybackSession,
  CustomPlayerAdapter,
  fetchMediaItem,
  getAiringsByGamePk,
  PlaybackScenarios,
  PlayerAdapterOptions,
} from 'experience/DSS';
import Hls from 'hls.js';
import { addSeconds } from 'utils/date';
import { msToSeconds } from 'utils/time';

export const fetchAirings = async (gamePk: string, feedContentId: string) => {
  const airings = await getAiringsByGamePk(gamePk);

  return airings
    .filter((a) => !!a)
    .find(
      ({ contentId, mediaConfig }) =>
        contentId === feedContentId && mediaConfig?.type === VIDEO_FEED,
    );
};

export const getIsAtLivePoint = ({
  currentTime,
  livePointOffsetInterval,
  totalTime,
}: {
  currentTime: number;
  livePointOffsetInterval: number;
  totalTime: number;
}) => {
  const livePointOffset = msToSeconds(livePointOffsetInterval);
  const timeUntilLive = totalTime - currentTime;

  return timeUntilLive <= livePointOffset;
};

interface InitializeDSSPlaybackSessionArgs {
  airing: Airing;
  hasPodServing: boolean;
  hls: Hls;
}

export const initializeDSSPlaybackSession = async ({
  airing,
  hasPodServing,
  hls,
}: InitializeDSSPlaybackSessionArgs) => {
  const playbackUrls = airing?.playbackUrls ?? [];
  const playbackUrl = playbackUrls[0]?.href ?? '';
  const playbackScenario = hasPodServing
    ? PlaybackScenarios.PLAYSTATION_DFP2_SSAI
    : PlaybackScenarios.PLAYSTATION_SSAI;
  const playback = playbackUrl.replace('{scenario}', playbackScenario);

  const playerAdapterOptions: PlayerAdapterOptions = {
    nativePlayer: hls,
    videoPlayerName: `${__PLATFORM__} HLS Constructor`,
    videoPlayerVersion: `${__PLATFORM__} HLS Constructor for ReactPlayer`,
  };
  const playerAdapter = await new CustomPlayerAdapter(playerAdapterOptions);
  const playbackSession = await createPlaybackSession(playerAdapter);
  const mediaDescriptor = await createMediaDescriptor(playback);
  const mediaItem = await fetchMediaItem(mediaDescriptor);
  const preparedSession = await playbackSession.prepare({
    mediaItem,
  });

  return {
    bamSdkConvivaData: mediaItem.payload.tracking.conviva,
    playbackUrl: preparedSession.prioritizedUrls[0].url,
  };
};

interface GetAbsoluteDateWithOffsetProps {
  currentTime: number;
  seekOffset: number;
  streamStartTime: string;
}

export const getAbsoluteDateWithOffset = ({
  currentTime,
  seekOffset,
  streamStartTime,
}: GetAbsoluteDateWithOffsetProps) => {
  const timeWithOffset = currentTime + seekOffset;

  return addSeconds(new Date(streamStartTime), timeWithOffset).toISOString();
};
