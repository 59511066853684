import MLBBullpenNeueBold from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Bold.otf';
import MLBBullpenNeueBoldItalic from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Bold-Italic.otf';
import MLBBullpenNeueBook from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Book.otf';
import MLBBullpenNeueBookItalic from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Book-Italic.otf';
import MLBBullpenNeueHeavy from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Heavy.otf';
import MLBBullpenNeueHeavyItalic from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Heavy-Italic.otf';
import MLBBullpenNeueLight from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Light.otf';
import MLBBullpenNeueLightItalic from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Light-Italic.otf';
import MLBBullpenNeueMedium from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Medium.otf';
import MLBBullpenNeueMediumItalic from 'assets/fonts/mlb-bullpen-neue/MLB-Bullpen-Neue-Medium-Italic.otf';
import { css } from 'styled-components';

export default css`
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueLight}) format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueLightItalic}) format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueBook}) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueBookItalic}) format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueMedium}) format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueMediumItalic}) format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueBoldItalic}) format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueHeavy}) format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'MLB Bullpen Neue';
    src: url(${MLBBullpenNeueHeavyItalic}) format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: fallback;
  }
`;
