import { TeamLogo } from 'components/TeamLogo';
import { EpgApiTeam } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { selectHideSpoilers } from 'store/profile';

import { TeamAbbrv, TeamDetailsContainer, TeamRecord } from './TeamDetails.styles';

interface TeamDetailsProps {
  focused: boolean;
  isRich: boolean;
  team: EpgApiTeam;
}

export function TeamDetails({ focused, isRich, team }: TeamDetailsProps) {
  const hideSpoilers = useAppSelector(selectHideSpoilers);

  const { losses, teamAbbrv, teamId, wins } = team;
  const showTeamRecord = focused && isRich && !hideSpoilers;

  return (
    <TeamDetailsContainer>
      <TeamLogo teamId={teamId} />
      {isRich && <TeamAbbrv>{teamAbbrv}</TeamAbbrv>}
      {showTeamRecord && <TeamRecord>{`${wins}-${losses}`}</TeamRecord>}
    </TeamDetailsContainer>
  );
}
