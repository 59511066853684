import { DAIParams } from 'ads/hooks/useIMA';
import { PlaylistTopicValues } from 'services/playlist/constants';

export interface ExtraData {
  awayTeamFileCode?: string;
  awayTeamId?: number;
  gameDateTime?: string;
  gameId?: string;
  gamePk?: number;
  gameStatusCode?: string;
  homeTeamFileCode?: string;
  homeTeamId?: number;
  id: number;
  teamCode?: string;
  teamFileCode?: string;
  type: string;
}

export interface FeaturedFields {
  heading: string;
  newWindow: boolean;
  searchableContent: boolean;
  text: string;
}

export interface Fields {
  blurb: string;
  description: string;
  duration: string;
  heading?: string;
  mediaPlaybackId: string;
  playbackScenarios: PlaybackScenario[];
  text?: string;
  url: string;
}

export interface ForgeContentApiItem {
  contentDate: string;
  featured?: number;
  fields: Fields;
  selfUrl?: string;
  slug: string;
  tags: Tag[];
  thumbnail: Thumbnail;
  title: string;
}

interface BaseContentApiResponse {
  meta: {
    apiVersion: string;
    generatedAt: string;
  };
  pagination: {
    maxItems: number;
    nextUrl: string;
  };
}

export interface ForgeContentApiResponse extends BaseContentApiResponse {
  items: ForgeContentApiItem[];
}

export interface FormattedContentApiResponse extends BaseContentApiResponse {
  items: ForgeVideoData[];
}

export type ForgeContentFlowResponse = {
  featuredItemData: ForgeVideoData;
  topic: PlaylistTopicValues;
};

export interface ForgeFeaturedApiItem {
  contentDate: string;
  featured: number;
  fields: FeaturedFields;
  image?: Thumbnail;
  references?: ForgeFeaturedItemReferences;
  selfUrl: string;
  slug: string;
  tags: Tag[];
  thumbnail: Thumbnail;
  title: string;
}

export interface ForgeFeaturedItemReferences {
  alternateThumbnail: ForgeFeaturedApiItem[];
  alternateThumbnail2: ForgeFeaturedApiItem[];
  video?: ForgeContentApiItem[];
}

export interface ForgePlaylistApi {
  displayType: string;
  hideSpoilers: boolean;
  items: ForgeContentApiItem[];
  title: string;
  type: string;
  url: string;
}

export interface ForgePlaylist {
  displayType: string;
  hideSpoilers: boolean;
  items: ForgeVideoData[];
  title: string;
  type: string;
  url: string;
}

export interface PlaybackScenario {
  location: string;
  playback: string;
}

export interface Tag {
  extraData?: ExtraData;
  selfUrl: string;
  slug: string;
  title: string;
}

export interface Thumbnail {
  alternateTemplateUrl?: string;
  templateUrl?: string;
  thumbnailUrl?: string;
}

interface BaseForgeVideoData {
  contentDate: string;
  slug: string;
  tags: Tag[];
  thumbnail: Thumbnail;
  title?: string;
}

export type ForgeVideoData = BaseForgeVideoData & {
  blurb: string;
  description: string;
  duration: number;
  heading?: string;
  mediaPlaybackId: string;
  text?: string;
  url: string;
};

export enum ForgeVideoType {
  AD_HOC_STREAM = 'ad-hoc-stream',
  MLBN = 'mlbn-linear',
  SLIVE = 'slive',
  SVOD = 'svod',
  VOD = 'vod',
}

export interface SLiveDAIResponse {
  dai: {
    assetKey: string;
    cdns: { deliveryPrefix: string; name: string; token: string }[];
    enabled: boolean;
    gamOutage: boolean;
    originalMasterPlaylist: string;
  };
}

export type SLiveDAI = Pick<DAIParams, 'assetKey' | 'enabled' | 'originalUrl' | 'token' | 'type'>;
