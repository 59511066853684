import { Milestone } from '@mlbtv-clients/services';
import { BOTTOM_INNING, NUM_INNINGS_IN_GAME, TOP_INNING } from 'constants/gameState';
import { LinescoreInning } from 'services/statsApi/linescore';
import { InningMilestone } from 'store/selectedVideo/types';

interface GetLinescoreInningFocusKeyProps {
  currentInning?: number;
  firstFocusableInning: number;
  isTop: boolean;
  lastFocusableInning: number;
  shouldShowLeftInningArrow: boolean;
  shouldShowRightInningArrow: boolean;
}

export const getLinescoreInningFocusKey = ({
  currentInning = 1,
  firstFocusableInning,
  isTop,
  lastFocusableInning,
  shouldShowLeftInningArrow,
  shouldShowRightInningArrow,
}: GetLinescoreInningFocusKeyProps) => {
  if (shouldShowLeftInningArrow && currentInning <= firstFocusableInning) {
    return `${TOP_INNING}-${firstFocusableInning + 1}`;
  }
  if (shouldShowRightInningArrow && currentInning >= lastFocusableInning) {
    return `${TOP_INNING}-${lastFocusableInning - 1}`;
  }

  const topOrBot = isTop ? TOP_INNING : BOTTOM_INNING;
  return `${topOrBot}-${currentInning}`;
};

interface ShowLeftInningArrowProps {
  currentInning?: number;
  hideSpoilers: boolean;
  inningOffset: number;
  innings: LinescoreInning[];
}

export const showLeftInningArrow = ({
  currentInning,
  hideSpoilers,
  inningOffset,
  innings,
}: ShowLeftInningArrowProps) => {
  const showForHideSpoilers =
    hideSpoilers && !!currentInning && currentInning >= NUM_INNINGS_IN_GAME + inningOffset;
  const extraInningsExist = innings.length > NUM_INNINGS_IN_GAME && inningOffset > 0;

  return (!hideSpoilers || showForHideSpoilers) && extraInningsExist;
};

interface ShowRightInningArrowProps {
  currentInning?: number;
  hideSpoilers: boolean;
  innings: LinescoreInning[];
  lastFocusableInning: number;
}

export const showRightInningArrow = ({
  currentInning,
  hideSpoilers,
  innings,
  lastFocusableInning,
}: ShowRightInningArrowProps) => {
  const showForHideSpoilers =
    hideSpoilers && !!currentInning && currentInning > lastFocusableInning;
  const extraInningsExist =
    innings.length > NUM_INNINGS_IN_GAME && lastFocusableInning !== innings.length;

  return (!hideSpoilers || showForHideSpoilers) && extraInningsExist;
};

interface IsLinescoreFocusableProps {
  gameLiveMilestone: Milestone | undefined;
  hideSpoilers: boolean;
  isLive: boolean;
  isPastGameLiveTime: boolean;
}

export const isLinescoreFocusable = ({
  gameLiveMilestone,
  hideSpoilers,
  isLive,
  isPastGameLiveTime,
}: IsLinescoreFocusableProps) => {
  const focusableForArchive = !isLive || !!gameLiveMilestone;

  return (!hideSpoilers && focusableForArchive) || (hideSpoilers && isPastGameLiveTime);
};

interface ShouldShowInningScoreProps {
  currentInning: number;
  gameLiveMilestone: Milestone | undefined;
  hideSpoilers: boolean;
  inning: number;
  isAway: boolean;
  isLive: boolean;
  isPastGameLiveTime: boolean;
  isTop: boolean;
  latestInningMilestone: InningMilestone | undefined;
}

export const shouldShowInningScore = ({
  currentInning,
  gameLiveMilestone,
  hideSpoilers,
  inning,
  isAway,
  isLive,
  isPastGameLiveTime,
  isTop,
  latestInningMilestone,
}: ShouldShowInningScoreProps) => {
  const isPriorInning = inning < currentInning;
  const isCurrentInning = currentInning === inning;
  const isCurrentOrPriorInning = isCurrentInning || isPriorInning;
  const isLatestInning = `${inning}` === latestInningMilestone?.inning;

  // Home Inning Logic
  const showHomeScoreLive = isPastGameLiveTime && isCurrentInning && !isTop;
  const showHomeScoreArchive = isCurrentInning && !isTop && !!gameLiveMilestone;
  const showHomeScoreForCurrentInningHideSpoilers = isLive
    ? showHomeScoreLive
    : showHomeScoreArchive;
  const showHomeScoreForCurrentInningNonHideSpoilers = isLive ? !!gameLiveMilestone : true;
  const showHomeScoreForCurrentInning = hideSpoilers
    ? showHomeScoreForCurrentInningHideSpoilers
    : showHomeScoreForCurrentInningNonHideSpoilers;
  const showHomeScoreForPriorOrFutureInning = !isLatestInning && !isCurrentInning && !hideSpoilers;
  const showHomeScoreForPriorInning = hideSpoilers && isPriorInning;
  const showHomeScoreForLatestInning =
    hideSpoilers && isCurrentInning && isTop ? false : !latestInningMilestone?.isTop;

  const shouldShowHomeScore =
    !isAway &&
    (isLatestInning
      ? showHomeScoreForLatestInning
      : showHomeScoreForPriorInning ||
        showHomeScoreForPriorOrFutureInning ||
        showHomeScoreForCurrentInning);

  // Away Inning Logic
  const showAwayScoreForCurrentOrPriorInning =
    hideSpoilers && !!gameLiveMilestone && isPastGameLiveTime && isCurrentOrPriorInning;
  const showAwayScoreForNonHideSpoilers = isLive
    ? !hideSpoilers && !!gameLiveMilestone
    : !hideSpoilers;

  const shouldShowAwayScore =
    isAway && (showAwayScoreForNonHideSpoilers || showAwayScoreForCurrentOrPriorInning);

  return isAway ? shouldShowAwayScore : shouldShowHomeScore;
};
