import {
  InningBox,
  InningHeader,
} from 'components/video/GameMenu/GameMenuOptions/Details/Linescore/Linescore.styles';
import styled from 'styled-components';
import { linescoreColors } from 'styles/colors';

const { statsColor } = linescoreColors;

export const StatsBox = styled(InningBox)`
  color: ${statsColor};
`;

export const StatsHeader = styled(InningHeader)`
  color: ${statsColor};
`;
