import api, { POST } from 'services/api';
import { ServicesConfig } from 'store/config';

import { ExpressLoginData, StandardLoginData } from './types';

export const fetchExpressLoginData = async (
  mlbAuthMetaConfig: ServicesConfig['mlbAuth']['meta'],
) => {
  const response = await api<ExpressLoginData>(
    POST,
    {
      endpoint: 'express',
      service: 'mlbAuth',
    },
    {
      body: {
        client_id: mlbAuthMetaConfig.clientId,
        scope: mlbAuthMetaConfig.express.scopes,
      },
      headers: { 'Content-Type': mlbAuthMetaConfig.express.contentType },
    },
  );

  return response.data;
};

export const fetchStandardLoginData = async (
  deviceCode: string,
  mlbAuthMetaConfig: ServicesConfig['mlbAuth']['meta'],
) => {
  const response = await api<StandardLoginData>(
    POST,
    {
      endpoint: 'token',
      service: 'mlbAuth',
    },
    {
      body: {
        client_id: mlbAuthMetaConfig.clientId,
        device_code: deviceCode,
        grant_type: mlbAuthMetaConfig.express.grantType,
        scope: mlbAuthMetaConfig.express.scopes,
      },
      headers: { 'Content-Type': mlbAuthMetaConfig.express.contentType },
    },
  );

  return response.data;
};
