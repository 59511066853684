import SDK, {
  MediaDescriptorContext,
  Session,
  SessionInfo,
} from 'bam-browser-sdk/dist/browser_browser_es5/bam-browser-sdk';
import { AccountEntitlement } from 'experience/DSS';
import { Config, SdkConfig } from 'store/config';

import { COMPLETE_PLAYLIST_TYPE } from './constants';
import { Airing, ContentApiAiringsData } from './types';

const version = __VERSION__;
const name = __PLATFORM__;
const { ContentQuery, GraphQlPersistedQuery } = SDK.Content;

let session: Session;
let sdkConfig: SdkConfig;

export const init = async (config: Config) => {
  sdkConfig = config.sdk;

  const bootstrapConfiguration = getBootstrapConfig();
  session = await SDK.SdkSession.createSdkSession({ bootstrapConfiguration });

  await session.initialize();

  return session;
};

const getBootstrapConfig = () => {
  const { clientApiKey, clientId, debugEnabled, environment, platform } = sdkConfig;

  const { BootstrapConfiguration, Platform } = SDK.Services.Configuration;

  const applicationId = `${name} - ${version}`;

  const bootstrapConfig = new BootstrapConfiguration({
    application: {
      id: applicationId,
      name,
      version,
    },
    clientApiKey,
    clientId,
    debugEnabled,
    environment,
    platform: Platform[platform],
  });

  return bootstrapConfig;
};

export const authorizeSession = async (entitlementToken: string) => {
  const { provider } = sdkConfig;

  return session.authorize(entitlementToken, provider);
};

// the number 7 represents the maximum possible
// number of airings a gamepk may have attached
// 2 audio feeds per team
// 1 video stream per team
// 1 additional national broadcast feed
const pageSizeForAiringsByGamePk = 7;

// this number is used to randomly generate a
// page size when fetching airings to avoid
// faulty DSS cache
const pageSizeMultiple = 10000;

export const getAccountEntitlements = async (): Promise<AccountEntitlement[]> => {
  const sessionInfo: SessionInfo = await session.getSessionInfo();

  return sessionInfo.entitlements;
};

export const getAiringsByGamePk = async (gamePk: string): Promise<Airing[]> => {
  const {
    persistedQueryIds: { byGamePk },
    queryContext: { searchPersisted },
  } = sdkConfig;

  const persistedQuery = new GraphQlPersistedQuery(byGamePk, {
    applyEsniMediaRightsLabels: true,
    gamePk,
    // use math random to bypass caching
    pageSize: pageSizeForAiringsByGamePk + Math.ceil(Math.random() * pageSizeMultiple),
  });
  const contentQuery = new ContentQuery(searchPersisted, persistedQuery);

  const contentData: ContentApiAiringsData = await session.contentApi.query({
    query: contentQuery,
  });

  return contentData.data.query.hits.map(({ hit }) => hit);
};

export const createPlaybackSession = (playerAdapter: any) => {
  const { mediaApi } = session;
  return mediaApi.createPlaybackSession(playerAdapter);
};

export const fetchMediaItem = async (mediaDescriptor: MediaDescriptorContext) => {
  const { mediaApi } = session;

  return mediaApi.fetch({
    mediaDescriptor,
    preferredPlaylistType: COMPLETE_PLAYLIST_TYPE,
  });
};

export const createMediaDescriptor = (playbackUrl: string) =>
  new SDK.Media.MediaDescriptor({
    playbackUrl,
  });

export const getSessionInfo = async () => session.getSessionInfo();

export const resetSession = async () => session.reset();
