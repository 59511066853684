import api, { GET } from 'services/api';

import { PlaylistTopicValues } from './constants';
import { PlaylistApiResponse } from './types';

export async function fetchPlaylistByTopic(topic: PlaylistTopicValues, teamId: number) {
  const queryParams = { teamId: `${teamId}`, topic };

  try {
    const response = await api<PlaylistApiResponse>(GET, {
      endpoint: 'byTopic',
      queryParams,
      service: 'playlist',
    });

    return response.data;
  } catch (error) {
    console.error('Could not fetch playlist', error);
    throw error;
  }
}
