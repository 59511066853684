import { MatchupData, MatchupResponse } from './types';

const nullStats = {
  atBats: 0,
  avg: null,
  era: null,
  hits: 0,
  inningsPitched: null,
  numberOfPitches: 0,
  ops: null,
};

export const formatMatchupData = ({ dates }: MatchupResponse): MatchupData => {
  const { games } = dates[0];
  const {
    gamePk,
    linescore: { defense, offense },
  } = games[0];

  const gamePitchingStats = defense?.pitcher?.stats?.find(
    ({ group, type }) => type.displayName === 'gameLog' && group.displayName === 'pitching',
  ) ?? {
    stats: nullStats,
  };

  const seasonPitchingStats = defense?.pitcher?.stats?.find(
    ({ group, type }) =>
      type.displayName === 'statsSingleSeason' && group.displayName === 'pitching',
  ) ?? {
    stats: nullStats,
  };

  const gameHittingStats = offense?.batter?.stats?.find(
    ({ group, type }) => type.displayName === 'gameLog' && group.displayName === 'hitting',
  ) ?? {
    stats: nullStats,
  };

  const seasonHittingStats = offense?.batter?.stats?.find(
    ({ group, type }) =>
      type.displayName === 'statsSingleSeason' && group.displayName === 'hitting',
  ) ?? {
    stats: nullStats,
  };

  const matchupData = {
    batter: {
      firstName: offense?.batter?.firstName ?? '',
      id: offense?.batter?.id ?? 0,
      lastName: offense?.batter?.lastName ?? '-',
      stats: {
        atBats: gameHittingStats?.stats?.atBats ?? 0,
        avg: seasonHittingStats?.stats?.avg ?? null,
        era: null,
        hits: gameHittingStats?.stats?.hits ?? 0,
        inningsPitched: null,
        numberOfPitches: null,
        ops: seasonHittingStats?.stats?.ops ?? null,
      },
      teamId: offense?.team?.id ?? 0,
    },
    gamePk,
    pitcher: {
      firstName: defense?.pitcher?.firstName ?? '',
      id: defense?.pitcher?.id ?? 0,
      lastName: defense?.pitcher?.lastName ?? '',
      stats: {
        atBats: 0,
        avg: null,
        era: seasonPitchingStats?.stats?.era ?? null,
        hits: 0,
        inningsPitched: gamePitchingStats?.stats?.inningsPitched ?? null,
        numberOfPitches: gamePitchingStats?.stats?.numberOfPitches ?? 0,
        ops: null,
      },
      teamId: defense?.team?.id ?? 0,
    },
  };

  return matchupData;
};
