import AppletvIcon from 'assets/partner/appletv.svg?react';
import PeacockIcon from 'assets/partner/peacock.svg?react';
import YoutubeIcon from 'assets/partner/youtube-dark.svg?react';
import {
  BLACKOUT_TITLE,
  BlackoutCodes,
  PARTNER_TITLE,
  YOUTUBE_TITLE,
} from 'constants/feedSelectModal';
import { EpgVideoFeed } from 'store/epg';
import { isAppleTvGame, isPeacockGame, isYoutubeGame } from 'utils/gameData';

const { NATIONAL_BLACKOUT_CODE, RSN_BLACKOUT_CODE } = BlackoutCodes;

export const isGameBlackout = (videoStatusCodes: number[], blackoutCode: number): boolean =>
  videoStatusCodes.includes(blackoutCode);

interface GetBlackoutInfoProps {
  messages: {
    appleTvMessage: string;
    nationalBlackoutMessage: string;
    peacockMessage: string;
    rsnBlackoutMessage: string;
    youtubeMessage: string;
  };
  videoFeeds: EpgVideoFeed[];
  videoStatusCodes: number[];
}

interface GetBlackoutInfoResult {
  Icon?: typeof AppletvIcon;
  message: string;
  title: string;
}

export const getBlackoutInfo = ({
  messages,
  videoFeeds,
  videoStatusCodes,
}: GetBlackoutInfoProps): GetBlackoutInfoResult => {
  const {
    appleTvMessage,
    nationalBlackoutMessage,
    peacockMessage,
    rsnBlackoutMessage,
    youtubeMessage,
  } = messages;
  const nationalBlackout = isGameBlackout(videoStatusCodes, NATIONAL_BLACKOUT_CODE);
  const rsnBlackout = isGameBlackout(videoStatusCodes, RSN_BLACKOUT_CODE);
  const appleTvGame = isAppleTvGame(videoFeeds);
  const peacockGame = isPeacockGame(videoFeeds);
  const youtubeGame = isYoutubeGame(videoFeeds);

  switch (true) {
    case appleTvGame:
      return {
        Icon: AppletvIcon,
        message: appleTvMessage,
        title: PARTNER_TITLE,
      };

    case peacockGame:
      return {
        Icon: PeacockIcon,
        message: peacockMessage,
        title: PARTNER_TITLE,
      };

    case youtubeGame:
      return {
        Icon: YoutubeIcon,
        message: youtubeMessage,
        title: YOUTUBE_TITLE,
      };

    case nationalBlackout:
      return { message: nationalBlackoutMessage, title: BLACKOUT_TITLE };

    case rsnBlackout:
    default:
      return { message: rsnBlackoutMessage, title: BLACKOUT_TITLE };
  }
};
