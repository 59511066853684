import { setThrottle } from '@noriginmedia/norigin-spatial-navigation';
import { DEFAULT_SPATIAL_NAV_SETTINGS } from 'constants/focus';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SmoothScrollerContainer } from './SmoothScroller.styles';

export type ISmoothScrollerContext = Dispatch<SetStateAction<number>>;

export const SmoothScrollerContext = createContext<ISmoothScrollerContext>(
  {} as ISmoothScrollerContext,
);

export interface SmoothScrollerProps {
  children: ReactNode;
}

export function SmoothScrollerProvider({ children }: SmoothScrollerProps) {
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    // Reset the throttle behavior when the page changes
    setThrottle(DEFAULT_SPATIAL_NAV_SETTINGS);
    // Ensures that page always starts at the top
    window.scroll({ behavior: 'instant' as ScrollBehavior, left: 0, top: 0 });
  }, []);

  return (
    <SmoothScrollerContext.Provider value={setY}>
      <SmoothScrollerContainer style={{ transform: `translateY(${-y}px)` }}>
        {children}
      </SmoothScrollerContainer>
    </SmoothScrollerContext.Provider>
  );
}

export function useSmoothScrollerContext() {
  return useContext(SmoothScrollerContext);
}
