import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { feedSelectIds } from 'constants/testIds';
import { selectStrings } from 'store/config';
import { useAppSelector } from 'store/hooks';

import {
  NoFeedsContainer,
  NoFeedsText,
  NoFeedsTextContainer,
  NoFeedsTitle,
  SignalTower,
} from './NoFeeds.styles';

const { NO_FEEDS, SIGNAL_TOWER } = feedSelectIds;

export function NoFeeds() {
  const {
    games: {
      noFeeds: { primaryText, secondaryText, titleText },
    },
  } = useAppSelector(selectStrings);
  const { ref } = useFocusable();

  return (
    <NoFeedsContainer ref={ref} data-testid={NO_FEEDS}>
      <SignalTower data-testid={SIGNAL_TOWER} />
      <NoFeedsTitle>{titleText}</NoFeedsTitle>
      <NoFeedsTextContainer>
        <NoFeedsText>{primaryText}</NoFeedsText>
        <NoFeedsText>{secondaryText}</NoFeedsText>
      </NoFeedsTextContainer>
    </NoFeedsContainer>
  );
}
