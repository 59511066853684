import { OfferGroup, PaywallConfig, PaywallConfigResponse, PaywallPackage } from 'store/paywall';

export const normalizePackage = (rawPackage: PaywallPackage): PaywallPackage => {
  const { buttons: rawButtons } = rawPackage;
  return {
    ...rawPackage,
    buttons: rawButtons.filter(({ enabled }) => enabled),
  };
};

export const normalizePackages = (packages: PaywallPackage[]) => {
  const normalizedPackages = packages.filter(({ enabled }) => enabled).map(normalizePackage);

  return normalizedPackages;
};

export const normalizeOfferGroups = (offerGroups: OfferGroup[]) =>
  offerGroups.map((offerGroup) => ({
    ...offerGroup,
    packages: normalizePackages(offerGroup.packages),
  }));

export const normalizePaywallConfig = (paywallConfigData: PaywallConfigResponse): PaywallConfig => {
  const platformPaywallData = paywallConfigData[__PAYWALL_PLATFORM__];
  const { offerGroups } = platformPaywallData;

  return {
    ...platformPaywallData,
    offerGroups: normalizeOfferGroups(offerGroups),
  };
};
