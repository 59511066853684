import { FocusableComponentLayout } from '@noriginmedia/norigin-spatial-navigation';

export const getNodeCenterYPosition = (layout: FocusableComponentLayout): number | null => {
  const { height, node, top } = layout;

  // Do not perform any scrolling if the element is in a modal
  if (node.offsetParent?.role === 'dialog') {
    return null;
  }

  // Do not perform any scrolling if the parent container is not scrollable
  if (!node.offsetParent || node.offsetParent.scrollHeight <= window.innerHeight) {
    return null;
  }

  const center = top - window.innerHeight / 2 + height / 2;

  const threshold = window.innerHeight / 3;
  // if item is in the top third of the screen scroll to the top
  if (center < threshold) return 0;

  return center;
};
