import { PREVIEW } from 'constants/gameState';
import { EpgPitcherApi } from 'store/epg';
import { isGameCancelled, isGameForfeited, isGamePostponed } from 'utils/gameData';

import { APPLE_TV, BLACKOUT, FREE_GAME_OF_THE_DAY, PEACOCK, YOUTUBE } from './constants';

export interface AdobeGameClickActionParam {
  blackedOutVideo: boolean;
  isAppleTv: boolean;
  isFreeGameOfTheDay: boolean;
  isPeacock: boolean;
  isYoutube: boolean;
}

export const getAdobeClickActionOption = ({
  blackedOutVideo,
  isAppleTv,
  isFreeGameOfTheDay,
  isPeacock,
  isYoutube,
}: AdobeGameClickActionParam) => {
  if (blackedOutVideo) {
    if (isAppleTv) return APPLE_TV;
    if (isPeacock) return PEACOCK;
    if (isYoutube) return YOUTUBE;

    return BLACKOUT;
  }

  if (isFreeGameOfTheDay) return FREE_GAME_OF_THE_DAY;

  return null;
};

export const shouldHideGameScore = ({
  abstractGameState,
  detailedState,
  hideSpoilers,
}: {
  abstractGameState: string;
  detailedState: string;
  hideSpoilers: boolean;
}) => {
  const showForCancelled = isGameCancelled(detailedState);
  const showForForfeit = isGameForfeited(detailedState);
  const showForPostponed = isGamePostponed(detailedState);
  const showForPreview = abstractGameState === PREVIEW;

  return showForCancelled || showForForfeit || showForPostponed || showForPreview || hideSpoilers;
};

export const shouldShowMessagePills = (
  focused: boolean,
  savingPitcher: EpgPitcherApi | null,
): boolean => !focused || (focused && !savingPitcher);
