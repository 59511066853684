import { msToSeconds } from 'utils/time';

interface CalculatePlayTimeProps {
  LIVE_POINT_OFFSET: number;
  LIVE_SEEK_TIME_OFFSET: number;
  seekTime: number;
  totalTime: number;
}

export const decrementSeekRate = (currentFFRate: number) => {
  switch (currentFFRate) {
    case -32: {
      return -32;
    }
    case -16: {
      return -32;
    }
    case -8: {
      return -16;
    }
    case -4: {
      return -8;
    }
    case -2: {
      return -4;
    }
    case 1: {
      return -2;
    }
    case 2: {
      return -2;
    }
    case 4: {
      return 2;
    }
    case 8: {
      return 4;
    }
    case 16: {
      return 8;
    }
    case 32: {
      return 16;
    }
    default:
      return 1;
  }
};

export const incrementSeekRate = (currentFFRate: number) => {
  switch (currentFFRate) {
    case -32: {
      return -16;
    }
    case -16: {
      return -8;
    }
    case -8: {
      return -4;
    }
    case -4: {
      return -2;
    }
    case -2: {
      return 2;
    }
    case 1: {
      return 2;
    }
    case 2: {
      return 4;
    }
    case 4: {
      return 8;
    }
    case 8: {
      return 16;
    }
    case 16: {
      return 32;
    }
    case 32: {
      return 32;
    }
    default:
      return 1;
  }
};

export const selectSeekSpeedInterval = (speedMultiplier: number): number => {
  // Speed is the multiplier squared
  switch (speedMultiplier) {
    case -32:
      return -1024;
    case -16:
      return -256;
    case -8:
      return -64;
    case -4:
      return -8;
    case -2:
      return -2;
    case 2:
      return 2;
    case 4:
      return 8;
    case 8:
      return 64;
    case 16:
      return 256;
    case 32:
      return 1024;
    default:
      return 1;
  }
};

export const calculatePlayTime = ({
  LIVE_POINT_OFFSET,
  LIVE_SEEK_TIME_OFFSET,
  seekTime,
  totalTime,
}: CalculatePlayTimeProps) => {
  // If seek point >= total time for a game, set play time to an offset of totalTime
  const seekTimeExceedsOrEqualsTotalTime = seekTime >= totalTime;

  // overshoot offset to prevent flickering of live indicator
  const seekOffsetSeconds = msToSeconds(LIVE_POINT_OFFSET) - msToSeconds(LIVE_SEEK_TIME_OFFSET);
  const livePointBufferTime = totalTime - seekOffsetSeconds;

  return seekTimeExceedsOrEqualsTotalTime ? livePointBufferTime : seekTime;
};
