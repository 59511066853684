import styled from 'styled-components';

interface BlackoutMessageContainerProps {
  $isPartnerMessage: boolean;
}

export const BlackoutMessageContainer = styled.div<BlackoutMessageContainerProps>`
  padding: ${({ theme }) => theme.spacing[16]};

  ${({ $isPartnerMessage }) =>
    $isPartnerMessage &&
    `
    align-items: center;
    display: flex;
    flex-direction: column;
  `};
`;

interface BlackoutTitleProps {
  $isAppleTv: boolean;
}

export const BlackoutTitle = styled.h1<BlackoutTitleProps>`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  margin-right: ${({ theme }) => theme.spacing[3]};
  margin-top: ${({ $isAppleTv, theme }) => ($isAppleTv ? theme.spacing[2] : 0)};
`;

export const BlackoutDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 3rem;
`;

export const BlackoutHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;
