import { buildAdobeGameMenuOptionClickParams } from 'components/video/GameMenu/GameMenuOptions/utils';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectFguid, selectMlbTrackingData, selectSelectedVideoFeed } from 'store/selectedVideo';

export const useAdobeGameMenuParams = (text: string) => {
  const selectedVideoFeed = useAppSelector(selectSelectedVideoFeed);
  const fguid = useAppSelector(selectFguid);
  const mlbTrackingData = useAppSelector(selectMlbTrackingData);

  return useMemo(
    () =>
      buildAdobeGameMenuOptionClickParams({
        assetName: mlbTrackingData?.assetName,
        feed: selectedVideoFeed,
        feedName: selectedVideoFeed?.callSign ?? '',
        fguid,
        gameMatchup: '', // TODO: PS5TV-772 - Figure out why getting this value makes the GameMenuButtons laggy
        text,
      }),
    [fguid, mlbTrackingData?.assetName, selectedVideoFeed, text],
  );
};
