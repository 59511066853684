import { DeviceSession, Experience } from '@mlbtv-clients/services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccountEntitlement,
  authorizeSession,
  getAccountEntitlements,
  getSessionInfo,
} from 'experience/DSS';
import { MLBExperience } from 'experience/MLB';
import { fetchEntitlementToken } from 'services/entitlement';
import { AppDispatch, GetState, RootState } from 'store';
import { selectDevExperience } from 'store/app';
import { selectFeatureFlags } from 'store/config';
import { selectAccessToken } from 'store/mlbAuth';
import { isNonProdBuild } from 'utils/env';

import { setExperience } from './experienceSlice';

const { LEGACY, MLB } = Experience;

export const getExperienceFlow = () => async (dispatch: AppDispatch, getState: GetState) => {
  const devExperience = selectDevExperience(getState());
  const { hasMLB, hasTrafficSplitting } = selectFeatureFlags(getState()).experience;

  const fallbackExperience = hasMLB ? MLB : LEGACY;

  try {
    let experience;
    if (isNonProdBuild() && devExperience) {
      experience = devExperience;
    } else {
      experience = hasTrafficSplitting
        ? await MLBExperience.requestExperience()
        : fallbackExperience;
    }

    await dispatch(setExperience(experience));

    return experience;
  } catch (e) {
    console.error('Get Experience Failure: ', e);
    return fallbackExperience;
  }
};

export const authorizeDSSSessionFlow = createAsyncThunk<
  { deviceId: string; entitlements: AccountEntitlement[] },
  undefined,
  { state: RootState }
>('experience/authorizeDSSSession', async (_, { getState }) => {
  const state = getState();
  const {
    device: { id },
  } = await getSessionInfo();
  const accessToken = selectAccessToken(state);

  const { data } = await fetchEntitlementToken(accessToken, id);
  await authorizeSession(data);

  const entitlements = await getAccountEntitlements();

  return { deviceId: id, entitlements };
});

export const registerMLBSessionFlow = createAsyncThunk<
  DeviceSession,
  undefined,
  { state: RootState }
>('experience/registerMLBSession', async () => {
  const response = await MLBExperience.registerDevice();

  return response;
});
