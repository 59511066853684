import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { selectDatesConfig, selectFeatureFlags } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { yearMonthDay } from 'utils/date';

export const useDateFromURL = () => {
  const { hasCalendarLoadDate } = useAppSelector(selectFeatureFlags);
  const { calendarLoadDate } = useAppSelector(selectDatesConfig);
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFromURL = searchParams.get('date');

  const date = useMemo(
    () =>
      dateFromURL
        ? new Date(`${dateFromURL}T00:00:00`)
        : hasCalendarLoadDate
          ? new Date(`${calendarLoadDate}T00:00:00`)
          : new Date(),
    [calendarLoadDate, dateFromURL, hasCalendarLoadDate],
  );

  const setDate = useCallback(
    (nextDate: Date) => {
      const params = new URLSearchParams({ date: yearMonthDay(nextDate) });
      setSearchParams(params);
    },
    [setSearchParams],
  );

  return { date, setDate };
};
