export const getEnvFromHost = () => {
  if (__APP_ENV__) return __APP_ENV__;
  const { host } = window.location;
  if (host.includes('dev') || host.includes('local') || host.includes('webstream')) return 'dev';
  if (host.includes('qa')) return 'qa';
  if (host.includes('beta') || host.includes('stage')) return 'stage';
  return 'prod';
};

export const isNonProdBuild = () => ['dev', 'qa', 'stage'].includes(getEnvFromHost());
