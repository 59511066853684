import Hls from 'hls.js';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export interface IPlayerContext {
  convivaAdStreamManager: any | null;
  destroyConvivaAdStreamManager: () => void;
  destroyPlayer: () => void;
  getCurrentTime: () => number;
  getDuration: () => number;
  handleConvivaAdStreamManager: (streamManager: any) => void;
  handleNativePlayer: (hls: Hls, video: HTMLVideoElement) => void;
  hls: Hls | null;
  seekTo: (time: number) => void;
  video: HTMLVideoElement | null;
}

export const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

interface PlayerProviderProps {
  children: ReactNode;
}

export function PlayerProvider({ children }: PlayerProviderProps) {
  const [hls, setHls] = useState<Hls | null>(null);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [convivaAdStreamManager, setConvivaAdStreamManager] = useState<any>(null);

  const destroyConvivaAdStreamManager = useCallback(() => {
    setConvivaAdStreamManager(null);
  }, []);

  const destroyPlayer = useCallback(async () => {
    if (hls?.media) {
      try {
        hls.destroy();
      } catch (error) {
        console.error('Could not properly destroy player');
        throw error;
      }
    }
  }, [hls]);

  const handleConvivaAdStreamManager = useCallback((streamManger: any) => {
    setConvivaAdStreamManager(streamManger);
  }, []);

  const handleNativePlayer = useCallback((hlsInstance: Hls, videoElement: HTMLVideoElement) => {
    setHls(hlsInstance);
    setVideo(videoElement);
  }, []);

  const getCurrentTime = useCallback(
    () => (video?.currentTime ? Math.floor(video.currentTime) : 0),
    [video],
  );

  const getDuration = useCallback(() => video?.duration ?? 0, [video]);

  const seekTo = useCallback(
    (time: number) => {
      if (video) {
        video.currentTime = time;
      }
    },
    [video],
  );

  const value = useMemo(
    () => ({
      convivaAdStreamManager,
      destroyConvivaAdStreamManager,
      destroyPlayer,
      getCurrentTime,
      getDuration,
      handleConvivaAdStreamManager,
      handleNativePlayer,
      hls,
      seekTo,
      video,
    }),
    [
      convivaAdStreamManager,
      destroyConvivaAdStreamManager,
      handleConvivaAdStreamManager,
      destroyPlayer,
      getCurrentTime,
      getDuration,
      handleNativePlayer,
      hls,
      seekTo,
      video,
    ],
  );
  return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
}

export function usePlayerContext() {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error('usePlayerContext must be used within a PlayerProvider');
  }

  return context;
}
