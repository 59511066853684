export const ADHOC = 'ADHOC';
export const ARCHIVE = 'ARCHIVE';
export const ARCHIVE_CATEGORY_TYPE = 'Archive Game';
export const ARCHIVE_CONTENT_TYPE = 'Archive';
export const LIVE = 'LIVE';
export const LIVE_CATEGORY_TYPE = 'Live Game';
export const LIVE_CONTENT_TYPE = 'Live';
export const MEDIA_AUDIO = 'audio';
export const MEDIA_VIDEO = 'video';
export const MISSING_CUSTOMER_KEY = 'Missing customer key';
export const MSDK_WIRED = 0;
export const MSDK_WIRELESS = 1;
export const NA = 'NA';
export const NO_ASSET_NAME = 'no asset name';
export const PARTNER_TAG = 'mlb';
export const PLAYBACK_SCENARIO = 'hlsCloud';
export const PLAYSTATION = 'playstation';
export const PLAYSTATION_5 = 'PlayStation 5';
export const PRODUCT_TYPE_GAME = 'game';
export const PRODUCT_TYPE_NON_GAME = 'non-game';
export const SLIVE = 'SLIVE';
export const SVOD = 'SVOD';
export const VOD = 'VOD';
export const WIRED = 'WIRED';
export const WIRELESS = 'WIRELESS';

export type ForgeVideoContentType = typeof ADHOC | typeof SLIVE | typeof SVOD | typeof VOD;

export enum CONVIVA_VIDEO_TYPE {
  FORGE = 'FORGE',
  GAME = 'GAME',
}
