import styled from 'styled-components';
import { boxScoreMatchupColors } from 'styles/colors';

const { matchupHeaderColor, matchupPlayerTextColor } = boxScoreMatchupColors;

export const MatchupContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
`;

export const MatchupSplitContainer = styled.div`
  align-items: center;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing[3]};
  width: 50%;
`;

export const MatchupPlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing[5]};
  width: 100%;
`;

export const MatchupPlayerHeader = styled.p`
  color: ${matchupHeaderColor};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const MatchupPlayerInfoContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing[3]};
`;

export const MatchupPlayerName = styled.p`
  color: ${matchupPlayerTextColor};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight[900]};
  height: 3.3rem;
  line-height: ${({ theme }) => theme.spacing[8]};
  max-width: 27.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MatchupPlayerStatGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing[1]};
  width: 26.4rem;
`;

interface MatchupPlayerStatProps {
  $isAvg?: boolean;
  $isIP?: boolean;
  $isPitches?: boolean;
}

export const MatchupPlayerStat = styled.div<MatchupPlayerStatProps>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ $isPitches, theme }) => ($isPitches ? theme.spacing[3] : theme.spacing[2])};
  width: ${({ $isAvg, $isIP, $isPitches }) => {
    switch (true) {
      case $isAvg:
        return '5.1rem';
      case $isIP:
        return '3.9rem';
      case $isPitches:
        return '7.5rem';
      default:
        return '6.3rem';
    }
  }};
`;

export const MatchupPlayerStatInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[900]};
  padding-bottom: ${({ theme }) => theme.spacing[2]};
  padding-top: ${({ theme }) => theme.spacing[1]};
`;

export const MatchupPlayerStatTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight[900]};
`;

export const MatchupStatDividerLine = styled.div`
  border: 0.15rem solid ${matchupPlayerTextColor};
  height: 6rem;
  margin-right: ${({ theme }) => theme.spacing[3]};
`;

export const SpinnerContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[28]};
  width: 50%;
`;
