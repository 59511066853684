import { NAVIGATION_KEY_MAP } from './controllerKeymap';
import { ButtonBehavior } from './buttons';

export type ButtonListener = (e: KeyboardEvent) => Promise<void> | void;

export type ButtonEvent = {
  enabled?: boolean;
  event: ButtonListener;
};

export type ButtonEvents = Record<ButtonBehavior, ButtonListener | ButtonEvent>;

type KeyEvents = Record<number, ButtonListener | ButtonEvent>;

export class ControllerManager {
  private keyEvents: Partial<KeyEvents> = {};

  constructor(buttonEvents = {} as Partial<ButtonEvents>) {
    this.addButtonEvents(buttonEvents);
  }

  addButtonEvents(events: Partial<ButtonEvents>) {
    Object.keys(events).forEach((value: string) => {
      const button = value as ButtonBehavior;
      const event = events[button];
      if (event) {
        Object.values(NAVIGATION_KEY_MAP[button]).forEach(
          (keyCode) => (this.keyEvents[keyCode] = event),
        );
      }
    });
  }

  eventHandler = async (e: KeyboardEvent) => {
    const keyCode = e.keyCode;
    if (this.keyEvents[keyCode]) {
      const buttonEvent = this.keyEvents[keyCode];

      if (typeof buttonEvent === 'function') {
        buttonEvent(e);
        return;
      }

      const { enabled, event } = buttonEvent as ButtonEvent;
      if (typeof enabled !== 'undefined') {
        if (enabled) await event(e);
      } else {
        await event(e);
      }
    }
  };

  removeButtonEvents(buttons: ButtonBehavior[]) {
    buttons.forEach((button) =>
      NAVIGATION_KEY_MAP[button].forEach((b) => delete this.keyEvents[b]),
    );
  }

  // Initializes the event listener
  subscribe() {
    window.addEventListener('keyup', this.eventHandler);
  }

  // Should be called in useEffect cleanups to prevent memory leaks
  unsubscribe() {
    window.removeEventListener('keyup', this.eventHandler);
  }
}
