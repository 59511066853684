import { AxiosError } from 'axios';
import {
  fetchCarouselData,
  fetchFeaturedItemData,
  ForgePlaylistApi,
  ForgeVideoData,
  FormattedContentApiResponse,
} from 'services/forge';
import {
  fetchPlaylistByTopic,
  PlaylistApiItem,
  PlaylistTopicValues,
  PlaylistType,
} from 'services/playlist';
import { RootState } from 'store';
import { apiSlice } from 'store/apiSlice';

export const emptyForgeVideo: ForgeVideoData = {
  blurb: '',
  contentDate: '',
  description: '',
  duration: 0,
  heading: '',
  mediaPlaybackId: '',
  slug: '',
  tags: [],
  text: '',
  thumbnail: {},
  title: '',
  url: '',
};

export interface FeaturedContentByTopicData {
  carousels: PlaylistApiItem[];
  featured: ForgeVideoData;
  gamesCarousel: ForgePlaylistApi | undefined;
}

export const forgeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCarousel: builder.query<FormattedContentApiResponse, { title: string; url: string }>({
      keepUnusedDataFor: 0,
      merge: (currentCache, newEntry) => {
        const { items, pagination } = newEntry;
        currentCache.items.push(...items);
        currentCache.pagination = pagination;
      },
      providesTags: ['Carousel'],
      queryFn: async ({ url }) => {
        try {
          const data = await fetchCarouselData(url);
          return { data };
        } catch (error) {
          if (error instanceof AxiosError) {
            const { code, message, name, response } = error;
            return {
              error: { code, message, name, status: response?.status },
            };
          }

          return { error: error?.message || 'Could not fetch carousel data' };
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { title } = queryArgs;
        return `${endpointName}("${title}")`;
      },
    }),
    fetchForgeContentByTopic: builder.query<FeaturedContentByTopicData, PlaylistTopicValues>({
      keepUnusedDataFor: 3600, // Forge data updates every hour
      queryFn: async (topic, { getState }) => {
        try {
          const {
            profile: { favoriteTeam },
          } = getState() as RootState;

          const playlistData = await fetchPlaylistByTopic(topic, favoriteTeam);

          const featuredUrl = playlistData.featuredContent[0]?.url ?? '';
          const featured = featuredUrl ? await fetchFeaturedItemData(featuredUrl) : emptyForgeVideo;

          const carousels = playlistData.items.filter(
            ({ type, url }) => !!url && type !== PlaylistType.ARCHIVE,
          );
          const gamesCarousel = playlistData.items.find(({ type }) => type === PlaylistType.GAME);

          return { data: { carousels, featured, gamesCarousel } };
        } catch (error) {
          if (error instanceof AxiosError) {
            const { code, message, name, response } = error;
            return {
              error: { code, message, name, status: response?.status },
            };
          }

          return {
            error: error?.message || 'Could not fetch carousels and featured content',
          };
        }
      },
    }),
  }),
});

export const {
  useFetchCarouselQuery,
  useFetchForgeContentByTopicQuery,
  useLazyFetchCarouselQuery,
} = forgeApiSlice;

// Selectors
export const selectFeaturedDataByTopic = (state: RootState, topic: PlaylistTopicValues) =>
  forgeApiSlice.endpoints.fetchForgeContentByTopic.select(topic)(state)?.data?.featured ??
  emptyForgeVideo;
