import { utteranceFromIdReferenceList } from './utteranceFromIdReferenceList';

/**
 * Returns string of text collected from
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby aria-labelledby} or
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label aria-label}
 */

export const utteranceFromElementLabel = (element: Element) => {
  const labelledby = element.getAttribute('aria-labelledby');

  if (labelledby) {
    return utteranceFromIdReferenceList(labelledby);
  }

  return element.getAttribute('aria-label') || '';
};
