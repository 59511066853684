import { ForgeVideoData, ForgeVideoType, Tag } from 'services/forge';

const { AD_HOC_STREAM, MLBN, SLIVE, SVOD } = ForgeVideoType;

export function doesForgeContentRequireSubscriberEntitlement(forgeData: ForgeVideoData): boolean {
  return isSlugInTags(forgeData.tags, [MLBN, SVOD, SLIVE]);
}

export function isForgeContentLive(vodData: ForgeVideoData) {
  return isSlugInTags(vodData.tags, [AD_HOC_STREAM, MLBN, SLIVE]);
}

export function isSlugInTags(tags: Tag[], slugs: string[]) {
  const upperCaseSlugs = slugs.map((s) => s.toUpperCase());
  return tags.some((tag) => upperCaseSlugs.includes(tag.slug.toUpperCase()));
}
