import api, { GET } from 'services/api';

import { formatLinescoreData } from './normalizers';
import { Linescore, LinescoreResponse } from './types';

export const fetchLinescore = async (
  gamePk: string | null,
  timecode: string,
): Promise<Linescore> => {
  const response = await api<LinescoreResponse>(GET, {
    endpoint: 'linescore',
    queryParams: {
      gamePk: gamePk ?? '',
      timecode,
    },
    service: 'statsapi',
  });

  return formatLinescoreData(response.data);
};
