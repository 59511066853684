import SDK from 'bam-browser-sdk/dist/browser_browser_es5/bam-browser-sdk';
import Hls from 'hls.js';
import { GET } from 'services/api';

export interface PlayerAdapterOptions {
  nativePlayer: Hls;
  videoPlayerName: string;
  videoPlayerVersion: string;
}

export class CustomPlayerAdapter extends SDK.Media.PlayerAdapter.HlsJsPlayerAdapter {
  //  mediaElement needs to be present when calling playbackSession.release(), or the release method will throw
  attachMedia(videoElement: HTMLVideoElement) {
    this.mediaElement = videoElement;
  }

  /**
   *
   * @access private
   * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
   * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
   *
   */
  setupXhrCallback() {
    const { accessToken, nativePlayer } = this;

    nativePlayer.config.xhrSetup = (xhr: XMLHttpRequest, url: string, type: string) => {
      const DAI_DOMAIN_PATTERN = /(truman-.*\.sandbox.google.com)|(dai.google.com)/;

      // Send cookies with the request?
      xhr.withCredentials = DAI_DOMAIN_PATTERN.test(url);

      /*
            const HlsDataTypes = {
            MANIFEST:  'MANIFEST', //.m3u8 files
            CHUNK:     'CHUNK',    //.ts files
            KEY:       'KEY',      //AES key files
            };
            */
      let addAuthHeader;

      if (type) {
        addAuthHeader = type === 'KEY';
      } else {
        addAuthHeader =
          url &&
          (url.indexOf('op-generateKey') > -1 ||
            url.indexOf('/keys/id/') > -1 ||
            url.indexOf('/silk/events/') > -1);
      }

      if (addAuthHeader) {
        // bam-hls v1.15 introduced a new sequence
        // whereupon we need to explicitly open the connection before adding headers
        // Wrap it in this IF to ensure backwards compatibility is maintained
        if (!xhr.readyState) {
          xhr.open(GET, url, true);
        }

        xhr.setRequestHeader('Authorization', accessToken);
      }
    };
  }
}
