import { useEffect, useMemo } from 'react';
import { ButtonEvents, ControllerManager } from 'utils/controller';

/*
 * Arguments can be passed in two different ways
 * 1. useController({ back: () => {} })
 * 2. useController({ back: { event: () => {}, enabled: true | false } })
 */

/*
 * The ControllerManager can also be used by destructuring it from the return value of the hook like so:
 * const { controller } =  useController({ back: () => {} })
 * You can then utilize the controller to add or remove events using the "addButtonEvents" or "removeButtonEvents" in your component like so:
 *  useEffect(() => {
 *    controller.addButtonEvents(events)
 *  }, [controller])
 */
export function useController(arg: Partial<ButtonEvents>) {
  const controller = useMemo(() => new ControllerManager(), []);
  useEffect(() => {
    controller.subscribe();

    return () => {
      controller.unsubscribe();
    };
  }, [controller]);

  useEffect(() => {
    controller.addButtonEvents(arg);
  }, [arg, controller]);

  return { controller };
}
