export const BEARER_TOKEN_MALFORMED = 'Bearer token is malformed';

export enum ContentField {
  AwayTeamId = 'AwayTeamId',
  ContentId = 'ContentId',
  ContentType = 'ContentType',
  GamePk = 'GamePk',
  HomeTeamId = 'HomeTeamId',
  MediaId = 'MediaId',
}
