import { HLS_CLOUD, HTTP_CLOUD_WIRED, HTTP_CLOUD_WIRED_60 } from 'constants/forge';
import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from 'constants/time';

import {
  ForgeContentApiItem,
  ForgeContentApiResponse,
  ForgeFeaturedApiItem,
  ForgeVideoData,
  PlaybackScenario,
} from './types';

// Converts a string in the format HH:MM:SS to a number in seconds
export const convertDurationStringToSeconds = (durationString: string | number): number => {
  if (typeof durationString === 'number') return durationString;
  const hourMinSecSplit = durationString.split(':').map(parseFloat);

  return (
    hourMinSecSplit[0] * MINUTES_IN_HOUR * SECONDS_IN_MINUTE +
    hourMinSecSplit[1] * SECONDS_IN_MINUTE +
    hourMinSecSplit[2]
  );
};

export const getForgeUrl = (playbackScenarios: PlaybackScenario[]): string => {
  const hlsVideoUrl = playbackScenarios.find(({ playback }) => playback === HLS_CLOUD);
  const httpVideoUrl = playbackScenarios.find(
    ({ playback }) => playback === HTTP_CLOUD_WIRED || playback === HTTP_CLOUD_WIRED_60,
  );

  if (hlsVideoUrl) return hlsVideoUrl?.location ?? '';

  return httpVideoUrl?.location ? `http:${httpVideoUrl.location}` : '';
};

export const hasCompleteVodData = (item: ForgeContentApiItem): boolean => {
  const { contentDate, fields, thumbnail, title } = item;
  if (!contentDate || !fields || !thumbnail || !title) return false;

  const { blurb, duration, mediaPlaybackId, playbackScenarios } = fields;
  if (!blurb || !duration || !mediaPlaybackId || !playbackScenarios) {
    return false;
  }

  const { thumbnailUrl } = thumbnail;
  if (!thumbnailUrl) {
    return false;
  }

  return true;
};

export const formatCarouselData = (carouselData: ForgeContentApiResponse) => ({
  ...carouselData,
  items: carouselData.items.filter(hasCompleteVodData).map(formatForgeVideo),
});

export const formatForgeVideo = (vod: ForgeContentApiItem): ForgeVideoData => {
  const {
    contentDate,
    fields: { blurb, description, duration, mediaPlaybackId, playbackScenarios },
    slug,
    tags,
    thumbnail: tn,
    title,
  } = vod;
  const url = getForgeUrl(playbackScenarios);
  const durationSeconds = convertDurationStringToSeconds(duration);

  const thumbnail = {
    templateUrl: tn?.templateUrl ?? '',
    thumbnailUrl: tn?.thumbnailUrl ?? '',
  };

  return {
    blurb,
    contentDate,
    description,
    duration: durationSeconds,
    mediaPlaybackId,
    slug,
    tags,
    thumbnail,
    title,
    url,
  };
};

export const formatFeaturedForgeVideo = (featuredItem: ForgeFeaturedApiItem): ForgeVideoData => {
  const error = new Error('incomplete featured forge data');
  if (!featuredItem.references) throw error;

  if (!featuredItem.references.video?.[0]) {
    throw error;
  }

  const {
    fields,
    references: { alternateThumbnail2, video },
    thumbnail,
  } = featuredItem;

  const { heading, text } = fields ?? {};
  const { contentDate, fields: embeddedFields, slug, tags } = video[0];

  const alternateTemplateUrl = alternateThumbnail2?.[0]?.image?.templateUrl;

  if (!contentDate || !embeddedFields || !slug || !thumbnail || !heading || !text || !tags)
    throw error;

  const { blurb, description, duration, mediaPlaybackId, playbackScenarios } = embeddedFields;

  const { templateUrl, thumbnailUrl } = thumbnail;

  if (
    !blurb ||
    !description ||
    !duration ||
    !mediaPlaybackId ||
    !playbackScenarios ||
    !templateUrl ||
    !thumbnailUrl
  ) {
    throw error;
  }

  const durationSeconds = convertDurationStringToSeconds(duration);
  const videoUrl = getForgeUrl(playbackScenarios);

  if (!videoUrl) throw error;

  return {
    blurb,
    contentDate,
    description,
    duration: durationSeconds,
    heading,
    mediaPlaybackId,
    slug,
    tags,
    text,
    thumbnail: {
      alternateTemplateUrl,
      templateUrl,
      thumbnailUrl,
    },
    url: videoUrl,
  };
};

export const formatForgeVideoFromSlug = (forgeVideo: ForgeContentApiItem): ForgeVideoData => {
  const error = new Error('incomplete forge video from slug data');

  if (!forgeVideo.fields) {
    throw error;
  }

  const { contentDate, fields, slug, tags, thumbnail, title } = forgeVideo;

  const { blurb, description, duration, mediaPlaybackId, playbackScenarios } = fields;

  const { templateUrl, thumbnailUrl } = thumbnail;

  if (
    !blurb ||
    !description ||
    !duration ||
    !mediaPlaybackId ||
    !playbackScenarios ||
    !templateUrl ||
    !thumbnailUrl
  ) {
    throw error;
  }

  const durationSeconds = convertDurationStringToSeconds(duration);
  const videoUrl = getForgeUrl(playbackScenarios);

  if (!videoUrl) throw error;

  return {
    blurb,
    contentDate,
    description,
    duration: durationSeconds,
    mediaPlaybackId,
    slug,
    tags,
    thumbnail,
    title,
    url: videoUrl,
  };
};
