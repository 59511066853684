import api, { GET } from 'services/api';
import { endOfMonth, monthDayYear, startOfMonth } from 'utils/date';

import {
  DayOfMonthData,
  FormattedPlayerData,
  MonthScheduleData,
  PlayerDataByDate,
  PlayerDataGame,
} from './types';

export const fetchPlayerDataByDate = async (date: string): Promise<FormattedPlayerData> => {
  const response = await api<PlayerDataByDate>(GET, {
    endpoint: 'playerDataByDate',
    queryParams: { date },
    service: 'statsapi',
  });
  const games = response.data.dates?.[0]?.games ?? [];

  const playerData = games
    .filter((game: PlayerDataGame) => !!game?.gamePk && !!game.linescore)
    .reduce((acc: FormattedPlayerData, game: PlayerDataGame) => {
      const {
        gamePk,
        linescore: { defense, offense },
      } = game;

      const avg =
        offense?.batter?.stats.find(({ type }) => type.displayName === 'statsSingleSeason')?.stats
          ?.avg ?? '.000';
      const batterId = offense?.batter?.id ?? 0;
      const batterTeamId = offense?.team?.id ?? 0;
      const pitcherId = defense?.pitcher?.id ?? 0;
      const pitcherTeamId = defense?.team?.id ?? 0;

      return {
        ...acc,
        [gamePk]: {
          batter: { avg, id: batterId, teamId: batterTeamId },
          pitcher: { id: pitcherId, teamId: pitcherTeamId },
        },
      };
    }, {});

  return playerData;
};

export const fetchScheduledGamesByMonth = async (dateString: string): Promise<DayOfMonthData[]> => {
  const date = new Date(dateString);

  const startOfMonthDate = monthDayYear(startOfMonth(date));
  const endOfMonthDate = monthDayYear(endOfMonth(date));

  const response = await api<MonthScheduleData>(GET, {
    endpoint: 'scheduledGamesByMonth',
    queryParams: { endOfMonthDate, startOfMonthDate },
    service: 'statsapi',
  });

  return response.data.dates;
};
