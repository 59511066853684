import { BaseModal } from 'components/modals/BaseModal';
import styled from 'styled-components';

export const Modal = styled(BaseModal)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize['5xl']};
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: ${({ theme }) => theme.spacing[14]};

  &:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
`;
