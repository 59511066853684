import styled from 'styled-components';

export const SecondaryFeedSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 50.1rem;
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[10]};
  }
`;
