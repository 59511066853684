import styled from 'styled-components';
import { gameMenuColors } from 'styles/colors';

const { peacockBackgroundColor } = gameMenuColors;

export const PeacockContainer = styled.div`
  background-color: ${peacockBackgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  padding: 0.9rem;
`;
