import styled from 'styled-components';
import { primaryButtonColors } from 'styles/colors';

const { backgroundColor, borderColor, color, focused } = primaryButtonColors;

export const BaseLink = styled.span`
  display: inline-block;
`;

export const ButtonLink = styled(BaseLink)`
  align-items: center;
  background-color: ${backgroundColor};
  border-color: ${borderColor};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border-style: solid;
  border-width: 0.3rem;
  color: ${color};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  justify-content: center;
  padding: 0.9rem 2.4rem;

  &.focused {
    background-color: ${focused.backgroundColor};
    border-color: ${focused.backgroundColor};
    color: ${focused.color};
  }
`;
