import api, { GET } from 'services/api';
import { PaywallConfig, PaywallConfigResponse } from 'store/paywall/types';

import { normalizePaywallConfig } from './normalizers';

export async function fetchPaywallConfig(): Promise<PaywallConfig> {
  try {
    const response = await api<PaywallConfigResponse>(GET, {
      endpoint: 'json',
      service: 'paywall',
    });
    return normalizePaywallConfig(response.data);
  } catch (error) {
    console.error('Could not fetch paywall config data', error);
    throw error;
  }
}
