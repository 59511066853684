import Conviva from '@convivainc/conviva-js-coresdk';
import {
  ADHOC,
  ARCHIVE_CATEGORY_TYPE,
  ARCHIVE_CONTENT_TYPE,
  ForgeVideoContentType,
  LIVE_CATEGORY_TYPE,
  LIVE_CONTENT_TYPE,
  MEDIA_VIDEO,
  NA,
  NO_ASSET_NAME,
  PARTNER_TAG,
  PLAYBACK_SCENARIO,
  PRODUCT_TYPE_GAME,
  PRODUCT_TYPE_NON_GAME,
  SLIVE,
  SVOD,
  VOD,
} from 'analytics/conviva';
import { LIVE_MLB_FEED } from 'constants/feeds';
import { Airing, BamSdkConvivaData, LIVE } from 'experience/DSS';
import { MlbTrackingData } from 'experience/MLB';
import { ForgeVideoData } from 'services/forge';
import { doesForgeContentRequireSubscriberEntitlement, isForgeContentLive } from 'utils/forge';
import { getClientType, getDeviceConnectionType } from 'utils/platform';

// eslint-disable-next-line no-template-curly-in-string
const customerKeyTemplate = '${customerKey}';
const playerName = `${__PLATFORM__.toUpperCase()} player`;

const {
  Constants: { ASSET_NAME, DURATION, IS_LIVE, PLAYER_NAME, STREAM_URL, StreamType, VIEWER_ID },
} = Conviva;

export const formatGameAssetName = (airing: Airing): string => {
  const { feedLanguage, feedType, mediaConfig, titles } = airing;
  const { descriptionShort, title } = titles[0];

  const formattedAssetName = `${
    title ?? descriptionShort
  } | ${feedType} | ${mediaConfig?.type} | ${feedLanguage}`;
  const assetName = !title && !descriptionShort ? NO_ASSET_NAME : formattedAssetName;

  return assetName;
};

interface FormatDSSGameContentMetadataParams {
  airing: Airing;
  bamSdkConvivaData: BamSdkConvivaData;
  duration: number;
  playbackUrl: string;
}

interface FormatMLBGameContentMetadataParams {
  duration: number;
  mlbTrackingData: MlbTrackingData;
  oktaId: string;
  playbackUrl: string;
}

export const formatMLBGameContentMetadata = ({
  duration,
  mlbTrackingData,
  oktaId,
  playbackUrl,
}: FormatMLBGameContentMetadataParams): Conviva.ConvivaMetadata => {
  const isLive = mlbTrackingData.isLive === LIVE_MLB_FEED;
  return {
    [ASSET_NAME]: mlbTrackingData.assetName,
    [DURATION]: duration,
    [IS_LIVE]: isLive ? StreamType.LIVE : StreamType.VOD,
    [PLAYER_NAME]: playerName,
    [STREAM_URL]: playbackUrl,
    [VIEWER_ID]: oktaId ?? NA,
  } as Conviva.ConvivaMetadata;
};

export const formatDSSGameContentMetadata = ({
  airing,
  bamSdkConvivaData,
  duration,
  playbackUrl,
}: FormatDSSGameContentMetadataParams): Conviva.ConvivaMetadata => {
  const isLive = airing.mediaConfig?.productType === LIVE;

  return {
    [ASSET_NAME]: formatGameAssetName(airing),
    [DURATION]: duration,
    [IS_LIVE]: isLive ? StreamType.LIVE : StreamType.VOD,
    [PLAYER_NAME]: playerName,
    [STREAM_URL]: playbackUrl,
    [VIEWER_ID]: bamSdkConvivaData.userid ?? NA,
  } as Conviva.ConvivaMetadata;
};

export const getDSSCustomGameMetadata = async (
  bamSdkConvivaData: BamSdkConvivaData,
): Promise<Conviva.ConvivaMetadata> => {
  const categoryType =
    bamSdkConvivaData.productType === VOD ? ARCHIVE_CATEGORY_TYPE : LIVE_CATEGORY_TYPE;
  const clientType = await getClientType();
  const streamType =
    bamSdkConvivaData.productType === VOD ? ARCHIVE_CONTENT_TYPE : LIVE_CONTENT_TYPE;

  return {
    categoryType,
    contentType: streamType,
    fguid: bamSdkConvivaData.fguid,
    locationName: bamSdkConvivaData.locationName,
    med: bamSdkConvivaData.med,
    pbs: bamSdkConvivaData.pbs,
    plt: clientType.toLowerCase(),
    productType: PRODUCT_TYPE_GAME,
    prt: bamSdkConvivaData.prt,
    state: bamSdkConvivaData.state,
    ver: __VERSION__,
  };
};

export const getMLBCustomGameMetadata = (
  mlbTrackingData: MlbTrackingData,
): Conviva.ConvivaMetadata => ({
  ...mlbTrackingData,
  ver: __VERSION__,
});

export const getConvivaGatewayUrl = (gatewayUrl: string, customerKey: string): string =>
  gatewayUrl.replace(customerKeyTemplate, customerKey);

export const getNetworkConnectionType = getDeviceConnectionType;

interface FormatForgeContentMetadataParams {
  duration: number;
  forgeData: ForgeVideoData;
  viewerId: string;
}

export const formatForgeContentMetadata = ({
  duration,
  forgeData,
  viewerId,
}: FormatForgeContentMetadataParams): Conviva.ConvivaMetadata => {
  const { slug, url } = forgeData;
  const isLive = isForgeContentLive(forgeData);

  return {
    [ASSET_NAME]: slug,
    [DURATION]: duration,
    [IS_LIVE]: isLive ? StreamType.LIVE : StreamType.VOD,
    [PLAYER_NAME]: playerName,
    [STREAM_URL]: url,
    [VIEWER_ID]: viewerId || NA,
  } as Conviva.ConvivaMetadata;
};

export function getContentTypeForForgeVideo(forgeData: ForgeVideoData): ForgeVideoContentType {
  const requiresSvodEntitlement = doesForgeContentRequireSubscriberEntitlement(forgeData);
  const isLive = isForgeContentLive(forgeData);

  switch (true) {
    case requiresSvodEntitlement && isLive:
      return SLIVE;
    case isLive:
      return ADHOC;
    case requiresSvodEntitlement:
      return SVOD;
    default:
      return VOD;
  }
}

interface GetCustomForgeMetadataParams {
  fguid?: string;
  forgeData: ForgeVideoData;
  userId: string;
}

export const getCustomForgeMetadata = async ({
  fguid = '',
  forgeData,
  userId,
}: GetCustomForgeMetadataParams): Promise<Conviva.ConvivaMetadata> => {
  const { tags } = forgeData;
  const categoryType = tags.map(({ title }) => title).join(', ');
  const clientType = await getClientType();
  const contentType = getContentTypeForForgeVideo(forgeData);

  return {
    categoryType,
    contentType,
    fguid,
    med: MEDIA_VIDEO,
    pbs: PLAYBACK_SCENARIO,
    plt: clientType.toLowerCase(),
    productType: PRODUCT_TYPE_NON_GAME,
    prt: PARTNER_TAG,
    state: NA,
    userId,
    ver: __VERSION__,
  };
};
