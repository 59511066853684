import FallbackLogoSvg from 'assets/teamLogo/fallback.svg?react';
import styled from 'styled-components';

export const FallbackContainer = styled.div`
  display: flex;
  width: 8.7rem;
`;

interface FallbackLogoProps {
  $scale?: number;
}

export const FallbackLogo = styled(FallbackLogoSvg)<FallbackLogoProps>`
  transform: ${({ $scale }) => ($scale ? `scale(${$scale})` : 1)};
`;

interface TeamLogoImageProps {
  $size: number | undefined;
}

export const TeamLogoImage = styled.img<TeamLogoImageProps>`
  width: ${({ $size }) => ($size ? `${$size}rem` : '8.7rem')};
`;

interface TeamLogoBackgroundProps {
  $backgroundColor: string;
  $backgroundSize: number;
}

export const TeamLogoBackground = styled.div<TeamLogoBackgroundProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  height: ${({ $backgroundSize }) => `${$backgroundSize}rem`};
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[3]};
  padding: ${({ theme }) => theme.spacing[2]};
  width: ${({ $backgroundSize }) => `${$backgroundSize}rem`};
`;
