import { selectStrings } from 'store/config';
import { EpgVideoFeed } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { getMvpdMessage } from 'utils/gameData';

import { MvpdText } from './MvpdMessage.styles';

interface MvpdMessageProps {
  videoFeeds: EpgVideoFeed[];
}

export function MvpdMessage({ videoFeeds }: MvpdMessageProps) {
  const strings = useAppSelector(selectStrings);

  const mvpdMessage = getMvpdMessage(videoFeeds, strings);

  return <MvpdText>{mvpdMessage}</MvpdText>;
}
