import { utteranceFromIdReferenceList } from './utteranceFromIdReferenceList';

/**
 * Returns string of text collected from
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby aria-describedby} or
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description aria-description}
 */

export const utteranceFromElementDescription = (element: Element) => {
  const describedby = element.getAttribute('aria-describedby');

  if (describedby) {
    return utteranceFromIdReferenceList(describedby);
  }

  return element.getAttribute('aria-description') || '';
};
