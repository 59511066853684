import { Linescore, LinescoreResponse } from './types';

export const formatLinescoreData = ({
  currentInning,
  innings,
  isTopInning,
  teams,
}: LinescoreResponse): Linescore => ({
  away: teams.away,
  currentInning,
  home: teams.home,
  innings: innings.map(({ away, home }) => ({
    awayScore: away?.runs,
    homeScore: home?.runs,
  })),
  isTopInning,
});
