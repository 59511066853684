// Bookmarks
export const BOOKMARKS_KEY = 'bookmarks';

// Create Account
export const LOG_IN_AFTER_CREATE_KEY = 'logInAfterCreate';

// Dev Settings
export const CONFIG_ENV_KEY = 'configEnv';
export const DEV_SETTINGS_KEY = 'devSettings';
export const LOGGING_ENABLED_KEY = 'loggingEnabled';
export const SHOW_TITLE_SAFE_OVERLAY_KEY = 'showTitleSafeOverlay';
export const TEXT_TO_SPEECH_ENABLED_KEY = 'textToSpeechEnabled';
export const TEXT_TO_SPEECH_STRATEGY_KEY = 'textToSpeechStrategy';

// EPG
export const EPG_CACHE_BUST_KEY = 'epgCacheBust';

// Experience
export const EXPERIENCE = 'experience';
export const MLB_EXPERIENCE_KEY = 'mlbExperience';

// Getting Started
export const GETTING_STARTED_VIEWED_KEY = 'gettingStartedViewed';

// IsMuted
export const IS_MUTED_KEY = 'isMuted';

// Okta Auth
export const OKTA_AUTH_STORAGE_KEY = 'okta-token-storage';

// Onboarding Redirect
export const ONBOARDING_REDIRECT_PAGE = 'onboardingRedirectPage';

// Profile
export const FAVORITE_TEAM_KEY = 'favoriteTeam';
export const FOLLOWING_TEAMS_KEY = 'followingTeams';
export const HIDE_SPOILERS_KEY = 'hideSpoilers';

// Settings
export const CLOSED_CAPTIONS_KEY = 'closedCaptions';
