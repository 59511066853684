import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, es } from './locales';
import { PLATFORMS } from 'constants/platforms';

i18n.use(initReactI18next).init({
  defaultNS: __PLATFORM__,
  fallbackLng: 'en',
  fallbackNS: 'core',
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  ns: PLATFORMS,
  resources: { en, es },
});

export default i18n;
