import { EASTERN_TZ } from 'constants/date';
import api, { GET } from 'services/api';
import { EpgData } from 'store/epg';

import { removeEmptyGames } from './normalizers';

interface FetchEpgByDateParams {
  baseQuery: string;
  cacheBustQuery: string;
  date?: string;
  headers: Record<string, string>;
}

export const fetchEpgByDate = async ({
  baseQuery,
  cacheBustQuery,
  date,
  headers,
}: FetchEpgByDateParams) => {
  let queryString = `${baseQuery}&timeZone=${EASTERN_TZ}`;

  if (date) {
    queryString += `&date=${date}`;
  }

  if (cacheBustQuery) {
    queryString += `&${cacheBustQuery}`;
  }

  const { data } = await api<EpgData>(
    GET,
    {
      endpoint: 'search',
      queryParams: {
        queryString,
      },
      service: 'epg',
    },
    { headers },
  );

  const results = removeEmptyGames(data.results);

  // Spreading "data" to ensure that hero and featured is returned with the results
  return { ...data, results };
};
