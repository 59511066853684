import { LOGO_WIDTH, PNG_TYPE, TYPE, WIDTH } from 'constants/cloudinary';
import {
  AllStarTeam,
  AllStarTeamColors,
  MlbTeamColors,
  RasterType,
  TeamBrandingInfo,
  TeamIdtoColorMap,
} from 'store/branding';

export const constructCapLogoMap = (
  rasterType: RasterType,
  teams: TeamBrandingInfo[] | AllStarTeam[] = [],
): TeamIdtoColorMap =>
  teams
    .filter((team: TeamBrandingInfo | AllStarTeam) => !!team.capLogos[rasterType])
    .reduce(
      (acc: TeamIdtoColorMap, team: TeamBrandingInfo | AllStarTeam) => ({
        ...acc,
        [team.teamID]: team.capLogos[rasterType].replace(TYPE, PNG_TYPE).replace(WIDTH, LOGO_WIDTH),
      }),
      {},
    );

export function constructColorMap<Type extends TeamBrandingInfo | AllStarTeam>(
  teams: Type[],
  key: keyof AllStarTeamColors & keyof MlbTeamColors,
) {
  return teams.reduce(
    (acc: TeamIdtoColorMap, team: Type) => ({
      ...acc,
      [team.teamID]: team.teamColors[key] as string,
    }),
    {},
  );
}
