import { createLogger } from 'redux-logger';
import { getLoggingEnabledStorage } from 'utils/storage';

const loggingEnabled = getLoggingEnabledStorage();
const enabled = loggingEnabled && __NODE_ENV__ === 'production';

export const logger = enabled
  ? createLogger({
      collapsed: true,
      diff: true,
    })
  : null;
