import {
  MOCK_CONTENT_SEARCH_SERVICE_CONFIG,
  MOCK_EXPERIENCE_SERVICE_CONFIG,
  MOCK_INIT_SESSION_SERVICE_CONFIG,
  MOCK_MEDIA_INFO_SERVICE_CONFIG,
  MOCK_PLAYBACK_SESSION_SERVICE_CONFIG,
  MOCK_REGISTER_DEVICE_SERVICE_CONFIG,
  NPD_CONTENT_SEARCH_SERVICE_CONFIG,
  NPD_EXPERIENCE_SERVICE_CONFIG,
  NPD_INIT_SESSION_SERVICE_CONFIG,
  NPD_MEDIA_INFO_SERVICE_CONFIG,
  NPD_PLAYBACK_SESSION_SERVICE_CONFIG,
  NPD_REGISTER_DEVICE_SERVICE_CONFIG,
  PROD_CONTENT_SEARCH_SERVICE_CONFIG,
  PROD_EXPERIENCE_SERVICE_CONFIG,
  PROD_INIT_SESSION_SERVICE_CONFIG,
  PROD_MEDIA_INFO_SERVICE_CONFIG,
  PROD_PLAYBACK_SESSION_SERVICE_CONFIG,
  PROD_REGISTER_DEVICE_SERVICE_CONFIG,
  QA_CONTENT_SEARCH_SERVICE_CONFIG,
  QA_EXPERIENCE_SERVICE_CONFIG,
  QA_INIT_SESSION_SERVICE_CONFIG,
  QA_MEDIA_INFO_SERVICE_CONFIG,
  QA_PLAYBACK_SESSION_SERVICE_CONFIG,
  QA_REGISTER_DEVICE_SERVICE_CONFIG,
} from '@mlbtv-clients/services';
import { ConfigEnv } from 'store/config/types';

type ExperienceServiceConfig = typeof PROD_EXPERIENCE_SERVICE_CONFIG;
type ExperienceServiceEndpoints = typeof PROD_EXPERIENCE_SERVICE_CONFIG.endpoints;

type MGWServiceConfig = typeof PROD_CONTENT_SEARCH_SERVICE_CONFIG;
type MGWServiceEndpoints = typeof PROD_CONTENT_SEARCH_SERVICE_CONFIG.endpoints;

type CombinedEndpoints = MGWServiceEndpoints & ExperienceServiceEndpoints;

export type MLBExperienceConfig = {
  [K in keyof CombinedEndpoints]: K extends keyof MGWServiceEndpoints
    ? MGWServiceConfig
    : K extends keyof ExperienceServiceEndpoints
      ? ExperienceServiceConfig
      : never;
};

export const mockServiceConfigs: MLBExperienceConfig = {
  contentSearch: MOCK_CONTENT_SEARCH_SERVICE_CONFIG,
  experience: MOCK_EXPERIENCE_SERVICE_CONFIG,
  initSession: MOCK_INIT_SESSION_SERVICE_CONFIG,
  mediaInfo: MOCK_MEDIA_INFO_SERVICE_CONFIG,
  playbackSession: MOCK_PLAYBACK_SESSION_SERVICE_CONFIG,
  refreshSession: MOCK_PLAYBACK_SESSION_SERVICE_CONFIG,
  registerDevice: MOCK_REGISTER_DEVICE_SERVICE_CONFIG,
};

export const qaServiceConfigs: MLBExperienceConfig = {
  contentSearch: QA_CONTENT_SEARCH_SERVICE_CONFIG,
  experience: QA_EXPERIENCE_SERVICE_CONFIG,
  initSession: QA_INIT_SESSION_SERVICE_CONFIG,
  mediaInfo: QA_MEDIA_INFO_SERVICE_CONFIG,
  playbackSession: QA_PLAYBACK_SESSION_SERVICE_CONFIG,
  refreshSession: QA_PLAYBACK_SESSION_SERVICE_CONFIG,
  registerDevice: QA_REGISTER_DEVICE_SERVICE_CONFIG,
};

export const stageServiceConfigs: MLBExperienceConfig = {
  contentSearch: NPD_CONTENT_SEARCH_SERVICE_CONFIG,
  experience: NPD_EXPERIENCE_SERVICE_CONFIG,
  initSession: NPD_INIT_SESSION_SERVICE_CONFIG,
  mediaInfo: NPD_MEDIA_INFO_SERVICE_CONFIG,
  playbackSession: NPD_PLAYBACK_SESSION_SERVICE_CONFIG,
  refreshSession: NPD_PLAYBACK_SESSION_SERVICE_CONFIG,
  registerDevice: NPD_REGISTER_DEVICE_SERVICE_CONFIG,
};

export const prodServiceConfigs: MLBExperienceConfig = {
  contentSearch: PROD_CONTENT_SEARCH_SERVICE_CONFIG,
  experience: PROD_EXPERIENCE_SERVICE_CONFIG,
  initSession: PROD_INIT_SESSION_SERVICE_CONFIG,
  mediaInfo: PROD_MEDIA_INFO_SERVICE_CONFIG,
  playbackSession: PROD_PLAYBACK_SESSION_SERVICE_CONFIG,
  refreshSession: PROD_PLAYBACK_SESSION_SERVICE_CONFIG,
  registerDevice: PROD_REGISTER_DEVICE_SERVICE_CONFIG,
};

export const serviceConfigsByEnv: { [key in ConfigEnv]: MLBExperienceConfig } = {
  dev: prodServiceConfigs,
  prod: prodServiceConfigs,
  qa: qaServiceConfigs,
  stage: stageServiceConfigs,
};
