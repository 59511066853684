// Final Game States
export const ARCHIVE_AIRING = 'ARCHIVE';
export const CANCELLED = 'Cancelled';
export const CANCELLED_STATUS = 'CANCELLED';
export const COMPLETED_EARLY = 'Completed Early';
export const COMPLETED_EARLY_STATUS = 'COMPLETED_EARLY';
export const FINAL = 'Final';
export const FORFEIT = 'Forfeit';
export const FORFEIT_STATUS = 'FORFEIT';
export const GAME_OVER = 'Game Over';
export const POSTPONED = 'Postponed';
export const POSTPONED_STATUS = 'POSTPONED';

// Live Game States
export const DELAYED = 'Delayed';
export const DELAYED_STATUS = 'DELAYED';
export const DELAYED_WEATHER = 'DELAYED - WEATHER';
export const IN_PROGRESS = 'In Progress';
export const INSTANT_REPLAY = 'Instant Replay';
export const LIVE = 'Live';
export const LIVE_AIRING = 'LIVE';
export const MANAGER_CHALLENGE = 'Manager challenge';
export const SUSPENDED = 'Suspended';
export const UMPIRE_REVIEW = 'Umpire review';
export const WARMUP = 'Warmup';
export const WARMUP_STATUS_CODE = 'PW';

// Preview Game States
export const DELAYED_START = 'Delayed Start';
export const GAME = 'GAME';
export const PRE_GAME = 'Pre-Game';
export const PREGAME = 'PREGAME';
export const PREVIEW = 'Preview';

// Preview Game Time TBD
export const TBD = 'TBD';

// Weather Reasons for delays, cancellations, etc
export const WEATHER = 'Weather';
export const WEATHER_REASONS = [
  'Cold',
  'COVID-19', // TODO: remove this when we are out of a global pandemic
  'Darkness',
  'Fog',
  'Inclement Weather',
  'Lightening',
  'Rain',
  'Snow',
  'Wet Grounds',
  'Wind',
];

// Game Status
export const MAX_BOXSCORE_LENGTH = 15;

// Game State Fallback Text
export const FINAL_FALLBACK_TEXT = 'Hide Spoilers ON';
export const LIVE_BATTER_HIDE_SPOILERS_TEXT = 'Batter Hidden';
export const LIVE_PITCHER_HIDE_SPOILERS_TEXT = 'Pitcher Hidden';
export const PREVIEW_PITCHER_HIDE_SPOILERS_TEXT = 'Probable Pitcher Hidden';
export const PREVIEW_PITCHER_FALLBACK_TEXT = 'Pitcher TBD';

// Inning states
export const BOTTOM_INNING = 'Bot';
export const TOP_INNING = 'Top';

// Outs states
export const OUT = 'OUT';
export const OUTS = 'OUTS';
export const OUT_TITLE_CASE = 'Out';
export const OUTS_TITLE_CASE = 'Outs';

// Pregame and Postgame Shows
export const PREGAME_SHOW = 'Pregame';
export const POSTGAME_SHOW = 'Postgame';

// Stats
export const AB = 'AB';
export const AVG = 'AVG';
export const ERA = 'ERA';
export const ERRORS = 'E';
export const FALLBACK_STAT = '-';
export const HITS = 'H';
export const IP = 'IP';
export const LOSS = 'L';
export const P = 'P';
export const RUNS = 'R';
export const SAVE = 'S';
export const WIN = 'W';

export const LINESCORE_STATS = [RUNS, HITS, ERRORS];

// Teams
export const AWAY_TEAM = 'AWAY';
export const HOME_TEAM = 'HOME';

// Hide Spoilers
export const BATTER_HIDE_SPOILERS_TEXT = 'Batter Hidden';
export const PITCHER_HIDE_SPOILERS_TEXT = 'Pitcher Hidden';
export const FINAL_HIDE_SPOILERS_TEXT = 'Hide Spoilers is ON';

// Innings
export const NUM_INNINGS_IN_GAME = 9;
