import { init as initSpatialNavigation, setKeyMap } from '@noriginmedia/norigin-spatial-navigation';
import { DEFAULT_SPATIAL_NAV_SETTINGS } from 'constants/focus';
import { AuthProvider } from 'context/AuthContext/AuthContext';
import { domAnimation, LazyMotion } from 'framer-motion';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/index';
import GlobalStyles from 'styles/global-styles';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { NAVIGATION_KEY_MAP } from 'utils/controllerKeymap';

import { App } from './App';

initSpatialNavigation(DEFAULT_SPATIAL_NAV_SETTINGS);
if (NAVIGATION_KEY_MAP) setKeyMap(NAVIGATION_KEY_MAP);

// set startAppTime to calculate initial load time for datadog
window.startAppTime = new Date();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <LazyMotion features={domAnimation}>
      <BrowserRouter>
        <ThemeProvider>
          <GlobalStyles />
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LazyMotion>
  </Provider>,
);
