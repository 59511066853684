import { ROUTES } from 'constants/screens';
import { useNavigate } from 'react-router-dom';

const { HOME } = ROUTES;

export const useReloadApp = () => {
  const navigate = useNavigate();

  return () => {
    navigate(HOME);
    navigate(0);
  };
};
