import { isGameBlackout } from 'components/FeedSelect/BlackoutMessage/utils';
import { INTERNATIONAL_FEED_SUFFIX, MEDIA_ARCHIVE, MEDIA_OFF, MEDIA_ON } from 'constants/feeds';
import { BlackoutCodes } from 'constants/feedSelectModal';
import {
  AWAY_TEAM,
  CANCELLED,
  COMPLETED_EARLY_STATUS,
  DELAYED_STATUS,
  DELAYED_WEATHER,
  FINAL,
  FORFEIT,
  LIVE,
  POSTPONED,
  PREVIEW,
  WARMUP_STATUS_CODE,
} from 'constants/gameState';
import { CANADA_COUNTRY_CODE, US_COUNTRY_CODE } from 'constants/userInfo';
import { StatsApiConfig, StringsConfig } from 'store/config';
import {
  EpgAndStatsGame,
  EpgAndStatsGameData,
  EpgApiGame,
  EpgAudioFeed,
  EpgPrePostShowData,
  EpgVideoFeed,
  ShowData,
  StreamStateValues,
} from 'store/epg';

import { monthDayYear } from './date';

const { NATIONAL_BLACKOUT_CODE, RSN_BLACKOUT_CODE } = BlackoutCodes;

const { POST_GAME_SHOW, PRE_GAME_SHOW } = StreamStateValues;

export const isGameCancelled = (detailedState: string) => detailedState.includes(CANCELLED);

export const isGameFinal = (gameData: EpgAndStatsGameData) => gameData.abstractGameState === FINAL;

export const isGameForfeited = (detailedState: string) => detailedState.includes(FORFEIT);

export const isGameLive = (gameData?: EpgAndStatsGameData) => gameData?.abstractGameState === LIVE;

export const isGamePostponed = (detailedState: string) => detailedState.includes(POSTPONED);

export const isGamePreview = ({ gameData }: EpgApiGame) => gameData.abstractGameState === PREVIEW;

export const isGameWarmup = (gameData?: EpgAndStatsGameData) =>
  gameData?.statusCode === WARMUP_STATUS_CODE;

export const getGameStatusFromStatusCode = (
  statusCode: string,
  statusCodes: StatsApiConfig['statusCodes'],
): string =>
  Object.entries(statusCodes).find(([_, codes]) => codes.includes(statusCode))?.[0] ?? '';

export const getGameStatusText = ({
  gameData,
  isFeaturedText = false,
  statusCodes,
}: {
  gameData: EpgAndStatsGameData;
  isFeaturedText?: boolean;
  statusCodes: StatsApiConfig['statusCodes'];
}) => {
  const { away, home, statusCode } = gameData;
  const gameStatus = getGameStatusFromStatusCode(statusCode, statusCodes);

  // Completed Early game or Normal game without a status code to show
  if (!gameStatus || gameStatus === COMPLETED_EARLY_STATUS) {
    return '';
  }
  // Game with a non-forfeit status code
  if (gameStatus !== FORFEIT.toUpperCase()) {
    return isFeaturedText && gameStatus === DELAYED_WEATHER ? DELAYED_STATUS : gameStatus;
  }

  // Forfeit game
  const forfeitingTeam = away.isWinner ? home.teamAbbrv : away.teamAbbrv;
  return `${forfeitingTeam} - ${FORFEIT.toUpperCase()}`;
};

interface InferTeamAbbrvFromGameDataArgs {
  gameData: EpgAndStatsGameData;
  teamId: number;
}

export const inferTeamAbbrvFromGameData = ({
  gameData,
  teamId,
}: InferTeamAbbrvFromGameDataArgs) => {
  const { away, home } = gameData;

  return away.teamId === teamId ? away.teamAbbrv : home.teamAbbrv;
};

// Feed State
export const getIsMediaArchive = (feed?: EpgAudioFeed | EpgVideoFeed) =>
  feed?.mediaState === MEDIA_ARCHIVE;

export const getIsMediaOff = (feed?: EpgAudioFeed | EpgVideoFeed) => feed?.mediaState === MEDIA_OFF;

export const getIsMediaOn = (feed?: EpgAudioFeed | EpgVideoFeed) => feed?.mediaState === MEDIA_ON;

// MVPD Games
export const filterOutIntFeedForMvpdGameUs = (videoFeeds: EpgVideoFeed[]) =>
  videoFeeds.filter(({ callLetters }) => !callLetters.includes(INTERNATIONAL_FEED_SUFFIX));

export const filterOutMvpdFeedForIntUser = (videoFeeds: EpgVideoFeed[]) =>
  videoFeeds.filter(({ callLetters }) => callLetters.includes(INTERNATIONAL_FEED_SUFFIX));

export const isAbcMvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.abcAuthRequired);

export const isEspnMvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.espnAuthRequired);

export const isEspn2MvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.espn2AuthRequired);

export const isFoxMvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.foxAuthRequired);

export const isFs1MvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.fs1AuthRequired);

export const isMlbnMvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.mlbnAuthRequired);

export const isTbsMvpdRequired = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.tbsAuthRequired);

export const isMvpdGame = (videoFeeds: EpgVideoFeed[] = []): boolean => {
  const isAbcRequired = isAbcMvpdRequired(videoFeeds);
  const isEspnRequired = isEspnMvpdRequired(videoFeeds);
  const isEspn2Required = isEspn2MvpdRequired(videoFeeds);
  const isFs1Required = isFs1MvpdRequired(videoFeeds);
  const isFoxRequired = isFoxMvpdRequired(videoFeeds);
  const isMlbnRequired = isMlbnMvpdRequired(videoFeeds);
  const isTbsRequired = isTbsMvpdRequired(videoFeeds);

  return (
    isAbcRequired ||
    isEspnRequired ||
    isEspn2Required ||
    isFs1Required ||
    isFoxRequired ||
    isMlbnRequired ||
    isTbsRequired
  );
};

export const getMvpdMessage = (videoFeeds: EpgVideoFeed[], strings: StringsConfig) => {
  const {
    feedSelectModal: {
      abcMvpdMessage,
      espn2MvpdMessage,
      espnMvpdMessage,
      foxMvpdMessage,
      fs1MvpdMessage,
      mlbnMvpdMessage,
      tbsMvpdMessage,
    },
  } = strings;

  const isAbcRequired = isAbcMvpdRequired(videoFeeds);
  const isEspnRequired = isEspnMvpdRequired(videoFeeds);
  const isEspn2Required = isEspn2MvpdRequired(videoFeeds);
  const isFoxRequired = isFoxMvpdRequired(videoFeeds);
  const isFs1Required = isFs1MvpdRequired(videoFeeds);
  const isMlbnRequired = isMlbnMvpdRequired(videoFeeds);
  const isTbsRequired = isTbsMvpdRequired(videoFeeds);

  switch (true) {
    case isAbcRequired:
      return abcMvpdMessage;
    case isEspnRequired:
      return espnMvpdMessage;
    case isEspn2Required:
      return espn2MvpdMessage;
    case isFoxRequired:
      return foxMvpdMessage;
    case isFs1Required:
      return fs1MvpdMessage;
    case isMlbnRequired:
      return mlbnMvpdMessage;
    case isTbsRequired:
      return tbsMvpdMessage;
    default:
      return '';
  }
};

// used to determine if a user is entitled to MVPD feed
export const isEntitledToFeeds = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some(({ entitled }) => entitled);

export const shouldShowMvpdMessage = (
  userCountryCode: string,
  videoFeeds: EpgVideoFeed[],
): boolean => {
  const isEntitledToVideoFeeds = isEntitledToFeeds(videoFeeds);
  const isInternationalUser =
    userCountryCode !== US_COUNTRY_CODE &&
    userCountryCode !== CANADA_COUNTRY_CODE &&
    userCountryCode !== '';

  return isMvpdGame(videoFeeds) && !isEntitledToVideoFeeds && !isInternationalUser;
};

// Partner Games
export const isAppleTvGame = (videoFeeds: EpgVideoFeed[]): boolean =>
  videoFeeds.some((feed) => feed.blackedOut && feed.appletv);

export const isFGOTD = (videoFeeds: EpgVideoFeed[]) => videoFeeds.some((feed) => feed.freeGame);

export const isPeacockGame = (videoFeeds: EpgVideoFeed[]) =>
  videoFeeds.some((feed) => feed.blackedOut && feed.peacock);

export const isYoutubeGame = (videoFeeds: EpgVideoFeed[]) =>
  videoFeeds.some((feed) => feed.blackedOut && feed.youtube);

export const isPartnerGame = (videoFeeds: EpgVideoFeed[] = []): boolean => {
  const isAppleTv = isAppleTvGame(videoFeeds);
  const isPeacock = isPeacockGame(videoFeeds);
  const isYoutube = isYoutubeGame(videoFeeds);

  return isAppleTv || isPeacock || isYoutube;
};

// Special Games
export const isAllStarGame = (game: EpgAndStatsGame, teamAbbrvToId: { [key: string]: number }) => {
  const {
    gameData: {
      home: { teamId },
    },
  } = game;

  const { AL, NL } = teamAbbrvToId;

  return teamId === AL || teamId === NL;
};

export const isTeamFeedEntitled = (videoFeeds: EpgVideoFeed[], awayOrHomeTeam: string): boolean =>
  videoFeeds.some(({ entitled, mediaFeedType }) => mediaFeedType === awayOrHomeTeam && entitled);

export const hasLivePostShow = (postGame: ShowData, prePostShowData: EpgPrePostShowData) =>
  postGame.hasShow && prePostShowData.streamState === POST_GAME_SHOW;

export const isPostGameLiveAndEntitledForTeam = (game: EpgAndStatsGame, awayOrHomeTeam: string) => {
  const {
    prePostShows: {
      away,
      away: { postGame: awayPostGame },
      home,
      home: { postGame: homePostGame },
    },
    videoFeeds,
  } = game;
  const awayFeedIsEntitled = isTeamFeedEntitled(videoFeeds, awayOrHomeTeam);
  const homeFeedIsEntitled = isTeamFeedEntitled(videoFeeds, awayOrHomeTeam);
  const awayHasLivePostShow = hasLivePostShow(awayPostGame, away);
  const homeHasLivePostShow = hasLivePostShow(homePostGame, home);
  const awayHasEntitledPostGameShow = awayFeedIsEntitled && awayHasLivePostShow;
  const homeHasEntitledPostGameShow = homeFeedIsEntitled && homeHasLivePostShow;

  return awayOrHomeTeam === AWAY_TEAM ? awayHasEntitledPostGameShow : homeHasEntitledPostGameShow;
};

export const isPreGameLiveAndEntitledForTeam = (game: EpgAndStatsGame, awayOrHomeTeam: string) => {
  const {
    prePostShows: {
      away,
      away: { preGame: awayPreGame },
      home,
      home: { preGame: homePreGame },
    },
    videoFeeds,
  } = game;
  const awayFeedIsEntitled = isTeamFeedEntitled(videoFeeds, awayOrHomeTeam);
  const homeFeedIsEntitled = isTeamFeedEntitled(videoFeeds, awayOrHomeTeam);
  const awayHasLivePreShow = awayPreGame.hasShow && away.streamState === PRE_GAME_SHOW;
  const homeHasLivePreShow = homePreGame.hasShow && home.streamState === PRE_GAME_SHOW;
  const awayHasEntitledPreGameShow = awayFeedIsEntitled && awayHasLivePreShow;
  const homeHasEntitledPreGameShow = homeFeedIsEntitled && homeHasLivePreShow;

  return awayOrHomeTeam === AWAY_TEAM ? awayHasEntitledPreGameShow : homeHasEntitledPreGameShow;
};

export const gamesPreviewOrLive = (games: EpgAndStatsGame[]) =>
  games.some(
    ({ gameData: { abstractGameState } }) =>
      abstractGameState === PREVIEW || abstractGameState === LIVE,
  );

export const getGameMatchup = (game: EpgAndStatsGame) => {
  const {
    gameData: {
      away: { teamAbbrv: awayTeamAbbrv },
      gameDate,
      home: { teamAbbrv: homeTeamAbbrv },
    },
  } = game;
  const formattedGameDate = gameDate ? monthDayYear(gameDate) : '';

  const awayTeam = awayTeamAbbrv ?? '';
  const homeTeam = homeTeamAbbrv ?? '';
  return `${awayTeam}@${homeTeam}_${formattedGameDate}`;
};

export const getGameTeamIds = (game: EpgAndStatsGame) => {
  const {
    gameData: {
      away: { teamId: awayTeamId },
      home: { teamId: homeTeamId },
    },
  } = game;

  return { awayTeamId, homeTeamId };
};

export const shouldShowBlackoutMessage = (game: EpgAndStatsGame | null) => {
  if (!game) return false;

  const { videoStatusCodes } = game;
  const isNationalBlackout = isGameBlackout(videoStatusCodes, NATIONAL_BLACKOUT_CODE);
  const isRsnBlackout = isGameBlackout(videoStatusCodes, RSN_BLACKOUT_CODE);

  return isNationalBlackout || isRsnBlackout;
};
