import { NAVIGATION_KEY_MAP } from 'utils/controllerKeymap';

export enum Directions {
  Down = 'down',
  Left = 'left',
  Right = 'right',
  Up = 'up',
}

export const ARROW_KEYS = new Set([
  ...NAVIGATION_KEY_MAP.down,
  ...NAVIGATION_KEY_MAP.left,
  ...NAVIGATION_KEY_MAP.right,
  ...NAVIGATION_KEY_MAP.up,
]);
