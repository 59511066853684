import {
  AudioTrack,
  Content,
  MediaFeedType,
  Milestone,
  Milestone as MlbMilestone,
} from '@mlbtv-clients/services';
import { TV_TRACK_TYPE } from 'constants/feeds';
import { DSSMilestone, MilestoneTimeType, MilestoneType } from 'experience/DSS';
import { toISOString } from 'utils/date';

import { InningMilestone } from './types';

export const formatDSSMilestones = (milestones: DSSMilestone[] = []): InningMilestone[] =>
  milestones
    .filter(({ milestoneType }) => milestoneType === MilestoneType.INNING_START)
    .map(({ keywords, milestoneTime }) => ({
      absoluteTime:
        milestoneTime.filter(({ type }) => type === MilestoneTimeType.absolute)[0]?.startDatetime ??
        '',
      inning: keywords[1].value,
      isTop: JSON.parse(keywords[0].value) as boolean,
      milestoneTime:
        milestoneTime.filter(({ type }) => type === MilestoneTimeType.offset)[0].start ?? 0,
    }));

export const formatMlbMilestones = (milestones: MlbMilestone[] = []): InningMilestone[] =>
  milestones
    .filter(({ milestoneType }) => milestoneType === MilestoneType.INNING_START)
    .map(({ absoluteTime, keywords, relativeTime }) => ({
      absoluteTime,
      inning: keywords[0].value,
      isTop: JSON.parse(keywords[1].value) as boolean,
      milestoneTime: relativeTime,
    }));

export const getDSSBroadcastStartTime = (milestones: DSSMilestone[] = []): number =>
  milestones
    .find(({ milestoneType }) => milestoneType === MilestoneType.BROADCAST_START)
    ?.milestoneTime.find((milestone) => milestone.type === MilestoneTimeType.offset)?.start ?? 1;

export const getDSSAbsoluteStreamStartTime = (milestones: DSSMilestone[] = []): string =>
  milestones
    .find(({ milestoneType }) => milestoneType === MilestoneType.BROADCAST_START)
    ?.milestoneTime.find((milestone) => milestone.type === MilestoneTimeType.absolute)
    ?.startDatetime ?? toISOString();

export const getMlbBroadcastStartTime = (milestones: Milestone[] = []): number => {
  const mlbBroadcastStart =
    milestones.find(({ milestoneType }) => milestoneType === MilestoneType.BROADCAST_START)
      ?.relativeTime ?? 1;

  return mlbBroadcastStart >= 0 ? mlbBroadcastStart : 1;
};

export const getMlbAbsoluteStreamStartTime = (milestones: Milestone[] = []): string =>
  milestones.find(({ milestoneType }) => milestoneType === MilestoneType.STREAM_START)
    ?.absoluteTime ?? toISOString();

// Puts the home feed first in the broadcast options video feed list
export const orderVideoFeeds = (videoFeeds: Content[]) => {
  videoFeeds.sort(({ feedType }) => (feedType === MediaFeedType.HOME ? -1 : 1));
  return videoFeeds;
};

// Puts the TV audio feed first in the broadcast options audio feed list
export const orderAudioTracks = (audioTracks: AudioTrack[]) => {
  audioTracks.sort(({ trackType }) => (trackType === TV_TRACK_TYPE ? -1 : 1));
  return audioTracks;
};
