import { AdobeTracker, NO_AUTH, NO_FAVORITE, NO_FOLLOW } from 'analytics/adobe';
import { useEffect } from 'react';
import { selectEntitlements } from 'store/experience';
import { useAppSelector } from 'store/hooks';
import { selectOktaId } from 'store/mlbAuth';
import { selectFavoriteTeam, selectFollowingTeams, selectHideSpoilers } from 'store/profile';

export function useAdobe() {
  const entitlements = useAppSelector(selectEntitlements);
  const favoriteTeam = useAppSelector(selectFavoriteTeam);
  const followingTeams = useAppSelector(selectFollowingTeams);
  const hideSpoilers = useAppSelector(selectHideSpoilers);
  const oktaId = useAppSelector(selectOktaId);

  useEffect(() => {
    AdobeTracker.updateUserParams({
      entitlements: entitlements.join(','),
      favoriteTeams: favoriteTeam ?? NO_FAVORITE,
      followTeams: followingTeams.length > 0 ? followingTeams.join(',') : NO_FOLLOW,
      okta: oktaId || NO_AUTH,
      settings: {
        hideSpoilers: hideSpoilers ? 'true' : 'false',
      },
    });
  }, [entitlements, favoriteTeam, followingTeams, hideSpoilers, oktaId]);
}
