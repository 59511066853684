export const appIds = {
  APP_CONTAINER: 'appContainer',
  APP_ERROR_BOUNDARY_BUTTON: 'appErrorBoundaryButton',
  APP_ERROR_BOUNDARY_MODAL: 'appErrorBoundaryModal',
  SCREEN: 'screen',
  WELCOME_INDEX: 'welcomeIndex',
};

export const backgroundImageIds = {
  BACKGROUND_IMAGE: 'backgroundImage',
  BACKGROUND_IMAGE_OVERLAY: 'backgroundImageOverlay',
};

export const blackoutMessageModalIds = {
  MODAL: 'blackoutMessageModal',
  MODAL_BUTTON: 'blackoutMessageModalButton',
};

export const concurrencyErrorModalIds = {
  MODAL: 'concurrencyErrorModal',
  MODAL_BUTTON: 'concurrencyErrorModalButton',
};

export const controlPanelIds = {
  CC_BUTTON: 'closedCaptionsButton',
  DEV_MUTE_BUTTON: 'devMuteButton',
  FFRW_ARROWS: 'ffrwArrows',
  FFRW_BOX: 'ffrwBox',
  FFRW_BOX_TIME: 'ffrwBoxTime',
  FFRW_INNING_AND_OUT: 'ffrwInningAndOut',
  JUMP_TO_LIVE_BUTTON: 'jumpToLiveButton',
  LIVE_CIRCLE: 'liveCircle',
  SCRUBBER: 'scrubber',
  TV_FEED_ICON: 'tvFeedIcon',
  VIDEO_PROGRESS: 'videoProgress',
  VIDEO_TITLE: 'videoTitle',
};

export const dateNavigatorIds = {
  CALENDAR_BUTTON: 'dateNavigatorCalendarButton',
  NEXT_MONTH_BUTTON: 'dateNavigatorNextMonthButton',
  PREV_MONTH_BUTTON: 'dateNavigatorPrevMonthButton',
  TODAY_BUTTON: 'dateNavigatorTodayButton',
};

export const debuggerIds = {
  VIDEO_DEBUGGER: 'videoDebugger',
};

export const featuredContentIds = {
  AWAY_SCORE: 'awayScore',
  FEATURED_FORGE_CONTENT: 'featuredForgeContent',
  FEATURED_GAME_CONTENT: 'featuredGameContent',
  FEATURED_GAME_STATUS_PILL: 'featuredGameStatusPill',
  HOME_SCORE: 'homeScore',
  LOCK_ICON: 'lockIcon',
  WATCH_BUTTON: 'watchButton',
};

export const featuredGameInfoIds = {
  FEATURED_GAME_BALLS_STRIKES: 'featuredGameBallsStrikes',
  FEATURED_GAME_BASES: 'featuredGameBases',
  FEATURED_GAME_INFO: 'featuredGameInfo',
  FEATURED_GAME_LIVE_TEXT: 'featuredGameLiveText',
  FEATURED_GAME_OUTS: 'featuredGameOuts',
};

export const featuredGamePartnerMessageIds = {
  APPLE_TV_ICON: 'appleTvIcon',
  PARTNER_WATCH_TEXT: 'partnerWatchText',
  PEACOCK_ICON: 'peacockIcon',
  YOUTUBE_ICON: 'youtubeIcon',
};

export const feedSelectIds = {
  CONDENSED_BUTTON: 'condensedButton',
  FEED_BUTTON: 'feedButton',
  FEED_SELECT: 'feedSelect',
  FEED_SELECT_CONTENT: 'feedSelectContent',
  GET_MLBTV_BUTTON: 'getMlbTvButton',
  LOCK_ICON: 'lockIcon',
  NO_FEEDS: 'noFeeds',
  RECAP_BUTTON: 'recapButton',
  RESUME_LINK: 'resumeLink',
  SECONDARY_MODAL: 'feedSelectSecondaryModal',
  SIGNAL_TOWER: 'signalTower',
  START_FROM_BEGINNING_LINK: 'startFromBeginningLink',
  STREAM_SELECTION: 'streamSelection',
  UPGRADE_MLBTV_BUTTON: 'upgradeMlbTvButton',
  VIDEO_ICON: 'videoIcon',
  WATCH_LIVE_LINK: 'watchLiveLink',
};

export const gameMenuIds = {
  ABC_ICON: 'abcIcon',
  APPLETV_ICON: 'appletvIcon',
  AUDIO_FEED_PLACEHOLDER_ICON: 'audioFeedPlaceholderIcon',
  BROADCAST_FEED_LOCK_ICON: 'broadcastFeedLockIcon',
  BROADCAST_OPTIONS: 'broadcastOptions',
  BROADCAST_OPTIONS_BUTTON: 'broadcastOptionsButton',
  BROADCAST_OPTIONS_FEED: 'broadcastOptionsFeed',
  DETAILS_BUTTON: 'detailsButton',
  DETAILS_MENU: 'detailsMenu',
  ESPN_ICON: 'espnIcon',
  FOX_ICON: 'foxIcon',
  FS1_ICON: 'fs1Icon',
  GAME_MENU: 'gameMenu',
  GAME_MENU_BUTTONS: 'gameMenuButtons',
  GAME_MENU_OPTIONS: 'gameMenuOptions',
  LINESCORE: 'linescore',
  MATCHUP: 'matchup',
  MLBN_ICON: 'mlbnIcon',
  PEACOCK_ICON: 'peacockIcon',
  TBS_ICON: 'tbsIcon',
  VIDEO_FEED_PLACEHOLDER_ICON: 'videoFeedPlaceholderIcon',
  YOUTUBE_ICON: 'youtubeIcon',
};

export const gamesCarouselIds = {
  GAMES_CAROUSEL: 'gamesCarousel',
};

export const gameTileIds = {
  APPLE_TV_ICON: 'appleTvIcon',
  BLACKOUT_ICON: 'blackoutIcon',
  FREE_GAME_OF_THE_DAY_ICON: 'freeGameOfTheDayIcon',
  LIVE_BADGE: 'liveBadge',
  MESSAGE_PILLS: 'messagePills',
  PARTNER_ICON: 'partnerIcon',
  PEACOCK_ICON: 'peacockIcon',
  TILE: 'gameTile',
  TILE_GRID: 'gameTileGrid',
  YOUTUBE_ICON: 'youtubeIcon',
};

export const gameCalendarIds = {
  DATE: 'gameCalendarDate',
  GAMES_PER_DAY: 'gameCalendarGamesPerDay',
  NEXT_MONTH_BUTTON: 'gameCalendarNextMonthButton',
  PANEL: 'gameCalendarPanel',
  PANEL_CONTENT: 'gameCalendarPanelContent',
  PREV_MONTH_BUTTON: 'gameCalendarPrevMonthButton',
  TODAY_BUTTON: 'gameCalendarTodayButton',
};

export const gamesPageIds = {
  ERROR: 'gamesPageError',
  NO_GAMES: 'gamesPageNoGames',
};

export const headerIds = {
  HEADER: 'header',
  LINK: 'headerLink',
};

export const loginIds = {
  EXPRESS_LOGIN: 'expressLogin',
  EXPRESS_LOGIN_INSTRUCTIONS: 'expressLoginInstructions',
  EXPRESS_LOGIN_QR_CODE: 'expressLoginQRCode',
  LOGIN_FORM: 'loginForm',
  LOGIN_OPTION_DIVIDER: 'loginOptionDivider',
  STANDARD_LOGIN: 'standardLogin',
};

export const matchupIds = {
  GAME_STATUS: 'gameStatus',
  MATCHUP: 'matchup',
  MATCHUP_DIVIDER: 'matchupDivider',
  MATCHUP_GAME_DETAILS: 'matchupGameDetails',
  MATCHUP_OVERLAY: 'matchupOverlay',
};

export const messagingIds = {
  LOCK_ICON: 'lockIcon',
};

export const mvpdMessageModalIds = {
  MODAL: 'mvpdMessageModal',
  MODAL_BUTTON: 'mvpdMessageModalButton',
};

export const networkConnectionModalIds = {
  MODAL: 'networkConnectionModal',
};

export const notEntitledModalIds = {
  MODAL: 'notEntitledModal',
  MODAL_BUTTON: 'notEntitledModalButton',
};

export const onboardingIds = {
  CANCEL_BUTTON: 'onboardingCancelButton',
  CHECKBOX: 'onboardingCheckbox',
  CREATE_ACCOUNT_BUTTON: 'onboardingCreateAccountButton',
  CREATE_ACCOUNT_SUCCESS_BUTTON: 'createAccountSuccessButton',
  CREATE_ACCOUNT_SUCCESS_ICON: 'createAccountSuccessIcon',
  EMAIL_INPUT: 'onboardingEmailInput',
  EYE_ICON: 'onboardingEyeIcon',
  EYE_WITH_SLASH: 'eyeWithSlash',
  EYE_WITHOUT_SLASH: 'eyeWithoutSlash',
  FAVORITE_TEAM_TEXT: 'onboardingFavoriteTeamText',
  LOGIN_BUTTON: 'onboardingLoginButton',
  PACKAGE_DISPLAY: 'onboardingPackageDisplay',
  PACKAGE_LIST: 'onboardingPackageList',
  PASSWORD_INPUT: 'onboardingPasswordInput',
};

export const playerIds = {
  PAUSE_FLASH_ICON: 'pauseFlashIcon',
  PLAY_FLASH_ICON: 'playFlashIcon',
  PLAYBACK_MARKER: 'playbackMarker',
};

export const playerErrorModalIds = {
  MODAL: 'playerErrorModal',
  MODAL_BUTTON: 'playerErrorModalButton',
};

export const paywallIds = {
  MLB_LOGO: 'mlbLogo',
  YEARLY_MONTHLY_TOGGLE: 'yearlyMonthlyToggle',
};

export const screenIds = {
  CREATE_ACCOUNT: 'createAccountScreen',
  CREATE_ACCOUNT_SUCCESS: 'createAccountSuccessScreen',
  FAV_TEAM_PICKER_SCREEN: 'favTeamPickerScreen',
  FOLLOW_TEAM_PICKER_SCREEN: 'followTeamPickerScreen',
  FORGE_VIDEO: 'forgeVideoScreen',
  GAME_VIDEO: 'gameVideoScreen',
  GAMES: 'gamesScreen',
  GET_STARTED: 'getStartedScreen',
  HOME: 'homeScreen',
  LOGIN: 'loginScreen',
  LOGIN_CALLBACK: 'loginCallbackScreen',
  PAYWALL: 'paywallScreen',
  PURCHASE: 'purchaseScreen',
  SETTINGS: 'settingsScreen',
  WELCOME: 'welcomeScreen',
};

export const settingsIds = {
  CC_BUTTON: 'closedCaptionsButton',
  CONTACT_SUPPORT_BUTTON: 'contactSupportButton',
  DEV_SETTINGS: 'devSettings',
  DEV_SETTINGS_BUTTON: 'devSettingsButton',
  DEV_SETTINGS_CLEAR_STORAGE_BUTTON: 'devSettingsClearStorageButton',
  DEV_SETTINGS_ENV_BUTTON: 'devSettingsEnvButton',
  DEV_SETTINGS_EXPERIENCE_BUTTON: 'devSettingsExperienceButton',
  FAVORITE_TEAM_BUTTON: 'favoriteTeamButton',
  FOLLOWING_TEAMS_BUTTON: 'followingTeamsButton',
  HIDE_SPOILERS_BUTTON: 'hideSpoilersButton',
  LOGIN_BUTTON: 'settingsLoginButton',
  SESSION_INFO: 'settingsSessionInfo',
  SETTINGS_BUTTON_ARROW: 'settingsButtonArrow',
  SETTINGS_INFO: 'settingsInfo',
  TOS_BUTTON: 'termsOfServiceButton',
};

export const teamLogoIds = {
  FALLBACK_LOGO: 'fallbackLogo',
};

export const teamPickerIds = {
  FAVORITE_TEAM_STAR: 'favoriteTeamStar',
  NEXT_OR_DONE_BUTTON: 'nextOrDoneButton',
  SKIP_OR_CANCEL_BUTTON: 'skipOrCancelButton',
  TEAM_PICKER: 'teamPicker',
  TEAM_PICKER_GRID: 'teamPickerGrid',
  TEAM_PICKER_TILE: 'teamPickerTile',
};

export const videoInfoModalIds = {
  MODAL: 'videoInfoModal',
  OKAY_BUTTON: 'videoInfoModalOkayButton',
};
