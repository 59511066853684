import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'services/api';
import { fetchPaywallConfig } from 'services/paywall';
import { RootState } from 'store';

import { PaywallConfig, PaywallPackage } from './types';

export interface PaywallState {
  currentPackage: PaywallPackage | null;
  data: PaywallConfig | null;
  error: string | null;
  status: RequestStatus;
}

export const initialState: PaywallState = {
  currentPackage: null,
  data: null,
  error: null,
  status: RequestStatus.IDLE,
};

export const fetchPaywallConfigFlow = createAsyncThunk<
  PaywallConfig,
  undefined,
  { rejectValue: string }
>('paywall/fetchPaywallConfig', async (_, { rejectWithValue }) => {
  try {
    const paywallConfig = await fetchPaywallConfig();
    return paywallConfig;
  } catch (error: any) {
    return rejectWithValue(error?.message);
  }
});

export const paywallSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPaywallConfigFlow.pending, (state) => {
      state.status = RequestStatus.LOADING;
    });

    builder.addCase(fetchPaywallConfigFlow.fulfilled, (state, action) => {
      state.status = RequestStatus.SUCCESS;
      state.data = action.payload;
      state.error = null;
    });

    builder.addCase(fetchPaywallConfigFlow.rejected, (state, action) => {
      state.status = RequestStatus.ERROR;
      state.error = action.payload ?? 'Unknown error';
    });
  },
  initialState,
  name: 'paywall',
  reducers: {
    setCurrentPackage(state, action: PayloadAction<PaywallPackage | null>) {
      state.currentPackage = action.payload;
    },
  },
});

// Actions
export const { setCurrentPackage } = paywallSlice.actions;

// Selectors
export const selectPaywallConfig = (state: RootState): PaywallConfig | null => state.paywall.data;

export const selectPaywallConfigError = (state: RootState): string | null => state.paywall.error;

export default paywallSlice.reducer;
