import { ProfileState } from 'store/profile';

import { ProfileData } from './types';

export const formatProfileData = (profile: ProfileData): ProfileState => {
  const {
    archiveSpoiler,
    doNotSell,
    email,
    firstName,
    followedTeams,
    guid,
    lastName,
    primaryFavorite,
  } = profile;

  const favorite = getFavorite(primaryFavorite);
  const following = getFollowing(primaryFavorite, followedTeams);

  return {
    doNotSell: doNotSell === 'true',
    email,
    error: null,
    favoriteTeam: favorite,
    firstName: firstName ?? '',
    followingTeams: following,
    hideSpoilers: !archiveSpoiler,
    lastName: lastName ?? '',
    loading: false,
    userId: guid,
  };
};

const getFavorite = (favorite?: number | number[]) => {
  if (!favorite) return 0;

  return typeof favorite === 'number' ? favorite : favorite[0];
};

const getFollowing = (favoriteTeam?: number | number[], followedTeams?: number[]) => {
  if (!followedTeams && !favoriteTeam) return [];

  const following = followedTeams ?? [];

  let uniqueFollowing: number[] = [];

  if (Array.isArray(favoriteTeam)) {
    const moreFollowing = favoriteTeam.slice(1);
    uniqueFollowing = Array.from(new Set([...following, ...moreFollowing]));
  } else {
    uniqueFollowing = following;
  }

  return uniqueFollowing;
};
