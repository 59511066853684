import { AnalyticsConfig } from 'store/config';
import fallback from 'store/config/fallback.json';

import { ClickStatus } from './constants';
import { ToggleValues } from 'constants/settings';

let analyticsConfig: AnalyticsConfig = fallback.analytics;

export const getConfig = () => analyticsConfig;

export const init = (config: AnalyticsConfig) => {
  analyticsConfig = config;
};

export const actionEventIds = () => {
  const { actionEventIds: analyticsActions } = getConfig();

  return {
    ...analyticsActions,
    PAYWALL_PACKAGE_CLICK: (packageTitle: string) =>
      fillTemplate({ packageTitle }, analyticsActions.PAYWALL_PACKAGE_CLICK),
    PAYWALL_PACKAGE_LOG_IN_CLICK: (packageTitle: string) =>
      fillTemplate({ packageTitle }, analyticsActions.PAYWALL_PACKAGE_LOG_IN_CLICK),
    PAYWALL_YEARLY_MONTHLY_TOGGLE_CLICK: (offerGroup: string) =>
      fillTemplate({ offerGroup }, analyticsActions.PAYWALL_YEARLY_MONTHLY_TOGGLE_CLICK),
    SELECT_FEATURED_CONTENT_WATCH: (contentType: string) =>
      fillTemplate({ contentType }, analyticsActions.SELECT_FEATURED_CONTENT_WATCH),
    SELECT_FEED: (feed: string) => fillTemplate({ feed }, analyticsActions.SELECT_FEED),
    SELECT_FEED_START_AT: (startAtOption: string) =>
      fillTemplate({ startAtOption }, analyticsActions.SELECT_FEED_START_AT),
    SVOD_CARD_CLICK: (page: string, svodCardData: string) =>
      fillTemplate({ page, svodCardData }, analyticsActions.SVOD_CARD_CLICK),
    VIDEO_PLAYER_DETAILS_CLICK: (openCloseText: ClickStatus) =>
      fillTemplate({ openCloseText }, analyticsActions.VIDEO_PLAYER_DETAILS_CLICK),
    VIDEO_PLAYER_MILESTONE_SELECTED: (type: string) =>
      fillTemplate({ type }, analyticsActions.VIDEO_PLAYER_MILESTONE_SELECTED),
    VIDEO_PLAYER_OPTIONS_TOGGLE_CC: (enabled: boolean) =>
      fillTemplate(
        { enabled: enabled ? ToggleValues.On : ToggleValues.Off },
        analyticsActions.VIDEO_PLAYER_OPTIONS_TOGGLE_CC,
      ),
    VOD_CARD_CLICK: (page: string, cardTitle: string) =>
      fillTemplate({ page, vodCardData: cardTitle }, analyticsActions.VOD_CARD_CLICK),
    VOD_CARD_GAMES_CAROUSEL_CLICK: (title: string) =>
      fillTemplate({ title }, analyticsActions.VOD_CARD_GAMES_CAROUSEL_CLICK),
  };
};

export const pageViewIds = () => {
  const { pageViewIds: analyticsPages } = getConfig();

  return {
    ...analyticsPages,
    CALENDAR: (month: string | number, year: string | number): string =>
      fillTemplate({ month, year }, analyticsPages.CALENDAR),
    PAYWALL_PACKAGE: (packageTitle: string) =>
      fillTemplate({ packageTitle }, analyticsPages.PAYWALL_PACKAGE),
  };
};

export interface TemplateArgs {
  [key: string]: { toString: () => string };
}

/**
 * Fill a dynamic template string (`${var}`), does not throw warnings if missing a parameter
 * @param args object containing key: value for string replacement
 * @param id string containing all the keys in format '${key}', the same as template literals
 */
export const fillTemplate = (args: TemplateArgs, id: string): string => {
  let value = id;

  Object.keys(args).forEach((key: string) => {
    const replace = `$\{${key}}`;
    while (value.includes(replace)) {
      value = value.replace(replace, args[key].toString());
    }
  });

  return value;
};
