import api, { GET } from 'services/api';

import {
  formatCarouselData,
  formatFeaturedForgeVideo,
  formatForgeVideoFromSlug,
} from './normalizers';
import {
  ForgeContentApiItem,
  ForgeContentApiResponse,
  ForgeFeaturedApiItem,
  FormattedContentApiResponse,
  SLiveDAI,
  SLiveDAIResponse,
} from './types';

export async function fetchCarouselData(url: string): Promise<FormattedContentApiResponse> {
  const response = await api<ForgeContentApiResponse>(GET, {
    endpoint: 'template',
    // temporary fix for beta - fixes the problem where pressing right on carousel
    // makes carousel disappear
    // TODO: remove when #forge-support updates nextUrl to use https
    queryParams: { url: url.replace('http:', 'https:') },
    service: 'anonymous',
  });

  return formatCarouselData(response.data);
}

export async function fetchFeaturedItemData(url: string) {
  try {
    const response = await api<ForgeFeaturedApiItem>(GET, {
      endpoint: 'template',
      queryParams: { url },
      service: 'anonymous',
    });

    return formatFeaturedForgeVideo(response.data);
  } catch (error) {
    console.error('Could not fetch forge featured item', error);
    throw error;
  }
}

interface EntitledForgeVideoData {
  value: string;
}

interface EntitledForgeVideoResponse {
  data: EntitledForgeVideoData[];
}

export interface ForgeRequestParams {
  accessToken: string;
  oktaId: string;
  playbackUrl: string;
}

export const fetchEntitledForgeVideoUrl = async ({
  accessToken,
  oktaId,
  playbackUrl,
}: ForgeRequestParams): Promise<string> => {
  try {
    const { data } = await api<EntitledForgeVideoResponse>(
      GET,
      {
        endpoint: 'template',
        queryParams: {
          url: playbackUrl,
        },
        service: 'anonymous',
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-MLBAM-OKTA': oktaId,
        },
      },
    );

    if (Array.isArray(data.data)) return data.data[0].value;

    throw new Error('Could not fetch entitled Forge video URL');
  } catch (error) {
    console.error('Could not fetch entitled Forge video URL');
    throw error;
  }
};

export const fetchSliveVideo = async ({
  accessToken,
  oktaId,
  playbackUrl,
}: ForgeRequestParams): Promise<SLiveDAI> => {
  try {
    const { data } = await api<SLiveDAIResponse>(
      GET,
      {
        endpoint: 'template',
        queryParams: {
          url: playbackUrl,
        },
        service: 'anonymous',
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-MLBAM-OKTA': oktaId,
          'dai-ready': true,
        },
      },
    );

    const {
      dai: { assetKey, cdns, enabled, gamOutage, originalMasterPlaylist },
    } = data;

    return {
      assetKey,
      enabled: enabled && !gamOutage,
      originalUrl: originalMasterPlaylist,
      token: cdns[0].token,
      type: 'live',
    };
  } catch (error) {
    console.error('Could not fetch SLive video');
    throw error;
  }
};

export const fetchForgeVideoBySlug = async (slug: string) => {
  const response = await api<ForgeContentApiItem>(GET, {
    endpoint: 'videosBySlug',
    queryParams: {
      slug,
    },
    service: 'forge',
  });

  return formatForgeVideoFromSlug(response.data);
};
