export enum ReportableErrorType {
  ENTITLEMENT,
  MEDIA,
  NETWORK,
  OTHER,
  RESTRICTION,
}

export enum ReportableMediaEventType {
  CONTENT_ENDED,
  CONTENT_START,
}

export const reportErrorToDevice = (
  type: ReportableErrorType,
  errorCode: string,
  desc: string,
  visibleToUser = true,
) => null;

export const reportMediaEventToDevice = async (type: ReportableMediaEventType, mediaId = '') =>
  null;
