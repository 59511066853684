import styled from 'styled-components';

export const GameDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize['4xl']};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  height: 100%;
  justify-content: center;
  width: 34%;
`;

export const GameDetailsScore = styled.span`
  width: 40%;
`;

export const GameDetailsDash = styled.span`
  width: 20%;
`;
