import { teamLogoIds } from 'constants/testIds';
import {
  selectDarkCapLogoUrls,
  selectGameTileTeamColors,
  selectLightCapLogoUrls,
} from 'store/branding';
import { useAppSelector } from 'store/hooks';

import {
  FallbackContainer,
  FallbackLogo,
  TeamLogoBackground,
  TeamLogoImage,
} from './TeamLogo.styles';

const { FALLBACK_LOGO } = teamLogoIds;

interface TeamLogoProps {
  backgroundSize?: number;
  fallbackScale?: number;
  light?: boolean;
  size?: number;
  teamId: number;
}

export function TeamLogo({
  backgroundSize,
  fallbackScale,
  light = false,
  size,
  teamId,
}: TeamLogoProps) {
  const darkCapLogoUrls = useAppSelector(selectDarkCapLogoUrls);
  const lightCapLogoUrls = useAppSelector(selectLightCapLogoUrls);
  const teamBackgroundColors = useAppSelector(selectGameTileTeamColors);

  const src = light ? lightCapLogoUrls[teamId] : darkCapLogoUrls[teamId];
  const altText = `team-logo-${teamId}`;

  const image = src ? (
    <TeamLogoImage $size={size} alt={altText} src={src} />
  ) : (
    <FallbackContainer>
      <FallbackLogo $scale={fallbackScale} data-testid={FALLBACK_LOGO} />
    </FallbackContainer>
  );

  return src && backgroundSize ? (
    <TeamLogoBackground
      $backgroundColor={teamBackgroundColors[teamId]}
      $backgroundSize={backgroundSize}>
      {image}
    </TeamLogoBackground>
  ) : (
    image
  );
}
