import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameMenuOption } from 'components/video/GameMenu';
import * as Hls from 'hls.js';
import { AppDispatch, RootState } from 'store';
import {
  getClosedCaptionsStorage,
  getIsMutedStorage,
  setClosedCaptionsStorage,
  setIsMutedStorage,
} from 'utils/storage';

import { PlaybackAction } from './types';

const closedCaptions = getClosedCaptionsStorage();
const isMuted = getIsMutedStorage();
export interface PlayerError {
  details?: Hls.ErrorDetails;
  fatal?: boolean;
  type?: Hls.ErrorTypes;
}

export interface PlayerContentState {
  gameMenu: {
    details: {
      inningOffset: number;
    };
    gameMenuOption: GameMenuOption | null;
    pressedLinescoreInning: boolean;
    prevFocusedFeed: string;
  };
  isAtLivePoint: boolean;
  isFlashIconDisplayed: boolean;
  isPlaybackMarkerFocused: boolean;
  isVideoContentVisible: boolean;
}

export interface PlayerVideoState {
  bitrate: number;
  closedCaptions: boolean;
  currentTime: number;
  error: PlayerError | null;
  frameCount: number;
  googleStreamId: string;
  isAdInProgress: boolean;
  isBuffering: boolean;
  isMuted: boolean;
  isReady: boolean;
  isStarted: boolean;
  playbackAction: PlaybackAction;
  seekOffset: number;
  seekPointSeconds: number;
  seekSpeedMultiplier: number;
  startTime: number;
  totalTime: number;
}

export interface PlayerState {
  content: PlayerContentState;
  video: PlayerVideoState;
}

export const initialState: PlayerState = {
  content: {
    gameMenu: {
      details: { inningOffset: 0 },
      gameMenuOption: null,
      pressedLinescoreInning: false,
      prevFocusedFeed: '',
    },
    isAtLivePoint: false,
    isFlashIconDisplayed: false,
    isPlaybackMarkerFocused: false,
    isVideoContentVisible: true,
  },
  video: {
    bitrate: 0,
    closedCaptions,
    currentTime: 0,
    error: null,
    frameCount: 0,
    googleStreamId: '',
    isAdInProgress: false,
    isBuffering: false,
    isMuted,
    isReady: false,
    isStarted: false,
    playbackAction: PlaybackAction.PLAY,
    seekOffset: 0,
    seekPointSeconds: 0,
    seekSpeedMultiplier: 1,
    startTime: 0,
    totalTime: 0,
  },
};

export const updateClosedCaptions = (on: boolean) => (dispatch: AppDispatch) => {
  setClosedCaptionsStorage(on);
  dispatch(setClosedCaptions(on));
};

export const updateIsMuted = (on: boolean) => (dispatch: AppDispatch) => {
  setIsMutedStorage(on);
  dispatch(setIsMuted(on));
};

export const playerSlice = createSlice({
  initialState,
  name: 'player',
  reducers: {
    resetPlayer(state: PlayerState) {
      return {
        ...initialState,
        video: {
          ...initialState.video,
          closedCaptions: state.video.closedCaptions,
          isMuted: state.video.isMuted,
        },
      };
    },
    seekInterrupted(state: PlayerState, action: PayloadAction<number>) {
      state.video.playbackAction = PlaybackAction.PLAY;
      state.video.seekOffset = 0;
      state.video.seekPointSeconds = action.payload;
      state.video.currentTime = action.payload;
      state.video.seekSpeedMultiplier = 1;
    },
    setBitrate(state: PlayerState, action: PayloadAction<number>) {
      state.video.bitrate = action.payload;
    },
    setClosedCaptions(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.closedCaptions = action.payload;
    },
    setCurrentTime(state: PlayerState, action: PayloadAction<number>) {
      state.video.currentTime = action.payload;
    },
    setFrameCount(state: PlayerState, action: PayloadAction<number>) {
      state.video.frameCount = action.payload;
    },
    setGameMenuOption(state: PlayerState, action: PayloadAction<GameMenuOption | null>) {
      state.content.gameMenu.gameMenuOption = action.payload;
    },
    setGoogleStreamId(state: PlayerState, action: PayloadAction<string>) {
      state.video.googleStreamId = action.payload;
    },
    setInningOffset(state: PlayerState, action: PayloadAction<number>) {
      state.content.gameMenu.details.inningOffset = action.payload;
    },
    setIsAdInProgress(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.isAdInProgress = action.payload;
    },
    setIsAtLivePoint(state: PlayerState, action: PayloadAction<boolean>) {
      state.content.isAtLivePoint = action.payload;
    },
    setIsBuffering(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.isBuffering = action.payload;
    },
    setIsFlashIconDisplayed(state: PlayerState, action: PayloadAction<boolean>) {
      state.content.isFlashIconDisplayed = action.payload;
    },
    setIsMuted(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.isMuted = action.payload;
    },
    setIsPlaybackMarkerFocused(state: PlayerState, action: PayloadAction<boolean>) {
      state.content.isPlaybackMarkerFocused = action.payload;
    },
    setIsReady(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.isReady = action.payload;
    },
    setIsStarted(state: PlayerState, action: PayloadAction<boolean>) {
      state.video.isStarted = action.payload;
    },
    setIsVideoContentVisible(state: PlayerState, action: PayloadAction<boolean>) {
      state.content.isVideoContentVisible = action.payload;
    },
    setPlaybackAction(state: PlayerState, action: PayloadAction<PlaybackAction>) {
      state.video.playbackAction = action.payload;
    },
    setPlayerError(state: PlayerState, action: PayloadAction<PlayerError>) {
      state.video.error = action.payload;
    },
    setPressedLinescoreInning(state: PlayerState, action: PayloadAction<boolean>) {
      state.content.gameMenu.pressedLinescoreInning = action.payload;
    },
    setPrevFocusedFeed(state: PlayerState, action: PayloadAction<string>) {
      state.content.gameMenu.prevFocusedFeed = action.payload;
    },
    setSeekOffset(state: PlayerState, action: PayloadAction<number>) {
      state.video.seekOffset = action.payload;
    },
    setSeekPointSeconds(state: PlayerState, action: PayloadAction<number>) {
      state.video.seekPointSeconds = action.payload;
    },
    setSeekSpeedMultiplier(state: PlayerState, action: PayloadAction<number>) {
      state.video.seekSpeedMultiplier = action.payload;
    },
    setStartTime(state: PlayerState, action: PayloadAction<number>) {
      state.video.startTime = action.payload;
    },
    setTotalTime(state: PlayerState, action: PayloadAction<number>) {
      state.video.totalTime = action.payload;
    },
  },
});

// Actions
export const {
  resetPlayer,
  seekInterrupted,
  setBitrate,
  setClosedCaptions,
  setCurrentTime,
  setFrameCount,
  setGameMenuOption,
  setGoogleStreamId,
  setInningOffset,
  setIsAdInProgress,
  setIsAtLivePoint,
  setIsBuffering,
  setIsFlashIconDisplayed,
  setIsMuted,
  setIsPlaybackMarkerFocused,
  setIsReady,
  setIsStarted,
  setIsVideoContentVisible,
  setPlaybackAction,
  setPlayerError,
  setPressedLinescoreInning,
  setPrevFocusedFeed,
  setSeekOffset,
  setSeekPointSeconds,
  setSeekSpeedMultiplier,
  setStartTime,
  setTotalTime,
} = playerSlice.actions;

// Content Selectors
export const selectContent = (state: RootState) => state.player.content;

export const selectIsAtLivePoint = (state: RootState) => state.player.content.isAtLivePoint;

export const selectGameMenuOption = (state: RootState) =>
  state.player.content.gameMenu.gameMenuOption;

export const selectGoogleStreamId = (state: RootState) => state.player.video.googleStreamId;

export const selectInningOffset = (state: RootState) =>
  state.player.content.gameMenu.details.inningOffset;

export const selectIsPlaybackMarkerFocused = createSelector(
  [selectContent],
  (content) => content.isPlaybackMarkerFocused,
);

export const selectIsVideoContentVisible = createSelector(
  [selectContent],
  (content) => content.isVideoContentVisible,
);

export const selectPrevFocusedFeed = (state: RootState) =>
  state.player.content.gameMenu.prevFocusedFeed;

// Video Selectors
export const selectBitrate = (state: RootState) => state.player.video.bitrate;

export const selectClosedCaptions = (state: RootState) => state.player.video.closedCaptions;

export const selectCurrentTime = (state: RootState) => state.player.video.currentTime;

export const selectFrameCount = (state: RootState) => state.player.video.frameCount;

export const selectIsAdInProgress = (state: RootState) => state.player.video.isAdInProgress;

export const selectIsBuffering = (state: RootState) => state.player.video.isBuffering;

export const selectIsFfOrRw = (state: RootState) =>
  state.player.video.playbackAction === PlaybackAction.PAUSE_RW ||
  state.player.video.playbackAction === PlaybackAction.PAUSE_FF;

export const selectIsFlashIconDisplayed = (state: RootState) =>
  state.player.content.isFlashIconDisplayed;

export const selectIsMuted = (state: RootState) => state.player.video.isMuted;

export const selectIsPlaying = (state: RootState) =>
  state.player.video.playbackAction === PlaybackAction.PLAY;

export const selectIsReady = (state: RootState) => state.player.video.isReady;

export const selectIsStarted = (state: RootState) => state.player.video.isStarted;

export const selectPlaybackAction = (state: RootState) => state.player.video.playbackAction;

export const selectPlayerError = (state: RootState) => state.player.video.error;

export const selectPressedLinescoreInning = (state: RootState) =>
  state.player.content.gameMenu.pressedLinescoreInning;

export const selectSeekOffset = (state: RootState) => state.player.video.seekOffset;

export const selectSeekPointSeconds = (state: RootState) => state.player.video.seekPointSeconds;

export const selectSeekSpeedMultiplier = (state: RootState) =>
  state.player.video.seekSpeedMultiplier;

export const selectStartTime = (state: RootState) => state.player.video.startTime;

export const selectTotalTime = (state: RootState) => state.player.video.totalTime;

export default playerSlice.reducer;
