import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'services/api';
import { fetchTeams } from 'services/statsApi/teams';
import { RootState } from 'store';
import { Team } from 'store/teams/types';
import invert from 'lodash/invert';
import fallback from 'store/config/fallback.json';

export interface TeamsState {
  data: Team[];
  error: string;
  status: RequestStatus;
}

export const initialState: TeamsState = {
  data: [],
  error: '',
  status: RequestStatus.IDLE,
};

export const fetchTeamsFlow = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'teams/fetchTeams',
  async (_, { rejectWithValue }) => {
    try {
      const teamsResponse = await fetchTeams();
      return teamsResponse;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  },
);

export const teamsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchTeamsFlow.pending, (state) => {
      state.status = RequestStatus.LOADING;
    });

    builder.addCase(fetchTeamsFlow.fulfilled, (state, action) => {
      state.status = RequestStatus.SUCCESS;
      state.data = action.payload;
      state.error = '';
    });

    builder.addCase(fetchTeamsFlow.rejected, (state, action) => {
      state.status = RequestStatus.ERROR;
      state.error = action.payload ?? '';
    });
  },
  initialState,
  name: 'teams',
  reducers: {},
});

// Selectors
export const selectTeams = (state: RootState) => state.teams.data;

export const selectTeamAbbrvToId = (state: RootState) =>
  state.teams?.data?.length
    ? state.teams.data.reduce(
        (teams, team: Team) => ({ ...teams, [team.abbreviation]: team.id }),
        {},
      )
    : fallback.statsApi.teamAbbrvToId;

export const selectTeamIdToAbbrv = createSelector([selectTeamAbbrvToId], (teamAbbrvToId) => {
  return invert(teamAbbrvToId);
});

export default teamsSlice.reducer;
