import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdobeTracker } from 'analytics/adobe';
import { AdobeTargetData, getHlsjsConfigOffer } from 'services/adobe';
import { AppDispatch, GetState, RootState } from 'store';
import { selectConvivaConfig } from 'store/config/configSlice';

const DEFAULT_ADOBE_HLS_CONFIG = { id: 'remote' };

export interface AdobeState {
  hlsConfig: AdobeTargetData;
  isInitialized: boolean;
}

export const initialState: AdobeState = {
  hlsConfig: { config: undefined, id: 'fallback' },
  isInitialized: false,
};

// Flows
export const initAdobe = () => async (dispatch: AppDispatch, getState: GetState) => {
  const state = getState();
  const {
    config: { data: config },
  } = state;

  await AdobeTracker.init(config);

  dispatch(setAdobeInitialized());
};

export const initAdobeTargetFlow = createAsyncThunk<
  AdobeTargetData,
  undefined,
  { rejectValue: { id: string }; state: RootState }
>(`adobe/initAdobeTargetFlow`, async (_, { getState, rejectWithValue }) => {
  try {
    const { deviceMetadata } = selectConvivaConfig(getState());
    const hlsjsConfig = await getHlsjsConfigOffer(deviceMetadata);
    return hlsjsConfig ?? DEFAULT_ADOBE_HLS_CONFIG;
  } catch (error) {
    return rejectWithValue(DEFAULT_ADOBE_HLS_CONFIG);
  }
});

// Slice
export const adobeSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(initAdobeTargetFlow.fulfilled, (state, action) => {
      state.hlsConfig = action.payload;
    });
    builder.addCase(initAdobeTargetFlow.rejected, (state) => {
      state.hlsConfig = DEFAULT_ADOBE_HLS_CONFIG;
    });
  },
  initialState,
  name: 'adobe',
  reducers: {
    setAdobeInitialized(state: AdobeState) {
      state.isInitialized = true;
    },
  },
});

// Actions
export const { setAdobeInitialized } = adobeSlice.actions;

// Selectors
export const selectIsInitialized = (state: RootState) => state.adobe.isInitialized;
export const selectAdobeTargetHlsConfigId = (state: RootState) => state.adobe.hlsConfig.id;
export const selectAdobeTargetHlsConfig = (state: RootState) => state.adobe.hlsConfig.config;

export default adobeSlice.reducer;
