export enum PlaybackAction {
  FF = 'ff',
  PAUSE = 'pause',
  PAUSE_FF = 'pause ff',
  PAUSE_RW = 'pause rw',
  PLAY = 'play',
  RW = 'rw',
  SEEK = 'seek',
  STOP = 'stop',
}

export enum StartAt {
  BEGINNING = 'BEGINNING',
  RESUME = 'RESUME',
}
