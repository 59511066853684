import { Button } from 'components/common';
import styled from 'styled-components';
import { gameMenuColors } from 'styles/colors';

const { gameMenuButtonBackgroundColorFocused, gameMenuButtonBackgroundColorSelected } =
  gameMenuColors;

export const GameMenuButtonContainer = styled.div`
  display: flex;
  margin-left: -${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

export const GameMenuButton = styled(Button)`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  margin-right: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};

  &.selected {
    background-color: ${gameMenuButtonBackgroundColorSelected};
  }

  &.focused {
    background-color: ${gameMenuButtonBackgroundColorFocused};
  }
`;
