import VideoIcon from 'assets/feedSelect/video.svg?react';
import { Button, Link } from 'components/common';
import { Spinner } from 'components/common/Spinner';
import styled from 'styled-components';

export const FeedLink = styled(Link)`
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[2]};
  padding-right: ${({ theme }) => theme.spacing[2]};
  width: 30rem;
`;

export const FeedsContainer = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: ${({ theme }) => theme.spacing[6]};
  padding-top: ${({ theme }) => theme.spacing[8]};
  width: 100%;
`;

export const FeedSectionTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  height: 3.9rem;
  letter-spacing: 0.114rem;
  padding-top: ${({ theme }) => theme.spacing[1]};
`;

export const FeedSectionTitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing[5]};

  &:not(:first-child) {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }
`;

export const FeedTeamText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight[900]};
`;

export const GetOrUpgradeMlbTvButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  width: 48.3rem;
`;

export const StreamButtonGrid = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StreamsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const StreamOptionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => {
    const horizontal = theme.spacing[5];
    return `0 ${horizontal}`;
  }};
`;

export const StreamSelectionSpinner = styled(Spinner)`
  height: 15.3rem;
`;

export const VideoSectionIcon = styled(VideoIcon)`
  margin-right: ${({ theme }) => theme.spacing[1]};
  width: 3.9rem;
`;
