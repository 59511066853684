export const random = (min: number, max: number) => Math.random() * (max - min) + min;

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const retryWithBackoff = async <T>(
  fn: () => Promise<T>,
  maxRetries: number,
  baseDelayMs: number,
): Promise<T> => {
  let retries = 0;
  let _delay = baseDelayMs;

  while (retries < maxRetries) {
    try {
      return await fn();
    } catch (error) {
      retries += 1;
      const randomizedDelay = baseDelayMs * random(0.4, 1.4);
      await delay(randomizedDelay);
      _delay *= 2;
    }
  }

  throw new Error('Max retries reached');
};
