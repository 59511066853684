import styled from 'styled-components';
import { backgroundImageColors } from 'styles/colors';
import { Z_INDICES } from 'styles/zIndexHierarchy';

const { BACKGROUND_IMAGE, BACKGROUND_IMAGE_OVERLAY } = Z_INDICES;
const { overlayColor } = backgroundImageColors;

export const BackgroundImageContainer = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${BACKGROUND_IMAGE};
`;

export const BackgroundImageOverlay = styled.div`
  background-color: ${overlayColor};
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${BACKGROUND_IMAGE_OVERLAY};
`;
