import styled from 'styled-components';

export const NoBroadcastAvailableContainer = styled.p`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  justify-content: center;
  margin: ${({ theme }) => theme.spacing[6]};
`;
