import { OktaAuthOptions } from '@okta/okta-auth-js';

export const buildOktaConfig = (clientId: string, issuer: string, redirectUri: string) => {
  const config: OktaAuthOptions = {
    clientId,
    issuer,
    redirectUri,
    responseType: 'code',
    scopes: ['offline_access', 'openid'],
  };
  return config;
};
