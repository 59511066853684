import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Link } from 'components/common';
import { buildGameVideoToObject } from 'components/FeedSelect/utils';
import { RESUME, START_FROM_THE_BEGINNING, WATCH_LIVE } from 'constants/feeds';
import { feedSelectIds } from 'constants/testIds';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useEffect } from 'react';
import { selectBookmarkTime } from 'store/bookmarks';
import { EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { StartAt } from 'store/player';
import { getIsMediaOn } from 'utils/gameData';

import { SecondaryFeedSelectContainer } from './SecondaryFeedSelect.styles';

const { RESUME_LINK, SECONDARY_MODAL, START_FROM_BEGINNING_LINK, WATCH_LIVE_LINK } = feedSelectIds;

export interface SecondaryFeedSelectProps {
  feed: EpgAudioFeed | EpgVideoFeed;
  gamePk: string;
}

export function SecondaryFeedSelect({ feed, gamePk }: SecondaryFeedSelectProps) {
  const { date } = useDateFromURL();
  const { contentId, mediaId } = feed;

  const { focusKey, focusSelf, ref } = useFocusable();

  const bookmarkTime = useAppSelector((state) => selectBookmarkTime(state, contentId));

  const isMediaOn = getIsMediaOn(feed);
  const to = buildGameVideoToObject({ contentId, date, gamePk, mediaId });

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      <SecondaryFeedSelectContainer ref={ref} data-testid={SECONDARY_MODAL}>
        {!!bookmarkTime && (
          <Link
            data-testid={RESUME_LINK}
            navigateOptions={{ state: { feed, startAt: StartAt.RESUME } }}
            to={to}
            variant="button">
            {RESUME}
          </Link>
        )}

        {isMediaOn && (
          <Link
            data-testid={WATCH_LIVE_LINK}
            navigateOptions={{ state: { feed } }}
            to={to}
            variant="button">
            {WATCH_LIVE}
          </Link>
        )}

        <Link
          data-testid={START_FROM_BEGINNING_LINK}
          navigateOptions={{ state: { feed, startAt: StartAt.BEGINNING } }}
          to={to}
          variant="button">
          {START_FROM_THE_BEGINNING}
        </Link>
      </SecondaryFeedSelectContainer>
    </FocusContext.Provider>
  );
}
